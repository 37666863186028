import React from 'react'

import { Button as MaterialButton } from '@mui/material'
import { Element, NodeTree, useEditor } from '@craftjs/core'
import { Text } from './Text/Text'
import { Container } from './Container/Container'
import { QR } from './Postcard/QR/QR'
import { Map } from './Map/Map'
import { CompanyInterface, useUser } from '../../contexts/UserContext' 
import { DesignInterface, useDesign } from '../../contexts/DesignContext'
import { WebMap } from './Website/WebMap/WebMap'
import { Form } from './Website/Form/Form'
import { Video } from './Website/Video/Video'
import { WebsiteImage } from '../Images/WebsiteImage/WebsiteImage'
import { WebButton } from './Website/WebButton/WebButton'
import { PopupForm } from './Website/PopupForm/PopupForm'
import './Elements.scss'

export const Elements = ({onNodeAdded}: {onNodeAdded: (id: string) => void}) => {
  const {connectors} = useEditor()
  let {user}: {user: CompanyInterface} = useUser() 
  let {design}: {design: DesignInterface} = useDesign()

  const elements: any = {
    mailpiece: [
      {
        type: 'container',
        create: <Element canvas
            is={Container}
            height="100px"
            width="100px"
            absolute={true} />,
        label: 'Container'
      }, 
      {
        type: 'text',
        create: <Text text="Placeholder"
          width="200px"
          absolute={true}/>,
        label: 'Text'
      }, 
      {
        type: 'map',
        create: <Map address={user.address_formatted} width="300px" height="300px"/>,
        label: 'Map'
      },
      {
        type: 'qr',
        create: <QR value="https://www.directmail.io/" width="40px" level="Q"/>,
        label: 'QR'
      }
    ],
    landing_page: [
      {
        type: 'text',
        create: <Text text="Placeholder"
          type="landing_page"
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'span 8',
            gridRowStart: 1,
            gridRowEnd: 'span 2',
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            paddingLeft: '0px',
            paddingRight: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
            textAlign: 'left',
            lineHeight: 1.2,
            opacity: 1
          }}/>,
        label: 'Text'
      }, 
      {
        type: 'map',
        create: <WebMap address={user.address_formatted} 
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'span 12',
            gridRowStart: 1,
            gridRowEnd: 'span 6'
          }}/>,
        label: 'Map'
      },
      {
        type: 'button',
        create: <WebButton label="Button"
          style={{
            padding: '5px',
            borderRadius: '4px',
            backgroundColor: 'rgba(25, 118, 210, 1)',
            color: 'rgba(255, 255, 255, 1)',
            gridColumnStart: 1,
            gridColumnEnd: 'span 4',
            gridRowStart: 1,
            gridRowEnd: 'span 2'
          }}/>,
        label: 'Button'
      },
      {
        type: 'image-var',
        create: <WebsiteImage type="variable"
          style={{
            opacity: 1,
            transform: 'rotate(0deg)',
            gridColumnStart: 1,
            gridColumnEnd: 'span 4',
            gridRowStart: 1,
            gridRowEnd: 'span 3'
          }} />,
        label: 'Variable Image'
      },
      {
        type: 'video',
        create: <Video type="static" 
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'span 8',
            gridRowStart: 1,
            gridRowEnd: 'span 6'
          }}/>,
        label: 'Video'
      },
      {
        type: 'video-var',
        create: <Video type="variable" 
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 'span 8',
            gridRowStart: 1,
            gridRowEnd: 'span 6'
          }}/>,
        label: 'Variable Video'
      },
      {
        type: 'form',
        create: <Form />,
        label: 'Form'
      },
      {
        type: 'form-popup',
        create: <PopupForm label="Open Form" />,
        label: 'Form Popup'
      }
    ]
  }

  const callback = (something: NodeTree) => {
    onNodeAdded(something.rootNodeId)
  }

  return <section className="dm-edit-sidebar">
    <div className="dm-edit-sidebar__header">
      <h1 className="dm-edit-sidebar__heading">Insert</h1>
    </div>
    <div className="dm-edit-elements">
      {
        elements[design.type.toLowerCase()].map((item: any, index: number) =>       
          <MaterialButton key={index}
            className="dm-edit-elements__item"
            ref={(ref: any) => connectors.create(ref, item.create, { onCreate: callback })}>
            <div>
              <svg className="dm-edit-elements__item-main">
                <use href={`assets/icons/elements.svg#${item.type}`} />
              </svg>
              <svg className="dm-edit-elements__item-hover">
                <use href={`assets/icons/elements.svg#mono-${item.type}`} />
              </svg>
            </div>
            {item.label}
          </MaterialButton>)
      }
    </div>
  </section>
}
