import React, { PropsWithChildren } from 'react'
import { useNode } from '@craftjs/core'
import { SectionProps } from '../../../../types/props'
import { SectionProperties } from './SectionProperties'
import { useDevice } from '../../../../contexts/ViewProvider'
import './Section.scss'

export const Section = (props: PropsWithChildren<SectionProps>) => {
	const {connectors: {connect}, id} = useNode(node => ({id: node.id}))
	const {device} = useDevice()

	let style = {
		width: '100%',
		...props.style,
	}

	if (device === 'mobile') {
		style = {...style, ...props.mobileStyle}
	}
	
	return <div ref={ref => connect(ref)}
		className={`dm-edit-section dm-edit-section--${ props.columns ? 'columns' : 'grid' } dm-edit-section--${device}`}
		style={style}>
		{ props.children }
		{	!props.columns &&
			<div className="dm-edit-section__grid"></div>
		}
	</div>
}

Section.craft = {
	name: 'Section',
	custom: {
		landing_page: {
			resizableProps: {
				enable: { top:false, right:false, bottom:true, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }
			}
		},
		mailpiece: {}
  },
	props: {
		height: '100px',
		style: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
			gridTemplateRows: 'repeat(19, 30px, auto)'
		}
	},
	related: {
		properties: SectionProperties
	}
}
