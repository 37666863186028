import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { PropertiesAccordionSummary, PropertiesAccordion, PropertiesAccordionDetails } from './PropertiesAccordion'
import { PropertiesSlider, PropertiesField, PropertiesFormLabel } from './PropertiesFields'
import { PropertyLabel } from './PropertyLabel'
import { Mixed } from './Mixed'
import { DesignInterface, useDesign } from '../../../contexts/DesignContext'
import { Checkbox, FormControlLabel } from '@mui/material'

export const Layout = ({square, hide = []}: {square?: boolean, hide?: string[]}) => {
	const { actions: {setProp}, props, dom } = useNode((node) => ({
		props: node.data.props,
		dom: node.dom
	}))
	const [open, setOpen] = useState<boolean>(true)
  let {design}: {design: DesignInterface} = useDesign()
	const styleWH= {display: 'flex', alignItems: 'center', flex: 1}

	const onCheckboxChange = (value: React.ChangeEvent<HTMLInputElement>, key: string) => {
		if (value.target.checked) {
			setProp((props: any) => props.height = 'auto')
		} else {
			let height = dom.clientHeight
			setProp((props: any) => props.height = height+'px')
		}
	} 

	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Layout"/>
		<PropertiesAccordionDetails>
			{ !hide.includes('padding') && <Mixed property="padding" square={square}/> }

			{/* Margin doesn't apply to mailpiece */}
			{/* { design.type.toLowerCase() !== 'mailpiece' && !hide.includes('margin') && <Mixed property="margin" square={square}/> } */}

			<section className="dm-edit-property-fields">
				{ !hide.includes('width') &&
					(
						design.type.toLowerCase() === 'mailpiece' ?
							<div style={styleWH}>
								<PropertyLabel>Width</PropertyLabel>
								<PropertiesField value={parseInt(props.width)}
									sx={{flex: 1}}
						      size="small"
						      type="number"
						      inputProps={{min: 0}}
						      onChange={event => setProp((props: any) => props.width = `${event.target.value}px`)}>
								</PropertiesField>
							</div> : 
							<section className="dm-edit-property-fields">
								<PropertyLabel>Width</PropertyLabel>
								<PropertiesField value={Math.round(props.style.opacity*100) || 100}
									size="small"
									type="number"
									inputProps={{min: 0, max: 100}}
									onChange={event => setProp((props: any) => props.style.opacity = parseInt(event.target.value)/100)}>
								</PropertiesField>
								<PropertiesSlider value={Math.round(props.style.opacity*100)}
									step={1}
									min={0}
									max={100}
									onChange={(_, value) => setProp((props: any) => props.style.opacity = (value as number)/100)} />
							</section>
					)

				}
				{ !hide.includes('height') &&
					<div style={styleWH}>
						<PropertyLabel>Height</PropertyLabel>
						<PropertiesField value={parseInt(props.height)}
							sx={{flex: 1}}
				      size="small"
				      type="number"
							disabled={props.height === 'auto'}
							placeholder={props.height === 'auto' ? 'Auto': ''}
				      inputProps={{min: 0}}
				      onChange={event => setProp((props: any) => props.height = `${event.target.value}px`)}>
						</PropertiesField>
						{
							design.type.toLowerCase() !== 'mailpiece' &&
							<PropertiesFormLabel	control={<Checkbox onChange={(value) => onCheckboxChange(value, 'height')} 
								size="small"
								checked={props.height === 'auto'}/>} 
								label="Auto" />
						}
					</div>
				}
			</section>
			
			{/* Coordinates only apply to mailpiece */}
			{ design.type.toLowerCase() == 'mailpiece' && <section className="dm-edit-property-fields">
				<PropertyLabel>X Pos</PropertyLabel>
				<PropertiesField value={props.x}
		      size="small"
		      type="number"
		      inputProps={{min: 0}}
		      onChange={event => setProp((props: any) => props.x = parseInt(event.target.value))}>
				</PropertiesField>
				<PropertyLabel>Y Pos</PropertyLabel>
				<PropertiesField value={props.y}
		      size="small"
		      type="number"
		      inputProps={{min: 0}}
		      onChange={event => setProp((props: any) => props.y = parseInt(event.target.value))}>
				</PropertiesField>
			</section> }
			{ !hide.includes('opacity') &&
				<section className="dm-edit-property-fields">
					<PropertyLabel>Opacity</PropertyLabel>
					<PropertiesField value={Math.round(props.style.opacity*100) || 100}
						size="small"
						type="number"
						inputProps={{min: 0, max: 100}}
						onChange={event => setProp((props: any) => props.style.opacity = parseInt(event.target.value)/100)}>
					</PropertiesField>
					<PropertiesSlider value={Math.round(props.style.opacity*100) || 100}
						step={1}
						min={0}
						max={100}
						onChange={(_, value) => setProp((props: any) => props.style.opacity = (value as number)/100)} />
				</section>
			}
			{ !hide.includes('rotate') &&
				<section className="dm-edit-property-fields">
					<PropertyLabel>Rotate</PropertyLabel>
					<PropertiesField value={props.style.transform ? parseInt(props.style.transform.replace(/(rotate\()|(deg\))+/g, '')) : 0}
						size="small"
						type="number"
						inputProps={{min: 0, max: 360}}
						onChange={event => setProp((props: any) => props.style.transform = `rotate(${event.target.value}deg)`)}>
					</PropertiesField>
				</section>
			}
		</PropertiesAccordionDetails>
	</PropertiesAccordion>
}
