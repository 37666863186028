import React, { CSSProperties, useState } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { Button, Dialog, DialogContent } from '@mui/material'
import { PopupFormProps } from '../../../../types/props'
import { PopupFormProperties } from './PopupFormProperties'
import { useDevice } from '../../../../contexts/ViewProvider'
import { getStyle } from '../../../../services/helperService'
import './PopupForm.scss'
import { FormRenderer } from '../FormRenderer/FormRenderer'

export const PopupForm = (popupFormProps: PopupFormProps) => {
	const [errors, setErrors] = useState<{[key: string]: string}>({})
	const {connectors: {connect}, actions: {setProp}, id, dom, parent} = useNode(node => ({
		id: node.id, 
		dom: node.dom,
		parent: node.data.parent
	}))
	const { actions: {history: {ignore}}, query: {node} } = useEditor()
  const {device} = useDevice()
	let parentNode = node(parent).get()
	let style: CSSProperties = {
		textTransform: 'none',
		...getStyle(popupFormProps.style, popupFormProps.mobileStyle, device, parentNode)
	}

	const checkValue = (value: string, type: string, key: string) => {
		switch (type) {
			case 'email':
				let new_errors: {[key: string]: string} = {...errors}
				let valid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
				if (valid) {
					delete new_errors[key]
					setErrors(new_errors)
				} else if (!valid && new_errors[key]) {
					new_errors[key] = 'Please enter a valid email address.'
					setErrors(new_errors)
				}
		}

	}

	return <>
		<Button ref={ref => connect(ref)}
			style={style}>
			{ popupFormProps.label }
		</Button>
		<Dialog open={popupFormProps.open}
			sx={{
				left: '56px',
				top: '56px',
				right: '320px',
				'.MuiBackdrop-root': {
					left: '56px',
					top: '56px',
					right: '320px',
				}
			}}>
			<DialogContent>
				<FormRenderer form={popupFormProps.form}/>
			</DialogContent>
		</Dialog>
	</>
}

PopupForm.craft = {
	displayName: 'Form Popup',
	props: {
		open: false, 
		label: 'Get Started',
		style: {           
			padding: '5px',
			borderRadius: 4,
			gridColumnStart: 1,
			gridColumnEnd: 'span 6',
			gridRowStart: 1,
			gridRowEnd: 'span 2',
			color: 'rgba(255, 255, 255, 1)',
			backgroundColor: 'rgba(25, 118, 210, 1)',
			fontSize: 14, 
			letterSpacing: 1
		},
		form: {		
			marginX: 16,
			marginY: 16,
			activeStep: 0,
			type: 'single',
			button: {
				style: {
					borderRadius: 0,
					justifySelf: 'flex-start',
					backgroundColor: 'rgba(25, 118, 210, 1)',
					color: 'rgba(255, 255, 255, 1)',
					width: '50%'
				},
				label: 'Submit'
			},
			fieldStyle: {
				borderRadius: 0,
				fieldType: 'outlined',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderColor: 'rgba(25, 118, 210, 1)',
				floatingLabel: true,
			},
			fields: [[
				[
					{
						key: 'first_name',
						type: 'text',
						label: 'First Name',
						required: true
					},
					{
						key: 'last_name',
						type: 'text',
						label: 'Last Name',
						required: true
					},
				],
				[
					{
						key: 'reason',
						type: 'select',
						label: 'Reason',
						required: true,
						options: [
							{
								label: 'First',
								value: 'first'
							},
							{
								label: 'Second',
								value: 'second'
							}
						]
					}
				]
			]]
		}
	},
	related: {
    properties: PopupFormProperties
  }
}
