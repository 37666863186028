import React, { useEffect, useRef, useState } from 'react'

import { useNode, useEditor } from '@craftjs/core'
import { ROOT_NODE } from '@craftjs/utils'
import ReactDOM from 'react-dom'
import { Resizable, ResizeCallback } from 're-resizable'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import { useCoordinates } from '../../../../contexts/CoordinatesContext'
import { useZoom } from '../../../../contexts/ViewProvider'
import { ItemControl } from '../../../Rightbar/Properties/ItemControl'
import { IconButton } from '@mui/material'
import _ from 'lodash'

export const PostcardRenderNode = React.memo(({ render }: {render: any}) => {
  const { query, actions } = useEditor()
  const {
    connectors: {connect},
    actions: {setProp},
    isHover, 
    dom,
    name,
    moveable,
    deletable,
    props,
    connectors: { drag },
    node,
    parentNode,
    parent,
    active,
    children
  } = useNode((node) => ({
    id: node.id,
    node: node,
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parentNode: node.data.parent ? query.node(node.data.parent).get() : null,
    parent: node.data.parent,
    children: node.data.nodes,
    props: node.data.props,
    active: node.data.name != 'Card' && node.events.selected
  }))
  const currentRef: any = useRef<any>()
  const [offset, setOffset] = useState({x: 0, y: 0})
  const {zoom} = useZoom()
  let coordinates = useCoordinates()
  let resizableProps = node.data.custom.mailpiece ? node.data.custom.mailpiece.resizableProps : null
  const prev_children = useRef<string[]>(null)

  useEffect(() => {
    if (props.initial && parent && props.x === 0 && props.y === 0) {
      let parent_dom = query.node(parent).get().dom
      if (parent_dom) {
        let parent_position = parent_dom.getBoundingClientRect()
        setProp((props: any) => {
          props.x = Math.ceil((coordinates.x - parent_position.x))
          props.y = Math.ceil((coordinates.y - parent_position.y))
        })
      }
    }
  }, [])

  useEffect(() => {
    getOffset()
  }, [zoom])

  useEffect(() => {
    if (dom && node.data.name != 'Card') {
      if (active) {
        dom.classList.add('component-selected')
        getOffset()
      } else {
        dom.classList.remove('component-selected')
      }
    }
  }, [active])

  useEffect(() => {
    if (node.id !== ROOT_NODE) {
      if (prev_children.current) {
        if (prev_children.current.length < children.length) {
          const new_child = _.difference(children, prev_children.current)[0] 
          const new_child_node = query.node(new_child).get()

          if (!new_child_node.data.props.initial) {
            actions.history.ignore().setProp(new_child, (props: any) => {
              if (!new_child_node.data.props.duplicate) {
                props.x = 0
                props.y = 0
              } else {
                props.duplicate = false
              }
            })
          } else {
            actions.history.ignore().setProp(new_child, (props: any) => props.initial = false)
          }
        }
      }

      prev_children.current = children
    }
  }, [children])

  const getOffset = () => {
    if (dom && node.data.name != 'Card' && parent) {
      let parent_dom = query.node(parent).get().dom

      if (parent_dom) {
        let card = dom.closest('.dm-edit-card')
        let wrapper = document.querySelector('.dm-edit-card-wrapper')
  
        if (card && wrapper) {
          setOffset({
            x: parent_dom.getBoundingClientRect().left - wrapper.getBoundingClientRect().left,
            y: parent_dom.getBoundingClientRect().top - wrapper.getBoundingClientRect().top
          })
        }
      }
    }
  }

  const handleDrag: DraggableEventHandler = (e, data) => {
    setProp((prop: any) => {
      prop.x = Math.round(data.x)
      prop.y = Math.round(data.y)
    }, 100)
  }

  const handleStop: DraggableEventHandler = (e, data) => {
    setProp((prop: any) => {
      prop.x = data.x
      prop.y = data.y
    })
  }

  const onResize: ResizeCallback = (event, direction, refToElement, delta) => {
    if (refToElement.clientHeight > 0 && refToElement.clientWidth > 0) {
      const width = refToElement.clientWidth - 4
      const height = refToElement.clientHeight - 4

      setProp((itemProps: any) => {
        if (direction.toLowerCase().includes('left')) {
          let current_width = props.width === 'auto' ? dom.offsetWidth : parseInt(props.width)
          let deltaX = current_width - width
          itemProps.x = props.x + deltaX
        }

        if (direction.toLowerCase().includes('top')) {
          let current_height = props.height === 'auto' ? dom.offsetHeight : parseInt(props.height)
          let deltaY = current_height - height
          itemProps.y = props.y + deltaY
        }

        if (node.data.name == 'Image') {
          itemProps.imageHeight = `${Math.round((height/parseFloat(props.height.toString()))*parseFloat(props.imageHeight.toString()))}px`
          itemProps.imageX = Math.round((height/parseFloat(props.height.toString()))*parseFloat(props.imageX.toString()))
          itemProps.imageY = Math.round((height/parseFloat(props.height.toString()))*parseFloat(props.imageY.toString()))
          itemProps.height = `${height}px`
          itemProps.width = `${width}px`
        } else {
          itemProps.width = `${width}px`

          if (itemProps.style && itemProps.style.flex === '1') {
            itemProps.style.flex = 'none'
          }
  
          if (itemProps.height !== 'auto') {
            itemProps.height = `${height}px`
          }
        }

      }, 500)
    }
  } 

  const handleDoubleClick = () => {
    if (node.data.name == 'Text') {
      actions.history.ignore().setProp(node.id, (itemProps: any) => itemProps.editing = true)
    }
  }

  const onLayerUp = () => {
    actions.selectNode(parent)
  }

  return <>
    {(active) && dom
      && !(node.data.name == 'Image' &&  props.crop)
      && !(node.data.name == 'Text' &&  props.editing)
      && node.id !== ROOT_NODE 
      && node.data.name != 'Card'
      ? ReactDOM.createPortal(
        <Draggable onStop={handleStop} 
          onDrag={handleDrag} 
          position={{x: props.x, y: props.y}} 
          handle=".dm-edit-render-indicator"
          cancel='.dm-edit-handle, .dm-edit-move'
          defaultClassName={props.absolute ? 'dm-edit-draggable' : 'dm-edit-not-draggable'}>
          <div onDoubleClick={handleDoubleClick}
            style={{
              zIndex: 2,
              position: 'absolute',
              left: offset.x - 2, 
              top: offset.y*(100/zoom) - 2,
              pointerEvents: props.editing ? 'none' : 'auto'
            }}>
          <Resizable ref={currentRef}
            style={props.style && props.style.transform ? { transform: props.style.transform } : {}}
            {...resizableProps}
            handleClasses={{
              top: 'dm-edit-handle dm-edit-handle--top',
              right: 'dm-edit-handle dm-edit-handle--right',
              bottom: 'dm-edit-handle dm-edit-handle--bottom',
              left: 'dm-edit-handle dm-edit-handle--left',
              topRight: 'dm-edit-handle dm-edit-handle--topRight',
              bottomRight: 'dm-edit-handle dm-edit-handle--bottomRight',
              bottomLeft: 'dm-edit-handle dm-edit-handle--bottomLeft',
              topLeft: 'dm-edit-handle dm-edit-handle--topLeft'
            }}
            onResize={onResize}
            className="dm-edit-render-indicator"
            size={{
              width: dom.offsetWidth + 4, 
              height: dom.offsetHeight + 4}}>
            <div className="dm-edit-rendernode__name">
              <ItemControl selected={{
                	id: node.id,
                  node: node,
                  deletable: deletable
              }}>
                <div ref={drag}
                  className="dm-edit-move">
                  <svg>
                    <use href="assets/icons/settings.svg#move" />
                  </svg>
                </div>
                { parentNode && parentNode.data.name !== 'Card' &&
                  <IconButton sx={{width: '24px', height: '24px', padding: 0}}
                    onClick={onLayerUp}>
                    <svg>
                      <use href={`assets/icons/settings.svg#arrow-up`}/>
                    </svg>   
                  </IconButton>
                }
              </ItemControl>
            </div>
          </Resizable>
          </div>
        </Draggable>,
        document.querySelector('.dm-edit-card-wrapper')
      ) : null}
    {render}
  </>
})
