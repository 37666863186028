import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { AccordionSummary } from '@mui/material'
import { VariableInterface } from '../../../contexts/VariablesContext'
import { TextProps } from '../../../types/props'
import { Background } from '../../Rightbar/Properties/Background'
import { Border } from '../../Rightbar/Properties/Border'
import { Corners } from '../../Rightbar/Properties/Corners'
import { Layout } from '../../Rightbar/Properties/Layout'
import { PropertiesAccordion, PropertiesAccordionDetails } from '../../Rightbar/Properties/PropertiesAccordion'
import { Typography } from '../../Rightbar/Properties/Typography'
import { Shadow } from '../../Rightbar/Properties/Shadow'
import { TextShadow } from '../../Rightbar/Properties/TextShadow'
import { EditorState, Modifier } from 'draft-js'
import { Variables } from '../../Rightbar/Properties/Variables'

export const TextProperties = () => {
	const { actions: {setProp}, id } = useNode((node) => ({
		id: node.id	
	}))
	const [open, setOpen] = useState<boolean>(true)

	const onAddVariable = (variable: VariableInterface) => {
		setProp((props: TextProps) => {
			const newContentState = Modifier.replaceText(props.state.getCurrentContent(), props.state.getSelection(), variable.variable_name)

			const newState = EditorState.set(props.state, {
				currentContent: newContentState
			})
			props.state = newState
		})
	}

	return <>
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<AccordionSummary id="layout"
			  expandIcon={<svg><use href="assets/icons/settings.svg#arrow"/></svg>}
				aria-controls="layout">
				Variables
			</AccordionSummary>
			<PropertiesAccordionDetails>
				<Variables label="Add Variable"
					type="TEXT" 
					onVariableSelected={onAddVariable}/>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Layout hide={['height', 'width']}/>
		<Typography />
		<TextShadow />
		<Background />
		<Corners />
		<Border />
		<Shadow />
	</>
}