import React from 'react'

import { useNode } from '@craftjs/core'
import { SectionProps } from '../../../../types/props'
import { Background } from '../../../Rightbar/Properties/Background'
import { useDevice } from '../../../../contexts/ViewProvider'

export const SectionProperties = () => {
	const { actions: {setProp}, sectionProps } = useNode((node) => ({
		sectionProps: node.data.props as SectionProps
	}))
	const { device } = useDevice()

	return <>
		{ device !== 'mobile' && 
			<Background />
		}
	</>
}