import React, { useEffect, useState } from 'react'

import { useNode } from '@craftjs/core'
import { PropertyLabel } from './PropertyLabel'
import { PropertiesField, PropertiesButton, PropertiesButtonGroup } from './PropertiesFields'

export const Mixed = ({property, square, subtype}: {property: string, square?: boolean, subtype?: string}) => {
	const { actions: {setProp}, props } = useNode((node) => ({
		props: node.data.props
	}))
	const fieldsMap: {[key: string] : { label: string, separate: string[]}} = {
		borderRadius: {
			label: 'Radius',
			separate: ['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomRightRadius', 'borderBottomLeftRadius']
		},
		padding: {
			label: 'Padding',
			separate: ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft']
		},
		margin: {
			label: 'Margin',
			separate: ['marginTop', 'marginRight', 'marginBottom', 'marginLeft']
		}
	}
	const [active, setActive] = useState<string>('')
	const [mode, setMode] = useState<string>('')
	let style = subtype ? props[subtype].style : props.style

	const onModeChange = (mode: string) => {
		setProp((props: any) => {
			let style = {...props.style}

			if (mode === 'separate') {
				if (style[property] && style[property] !== '0px') {
					fieldsMap[property].separate.forEach(item => style[item] = style[property])
				}

				delete style[property]
			} else {
				fieldsMap[property].separate.forEach(item => delete style[item])
			}

			props.style = style
		})

		setMode(mode)
	}

	const onValueChange = (value: string, property: string) => {
		setProp((props: any) => {
			if (subtype) {
				props[subtype].style[property] = `${value}px`
			} else {
				props.style[property] = `${value}px`
			}
		})
	} 

	useEffect(() => {
		if (!props.style[property] 
			&& Object.keys(props.style).some(key => fieldsMap[property].separate.includes(key))) setMode('separate') 
		else setMode('joint')
	}, [])

	return <>
		<section className="dm-edit-property-fields">
			<PropertyLabel>{fieldsMap[property].label}</PropertyLabel>
			<PropertiesField value={mode === 'separate' ? 'Mixed' : (style[property] ? parseInt(style[property]) : 0)}
				sx={{flex: 1, 'input': {color: !style[property] || style[property] === 0 ? '#7E8996':'inherit'}}}
	      size="small"
	      type={mode === 'separate' ? "text" : "number"}
	      inputProps={{min: 0}}
				disabled={mode === 'separate'}
	      onChange={event => onValueChange(event.target.value, property)}>
			</PropertiesField>
			{	!square &&
				<PropertiesButtonGroup>
					{ 
						['joint', 'separate'].map(item => <PropertiesButton key={item}
							onClick={() => onModeChange(item)}
							className={item === mode ? 'active' : ''}>
							<svg className={`dm-edit-property__mixed`}>
								<use href={`assets/icons/settings.svg#${item}-${property}`} />
							</svg>
						</PropertiesButton>)
					}
				</PropertiesButtonGroup>
			}
		</section>
		{
			mode === 'separate' && <section className="dm-edit-property-fields">
				<PropertyLabel sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
					<svg style={{width: '19px', height: '19px', marginRight: '4px'}}>
						<use href={`assets/icons/settings.svg#${active ? active : property}`} />
					</svg>
				</PropertyLabel>
				{	fieldsMap[property].separate.map(item => 				
					<PropertiesField value={style && style[item] ? parseInt(style[item]) : 0}
						sx={{width: '51px'}}
						key={item}
			      size="small"
			      type="number"
			      inputProps={{min: 0}}
						onFocus={() => setActive(item)}
						onBlur={() => setActive('')}
			      onChange={event => setProp((props: any) => props.style[item] = `${event.target.value}px`)}>
					</PropertiesField>)
				}
			</section>
		}
	</>
}

 
