import React, { createContext, PropsWithChildren, useContext } from 'react'

export interface  CoordinatesInterface {
	x: number
	y: number
}

const CoordinatesContext = createContext(null)

const useCoordinates = (): CoordinatesInterface => {
	const coordinates = useContext(CoordinatesContext)

	return coordinates
}

const CoordinatesProvider = ({value, children}: PropsWithChildren<{value: CoordinatesInterface }>) => (
	<CoordinatesContext.Provider value={value}>{children}</CoordinatesContext.Provider>
)

export { useCoordinates, CoordinatesProvider }
