import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { PropertiesAccordionSummary, PropertiesAccordion, PropertiesAccordionDetails } from './PropertiesAccordion'
import { MenuItem } from '@mui/material'
import { ColorPicker } from './ColorPicker'
import { PropertyLabel } from './PropertyLabel'
import { PropertiesField, SmallPropertiesSelect, PropertiesButton, PropertiesButtonGroup } from './PropertiesFields'

export const Border = ({equal}: {equal?: boolean}) => {
	const { actions: {setProp}, props } = useNode((node) => ({
		props: node.data.props
	}))
	const [open, setOpen] = useState<boolean>(true)
	const borders: string[] = ['borderTop', 'borderRight', 'borderBottom', 'borderLeft']

	const onBorderAction = (side: string) => {
		setProp((props: any) => {
			let border = {...props.border}
			let sides: string[]

			if (!border.sides) {
				sides = [side]
			} else {
				sides = [...border.sides]

				if (side === 'full') {
					if (sides.includes(side)) sides = []
					else sides = ['full']
				} else {
					let index = sides.indexOf(side)

					if (index === -1) {
						if (sides.length === 3) sides = ['full']
						else if (sides.includes('full')) sides = [side]
						else sides.push(side)
					} else {
						sides.splice(index, 1)
					}
				}
			}

			props.border = {...props.border, sides}
		})
	}

	const onSetBorderProperty = (property: string, value: string) => {
		setProp((props: any) => {
			let border = {...props.border}

			if (!props.border) {
				border['sides'] = ['full']
			}
			border[property] = value
			
			props.border = border
		})
	}

	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Border"/>
		<PropertiesAccordionDetails>
			{ !equal &&
				<section className="dm-edit-property-fields">
					<PropertyLabel>Sides</PropertyLabel>
					<PropertiesButtonGroup>
						{ 
							['full', ...borders].map(item => <PropertiesButton key={item}
								onClick={() => onBorderAction(item)}
								className={(props.border && props.border.sides && props.border.sides.includes(item)) || (!props.border && item === 'full') ? 'active': ''}>
								<div className={`dm-edit-property__border dm-edit-property__border--${item}`}></div>
							</PropertiesButton>)
						}
					</PropertiesButtonGroup>	
				</section>
			}
			<section className="dm-edit-property-fields">		
				<PropertyLabel>Width</PropertyLabel>
				<PropertiesField value={props.border && props.border.width ? parseInt(props.border.width) : 0}
		      size="small"
		      type="number"
					sx={{'input': {color: !props.border || !props.border.width || parseInt(props.border.width) === 0 ? '#7E8996':'inherit'}}}
		      inputProps={{min: 0}}
		      onChange={event => onSetBorderProperty('width', `${parseInt(event.target.value)}px`)}>
				</PropertiesField>
				<PropertyLabel>Style</PropertyLabel>
				<SmallPropertiesSelect size="small"
	        value={ props.border && props.border.style ? props.border.style : 'solid' }
	        onChange={(event) => onSetBorderProperty('style', event.target.value as string)}>
	        <MenuItem value="solid">Solid</MenuItem>
	        <MenuItem value="dashed">Dashed</MenuItem>
	        <MenuItem value="dotted">Dotted</MenuItem>
	        <MenuItem value="double">Double</MenuItem>
					<MenuItem value="groove">Groove</MenuItem>
	        <MenuItem value="ridge">Ridge</MenuItem>
	        <MenuItem value="inset">Inset</MenuItem>
					<MenuItem value="outset">Outset</MenuItem>
	      </SmallPropertiesSelect>
			</section>
			<section className="dm-edit-property-fields">		
				<ColorPicker color={props.border && props.border.color ? props.border.color : '#000000'} 
					onColorChange={(color: string) => onSetBorderProperty('color', color)}/>
			</section>
		</PropertiesAccordionDetails>
	</PropertiesAccordion>
}
