import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Draggable, { DraggableEventHandler } from 'react-draggable'

import { useNode, useEditor, Element } from '@craftjs/core'
import { ROOT_NODE } from '@craftjs/utils'
import { Resizable, ResizeCallback } from 're-resizable'
import { useDevice, useZoom } from '../../../../contexts/ViewProvider'
import { ItemControl } from '../../../Rightbar/Properties/ItemControl'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Section } from '../../../Elements/Website/Section/Section'
import _ from 'lodash'
import { Column } from '../../../Elements/Website/Section/Column/Column'

export const WebsiteRenderNode = React.memo(({ render }: {render: any}) => {
  const { id } = useNode()
  const { actions, query } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id)
  }))
  const {device} = useDevice()
  const [sectionPlace, setSectionPlace] = useState<string>(null)

  const {
    connectors: {connect},
    actions: {setProp},
    hovered, 
    dom,
    name,
    moveable,
    deletable,
    props,
    connectors: { drag },
    node,
    parentNode,
    parent,
    active,
    resizableProps
  } = useNode((node) => ({
    id: node.id,
    node: node,
    hovered: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parentNode: node.data.parent ? query.node(node.data.parent).get() : null,
    parent: node.data.parent,
    props: node.data.props,
    active: node.data.name != 'Card' && node.events.selected,
    resizableProps: node.data.custom?.landing_page?.resizableProps ? node.data.custom.landing_page.resizableProps : {}
  }))
  
  const currentRef: any = useRef<any>()
  const [offset, setOffset] = useState<{x: number, y: number}>({x: 0, y: 0})
  const {zoom} = useZoom()

  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 }
    return {
      top: `${top}px`,
      left: `${left}px`,
    }
  }, [])

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef

    if (!currentDOM) return
    const { top, left } = getPos(dom)
    
    currentDOM.style.transform = `translate(${left}, ${top})`
  }, [dom, getPos])

  useEffect(() => {
    const { current: currentDOM } = currentRef

    if (!currentDOM) return
    const { top, left } = getPos(dom)
    
    currentDOM.style.transform = `translate(${left}, ${top})`
    setOffset({x: parseInt(top), y: parseInt(left)})
  }, [device, props.mobileStyle, props.fit])

  useEffect(() => {
    document
      .querySelector('.dm-edit-workspace')
      .addEventListener('scroll', scroll)

    return () => {
      document
        .querySelector('.dm-edit-workspace')
        .removeEventListener('scroll', scroll)
    }
  }, [scroll])

  useEffect(() => {
    if (dom && !['Website', 'LandingPage'].includes(node.data.name)) {
      if (active || hovered) dom.classList.add('component-selected')
      else dom.classList.remove('component-selected')
    }
  }, [dom, active])

  const onResize: ResizeCallback = (event, direction, refToElement, delta) => {
    if (refToElement.clientHeight > 0 && refToElement.clientWidth > 0) {
      const width = refToElement.clientWidth
      const height = refToElement.clientHeight

      setProp((itemProps: any) => {
        if (node.data.name === 'Section') {
          if (device === 'mobile') {
            let new_mobile: CSSProperties = {...itemProps.mobileStyle} 
            new_mobile.minHeight = height

            itemProps.mobileStyle = new_mobile
          } else {
            itemProps.style.minHeight = height
          }
        } else if (node.data.name === 'WebsiteImage' && itemProps.fit === 'full') {
          itemProps.grid_height = height/getGridSize(device).y
        } else {
          if (props.style.gridColumnEnd) {
            let current_x = parseInt(props.style.gridColumnEnd.replace('span ', ''))
            let x_gap = width/getGridSize(device).x

            if (current_x !== x_gap) {
              updateStyle(itemProps, 'gridColumnEnd', `span ${x_gap}`)
  
              if (direction.toLowerCase().includes('left')) {
                let new_x = props.style.gridColumnStart - (x_gap - current_x)
                updateStyle(itemProps, 'gridColumnStart', new_x < 0 ? 0 : new_x)
              }
            }
          }

          if (props.style.gridRowEnd) {
            let current_y = parseInt(props.style.gridRowEnd.replace('span ', ''))
            let y_gap = height/getGridSize(device).y

            if (current_y !== y_gap) {
              updateStyle(itemProps, 'gridRowEnd', `span ${y_gap}`)

              if (direction.toLowerCase().includes('top')) {
                let new_y = props.style.gridRowStart - (y_gap - current_y)
                updateStyle(itemProps, 'gridRowStart', new_y < 0 ? 0 : new_y)
              }
            }
          }
        }
      })
    }
  }

  const updateStyle = (props: any, key: string, value: string|number) => {
    if (device === 'mobile') {
      if (!props.mobileStyle) {
        props.mobileStyle = {}
      }

      props.mobileStyle[key] = value
    } else {
      props.style[key] = value
    }
  }

  const getGridSize = (device: string) => {
    let x = device === 'mobile' ? 11 : 25
    let y = device === 'mobile' ? 8 : 15

    return {x, y}
  }

  const handleDoubleClick = () => {
    if (node.data.name == 'Text') {
      actions.history.ignore().setProp(node.id, (itemProps: any) => itemProps.editing = true)
    }
  }

  const handleClick = () => {
    actions.selectNode(id)
  }

  const onLayerUp = () => {
    actions.selectNode(parent)
  }

  const handleDrag: DraggableEventHandler = (e, data) => {
    // setProp((prop: any) => {
    //   prop.x = Math.round(data.x)
    //   prop.y = Math.round(data.y)
    // }, 100)
  }

  const handleStop: DraggableEventHandler = (e, data) => {
    let parent_position = parentNode.dom.getBoundingClientRect()
    let parent_offset = 0

    if (node.data.name === 'WebsiteImage' && props.fit === 'full') {
      setProp((itemProps: any) => itemProps.grid_top = Math.ceil(data.lastY - parent_position.y)/getGridSize(device).y)
    } else {  
      if (parentNode.data.name === 'Section' && device !== 'mobile') {
        parent_offset = 200
      }
      let node_x = Math.ceil((data.lastX - parent_position.x - parent_offset)/getGridSize(device).x) + 1
      let node_y = Math.ceil(data.lastY - parent_position.y)/getGridSize(device).y + 1
  
      setProp((itemProps: any) => {
        updateStyle(itemProps, 'gridColumnStart', node_x)
        updateStyle(itemProps, 'gridRowStart', node_y)
      })
    }
  }

  const addSection = (columns: number|null) => {
    let siblings = query.node(parent).get().data.nodes
    let index = siblings.findIndex(item => item === node.id)
    
    let new_section = query.parseReactElement(SectionWithColumns(columns)).toNodeTree()


    actions.addNodeTree(new_section, parent, index+(sectionPlace === 'after' ? 1 : 0))
    setSectionPlace(null)
  }

  const getAddSectionButton = (placement: string) => {
    return <Button className={`dm-edit-add-section dm-edit-add-section--${placement}`}
      onClick={() => setSectionPlace(placement)}
      variant="contained">Add Section</Button>
  }

  return <>
    {(active)
      && !(node.data.name == 'Image' &&  props.crop)
      && !(node.data.name == 'Text' &&  props.editing)
      && node.id !== ROOT_NODE 
      && !['Website', 'LandingPage', 'Column'].includes(node.data.name)
      ? ReactDOM.createPortal(
        <Draggable onStop={handleStop} 
          onDrag={handleDrag} 
          position={{x: parseInt(getPos(dom).left), y: parseInt(getPos(dom).top)}} 
          handle=".dm-edit-render-indicator"
          cancel='.dm-edit-handle, .dm-edit-move'
          defaultClassName={props.absolute ? 'dm-edit-draggable' : 'dm-edit-not-draggable'}
          grid={[getGridSize(device).x, getGridSize(device).y]}>
          <div onDoubleClick={handleDoubleClick}
            onClick={handleClick}
            ref={currentRef}
            style={{
              position: 'absolute',
              pointerEvents: props.editing ? 'none' : 'auto'
            }}>
            <Resizable ref={currentRef}
              style={props.style && props.style.transform ? { transform: props.style.transform } : {}}
              {...resizableProps}
              handleClasses={{
                top: 'dm-edit-handle dm-edit-handle--top',
                right: 'dm-edit-handle dm-edit-handle--right',
                bottom: 'dm-edit-handle dm-edit-handle--bottom',
                left: 'dm-edit-handle dm-edit-handle--left',
                topRight: 'dm-edit-handle dm-edit-handle--topRight',
                bottomRight: 'dm-edit-handle dm-edit-handle--bottomRight',
                bottomLeft: 'dm-edit-handle dm-edit-handle--bottomLeft',
                topLeft: 'dm-edit-handle dm-edit-handle--topLeft'
              }}
              onResize={onResize}
              className={`dm-edit-render-indicator dm-edit-render-indicator--${node.data.name.toLowerCase()}`}
              size={{
                width: dom ? dom.offsetWidth : 0, 
                height: dom ? dom.offsetHeight : 0}}
              grid={[getGridSize(device).x, getGridSize(device).y]}>
              <div className="dm-edit-rendernode__name">
                <ItemControl selected={{
                  	id: node.id,
                    node: node,
                    deletable: deletable
                }}>
                  <div ref={drag}
                    className="dm-edit-move">
                    <svg>
                      <use href="assets/icons/settings.svg#move" />
                    </svg>
                  </div>
                  { parentNode && parentNode.data.name !== 'Card' &&
                    <IconButton sx={{width: '24px', height: '24px', padding: 0}}
                      onClick={onLayerUp}>
                      <svg>
                        <use href={`assets/icons/settings.svg#arrow-up`}/>
                      </svg>   
                    </IconButton>
                  }
                </ItemControl>
              </div>
            </Resizable>
            {
              node.data.name == 'Section' && getAddSectionButton('before')
            }
            {
              node.data.name == 'Section' && getAddSectionButton('after')
            }
            <Dialog
              open={sectionPlace !== null}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description">
              <DialogTitle id="scroll-dialog-title">Section Format</DialogTitle>
              <DialogContent dividers={true}>
                <ul>
                  <li>
                    <Button onClick={() => addSection(null)}>1 Column</Button>
                  </li>
                  <li>
                    <Button onClick={() => addSection(2)}>2 Column</Button>
                  </li>
                  <li>
                    <Button onClick={() => addSection(3)}>3 Column</Button>
                  </li>
                  <li>
                    <Button onClick={() => addSection(4)}>4 Column</Button>
                  </li>
                </ul>
              </DialogContent>
            </Dialog>
          </div>
        </Draggable>,
        document.querySelector('.dm-edit-renderer')
      ) : null}
    {render}
  </>
})

const SectionWithColumns = (columns: number) => {
  const getColumns = () => {
    let columnsArray = []
    for(let i = 0; i < columns; i++) {
      columnsArray.push(<Element canvas 
        is={Column}
        grids={36/columns}/>)
    }

    return columnsArray
  }

  return <Element canvas
    is={Section}
    columns={columns}
    type="body"
    height="100px">
      { getColumns() }
    </Element>
}