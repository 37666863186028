import React, { PropsWithChildren } from 'react'

import { NodeHelpersType, useNode, Node } from '@craftjs/core'
import { useDevice, useMode } from '../../../../contexts/ViewProvider'
import './LandingPage.scss'

export const LandingPage = ({background, children}: PropsWithChildren<{bleed?: boolean, background: string}>) => {
  const {connectors: {connect}} = useNode()
  const {device} = useDevice()
  const mode: string = useMode()

  return <div className={`dm-edit-landing dm-edit-landing--${device} ${['proof', 'preview'].includes(mode) ? 'dm-edit-landing--live' : ''}`}
    style={{background}}
    ref={(ref) => connect(ref)}>
    { children }
  </div>
}

LandingPage.craft = {
  displayName: 'LandingPage',
  props: {
    background : "#ffffff",
    padding: 3
  },
  rules: {
    canMoveIn: (incomingNodes: Node[], currentNode: Node, helpers: NodeHelpersType) => {
      return incomingNodes.every((item: Node) => item.data.name === 'Section')
    }
  }
}
