import React, { PropsWithChildren } from 'react'

import { DesignInterface, useDesign } from '../../../../contexts/DesignContext'
import { useNode } from '@craftjs/core'
import './Card.scss'

export const Card = ({background, children, bleed = true}: PropsWithChildren<{bleed?: boolean, background: string}>) => {
  const {connectors: {connect}} = useNode()
	let { design }: { design: DesignInterface } = useDesign()

  return <div className={`dm-edit-card dm-edit-card--${design.dimensions}`}
    style={{background}}
    ref={(ref) => connect(ref)}>
    { bleed && <div className="dm-edit-card__bleed">
        <div className="dm-edit-post-line dm-edit-post-line--bleed"></div>
        <div className="dm-edit-post-line dm-edit-post-line--buffer"></div>
        <div className="dm-edit-post-line dm-edit-post-line--live"></div>
      </div>
    }
    <div className="dm-edit-cutline dm-edit-cutline--ne"></div>
    <div className="dm-edit-cutline dm-edit-cutline--nw"></div>
    <div className="dm-edit-cutline dm-edit-cutline--se"></div>
    <div className="dm-edit-cutline dm-edit-cutline--sw"></div>
    <div className="dm-edit-card__indicia">
      <div>
        FIRST-CLASS MAIL <br/>
        U.S. POSTAGE PAID <br/>
        WASHINGTON, DC <br/>
        PERMIT NO. 1
      </div>
    </div>
    <div className="dm-edit-card__address">
      <p>
      </p>
      <div>
        {`{{recipient_address}}`}
      </div>
    </div>
    <div className="dm-edit-card__canvas">
      { children }
    </div>
  </div>
}

Card.craft = {
  displayName: 'Card',
  props: {
    background : "#ffffff",
    padding: 3
  }
}
