import React, {CSSProperties, useEffect, useRef} from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { Resizable, ResizeCallback } from 're-resizable'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import { getBorder, request } from '../../../services/helperService'
import { UploadedInterface, useImages } from '../../../contexts/ImagesContext'
import { ImageProps } from '../../../types/props'
import { ImageProperties } from './ImageProperties'
import './Image.scss'

export const Image = (imageProps: ImageProps) => {
  const resizable: React.MutableRefObject<Resizable | null> = useRef<Resizable>(null),
        { connectors: {connect}, actions: {setProp}, id } = useNode((state) => ({
          id: state.id,
          selected: state.events.selected,
        })),
        {actions: {history: {ignore}}} = useEditor(),
        {uploadedImages, setUploadedFiles} = useImages(),
        enabled = { top:true, right:true, bottom:true, left:true, topRight:true, bottomRight:true, bottomLeft:true, topLeft:true },
        disabled = { top:false, right:false, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }

  let image = document.getElementById(`dm-edit-image__clone--${id}`)
  let style: CSSProperties = {...imageProps.style, ...getBorder(imageProps.border)}

  if (imageProps.absolute) {
    style.left = imageProps.x
    style.top = imageProps.y
    style.position = 'absolute'
  }

  if (!imageProps.crop) {
    style.overflow = 'hidden'
  }

  useEffect(() => {
    if (imageProps.width === 'auto' && imageProps.height === 'auto' && imageProps.imageWidth === 'auto') {
      ignore().setProp(id, (prop: any) => {
        let image = document.getElementById(`dm-edit-image__clone--${id}`)
        prop.width = image.offsetWidth
        prop.height = `${image.offsetHeight}px`
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (imageProps.url && imageProps.url.includes('pixabay')) {
      request.post(process.env.REACT_APP_API_URL + `editor/images/pixabay/save`, {id: imageProps.id})
        .then((response) => {
          let asset: UploadedInterface = response.data.asset
          setUploadedFiles([...uploadedImages, asset])
          ignore().setProp(id, (props: ImageProps) => {props.url = asset.asset_url})
        })
    }
  }, [imageProps.url])

  const handleImageDrag: DraggableEventHandler = (e, data) => {
    setProp((props: ImageProps) => {
      props.tempImageX = data.x
      props.tempImageY = data.y
    }, 500)
  }

  const onResize: ResizeCallback = (event, direction, refToElement, delta) => {
    setProp((props: ImageProps) => {
      if (imageProps.crop) {
        if (direction.toLowerCase().includes('left')) {
          let deltaX = parseInt(props.tempWidth) - refToElement.clientWidth
          if (deltaX) {
            props.tempImageX = props.tempImageX - deltaX
            props.x = props.x + deltaX
          }
        }

        if (direction.toLowerCase().includes('top')) {
          let deltaY = parseInt(props.tempHeight) - refToElement.clientHeight

          if (deltaY) {
            props.tempImageY = props.tempImageY - deltaY
            props.y = props.y + deltaY
          }
        }

        props.tempHeight =  refToElement.clientHeight + 'px'
        props.tempWidth = refToElement.clientWidth + 'px'
      }
    }, 500)
  }

  const onImageResize: ResizeCallback = (event, direction, refToElement, delta) => {
    setProp((props: ImageProps) => {
      props.tempImageHeight = refToElement.clientHeight + 'px'
      props.tempImageWidth = refToElement.clientWidth + 'px'
    }, 500)
  }

  return <Resizable
    handleClasses={{
      top: 'dm-edit-handle dm-edit-handle--top',
      right: 'dm-edit-handle dm-edit-handle--right',
      bottom: 'dm-edit-handle dm-edit-handle--bottom',
      left: 'dm-edit-handle dm-edit-handle--left',
      topRight: 'dm-edit-handle dm-edit-handle--topRight',
      bottomRight: 'dm-edit-handle dm-edit-handle--bottomRight',
      bottomLeft: 'dm-edit-handle dm-edit-handle--bottomLeft',
      topLeft: 'dm-edit-handle dm-edit-handle--topLeft'
    }}
    ref={(ref) => {
      if (ref && ref.resizable) {
        resizable.current = ref
        connect(ref.resizable)
      }
    }}
    size={{
      width: imageProps.crop ? imageProps.tempWidth : imageProps.width , 
      height: imageProps.crop ? imageProps.tempHeight : imageProps.height
    }}
    style={style}
    enable={imageProps.crop ? enabled : disabled}
    lockAspectRatio={!imageProps.crop}
    onResize={onResize}>
    <div style={{position: 'absolute', pointerEvents: 'none'}}>
      <Draggable
        bounds={imageProps.crop ? {
          left: parseInt(imageProps.tempWidth.toString()) - parseInt(image.offsetWidth.toString()), 
          right: 0,
          top: parseInt(imageProps.tempHeight.toString()) - parseInt(imageProps.tempImageHeight.toString()), 
          bottom: 0} : {}}
        position={{
          x: imageProps.crop ? imageProps.tempImageX : imageProps.imageX, 
          y: imageProps.crop ? imageProps.tempImageY : imageProps.imageY }} 
        cancel=".dm-edit-handle div"
        defaultClassName={`dm-edit-image__draggable ${imageProps.style && imageProps.style.pointerEvents === 'none' ? 'dm-edit-image__draggable--locked' : ''}`}
        onDrag={handleImageDrag}>
        <Resizable
          lockAspectRatio={true}
          // minWidth={imageProps.width === 'auto' ? (image ? image.offsetWidth : '100%')  : parseInt(imageProps.width) - parseInt(imageProps.imageX.toString())}
          // minHeight={imageProps.height === 'auto' ? (image ? image.offsetHeight : '100%') : parseInt(imageProps.height.toString()) - parseInt(imageProps.imageY.toString())}
          onResize={onImageResize}
          size={{
            width: imageProps.crop ? imageProps.tempImageWidth : imageProps.imageWidth, 
            height: imageProps.crop ? imageProps.tempImageHeight : imageProps.imageHeight
          }}>
          {
            imageProps.url ? 
            <img id={`dm-edit-image__clone--${id}`}
              className="dm-edit-image__clone"
              draggable="false"
              style={{
                opacity: imageProps.crop ? 0.5 : 0, 
                width: imageProps.crop ? imageProps.tempImageWidth : imageProps.imageWidth, 
                height: imageProps.crop ? imageProps.tempImageHeight : imageProps.imageHeight}}
              src={imageProps.url}/> : <div>Please select an image</div>
          }

        </Resizable>
      </Draggable>
    </div>
    <div style={{
      width: '100%', 
      height: '100%', 
      overflow: 'hidden',
      transform: `scaleX(${imageProps.scaleX}) scaleY(${imageProps.scaleY})`
      }}>
      <Draggable
        bounds={imageProps.crop ? {
          left: parseInt(imageProps.tempWidth.toString()) - parseInt(image.offsetWidth.toString()), 
          right: 0,
          top: parseInt(imageProps.tempHeight.toString()) - parseInt(imageProps.tempImageHeight.toString()), 
          bottom: 0} : {}}
        disabled={!imageProps.crop}
        position={{
          x: imageProps.crop ? imageProps.tempImageX : imageProps.imageX, 
          y: imageProps.crop ? imageProps.tempImageY : imageProps.imageY }} 
        onDrag={handleImageDrag}>
        <img className="dm-edit-image__main"
          style={{
            width: imageProps.crop ? imageProps.tempImageWidth : imageProps.imageWidth,
            height: imageProps.crop ? imageProps.tempImageHeight : imageProps.imageHeight}}
          draggable="false"
          src={imageProps.url}/>
      </Draggable>
    </div>
    { imageProps.crop && <>
        <div className="dm-edit-image__ind dm-edit-image__ind--tl"></div>
        <div className="dm-edit-image__ind dm-edit-image__ind--bl"></div>
        <div className="dm-edit-image__ind dm-edit-image__ind--tr"></div>
        <div className="dm-edit-image__ind dm-edit-image__ind--br"></div>
      </>
    }
  </Resizable>
}

Image.craft = {
  displayName: 'Image',
  custom: {
    mailpiece: {
      resizableProps: {
        lockAspectRatio: true
      }
    }
  },
  props: {
    imageX: 0,
    imageY: 0,
    crop: false,
    scaleX: 1,
    scaleY: 1,
  },
  related: {
    properties: ImageProperties
  }
}
