import React from 'react'
import { StartProps } from '../Start'

export const LandingPageStart = ({onClose}: StartProps) => {
	return <section className={`dm-edit-start dm-edit-start--templates`}>
		<div className="dm-edit-start__heading">
			Choose a template
		</div>
		<div className="dm-edit-start__templates">
			<div className="dm-edit-start__template"
				onClick={() => onClose({selected: {}})}>
				<div className="dm-edit-start__template-label">
					Empty Template
				</div>
			</div>
		</div>
	</section>
}