import React, { CSSProperties, useState } from 'react'

import { useNode } from '@craftjs/core'
import { ImageProps, ImageReplaceResponse } from '../../../types/props'
import { Layout } from '../../Rightbar/Properties/Layout'
import { Corners } from '../../Rightbar/Properties/Corners'
import { Border } from '../../Rightbar/Properties/Border'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../Rightbar/Properties/PropertiesAccordion'
import { LightButton } from '../../Buttons/LightButton'
import { FullPropertiesField, PropertiesButton, PropertiesButtonGroup, PropertiesFormLabel, PropertiesRadioGroup } from '../../Rightbar/Properties/PropertiesFields'
import { Shadow } from '../../Rightbar/Properties/Shadow'
import { Uploaded } from '../Uploaded/Uploaded'
import { Pixabay } from '../Pixabay/Pixabay'
import { Variables } from '../../Rightbar/Properties/Variables'
import { VariableInterface } from '../../../contexts/VariablesContext'
import { getVariablesPreview } from '../../../services/designService'
import { DesignInterface, useDesign } from '../../../contexts/DesignContext'
import { Checkbox, FormControlLabel, Radio } from '@mui/material'
import { PropertyLabel } from '../../Rightbar/Properties/PropertyLabel'
import { useDevice } from '../../../contexts/ViewProvider'

export const WebsiteImageProperties = () => {
	const { actions: {setProp}, imageProps, dom } = useNode((node) => ({
		imageProps: node.data.props as ImageProps,
		dom: node.dom
	}))
	const [open, setOpen] = useState<boolean>(true)
	const [mode, setMode] = useState<string>('properties')
	const [library, setLibrary] = useState<string|null>(null)
	const { design }: {design: DesignInterface} = useDesign()
	const { device } = useDevice()

	const onReplaceImage = (image: ImageReplaceResponse) => {
		setProp((props: ImageProps) => {
			props.url = image.url

			if (image.id) {
				props.id = image.id
			}
		})

		setMode('properties')
	}

	const onVariableSelected = (variable: VariableInterface) => {
		getVariablesPreview(design, [variable.id], 'random')
			.then((response) => {
				if (response.data && response.data.data) {
					setProp((props: ImageProps) => props.variable = {name: variable.name, id: variable.id, preview: response.data.data[0].value})
				}
			})
	}

	if (mode === 'replace') {
		return <>
			<section className="dm-edit-property-fields">
				<LightButton aria-label="cancel" 
					sx={{flex: 1, margin: '4px'}}
	        onClick={() => setMode('properties')}>
	        Cancel
	      </LightButton>
			</section>
			<section className="dm-edit-property-fields">
				<PropertiesButtonGroup>
					{ 
						['Uploaded', 'Pixabay'].map(item => <PropertiesButton key={item}
							onClick={() => setLibrary(item)}
							className={item === library ? 'active' : ''}>
							{ item } 
						</PropertiesButton>)
					}
				</PropertiesButtonGroup>
			</section>
			{
				library === 'Uploaded' ?
				<Uploaded type='replace' 
					onImageSelected={onReplaceImage} /> :
				<Pixabay type='replace' 
					onImageSelected={onReplaceImage} />
			}
		</>
	}

	const onImageFitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProp((props: ImageProps) => {
			let fit = event.target.value
			if (fit === 'full') {
				let style: CSSProperties = {
					...props.style,
					position: 'absolute',
					left: 0,
					right: 0,
					height: dom.clientHeight
				}

				delete style.gridColumnStart
				delete style.gridColumnEnd
				delete style.gridRowStart
				delete style.gridRowEnd

				props.style = style
				props.grid_top = 0
				props.grid_height = dom.clientHeight/(device === 'mobile' ? 8 : 15)
			} else if (props.fit === 'full') {
				let style: CSSProperties = {
					...props.style,
					gridColumnStart: 1,
					gridColumnEnd: 'span 4',
					gridRowStart: 1,
					gridRowEnd: 'span 3'
				}

				delete style.position
				delete style.left

				props.style = style
			}

			props.fit = fit
		})
	}
	
	return <>
		{
			imageProps.type !== 'variable' ? 
			<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
				<PropertiesAccordionSummary label="Image"/>
				<PropertiesAccordionDetails>
					{ !imageProps.variable &&
						<section className="dm-edit-property-fields">
							<PropertiesButtonGroup>
								{
									['scaleX', 'scaleY'].map((item) => <PropertiesButton key={item}
										className={(imageProps as any)[item] === -1 ? 'active':''}
										onClick={() => setProp((props: any) => props[item] = -props[item])}>
										<svg style={{width: 18}}>
											<use href={`assets/icons/settings.svg#${item}`} />
										</svg>
									</PropertiesButton>)
								}
							</PropertiesButtonGroup>
						</section>
					}
					<>
						<section className="dm-edit-property-fields">
							<PropertiesRadioGroup onChange={onImageFitChange}>
								<FormControlLabel value="contain" control={<Radio checked={imageProps.fit === 'contain'}/>} label="Fit" />
								<FormControlLabel value="cover" control={<Radio checked={!imageProps.fit || imageProps.fit === 'cover'}/>} label="Cover" />
								<FormControlLabel value="full" control={<Radio checked={imageProps.fit === 'full'}/>} label="Full Width" />
							</PropertiesRadioGroup>
						</section>
						<section className="dm-edit-property-fields">
							<LightButton aria-label="replace image" 
								sx={{flex: 1, marginLeft: 0}}
								onClick={() => {
									setMode('replace') 
									setLibrary('Uploaded')
								}}>
								Replace
							</LightButton>
						</section>
					</>
				</PropertiesAccordionDetails>
			</PropertiesAccordion> :
			<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
				<PropertiesAccordionSummary label="Variable"/>
				<PropertiesAccordionDetails>
					<Variables label={imageProps.variable ? `Using: ${imageProps.variable.name}` : 'Assign Variable'}
						type="IMAGEURL" 
						onVariableSelected={onVariableSelected}/>
				</PropertiesAccordionDetails>
			</PropertiesAccordion>
		}
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Link"/>
			<PropertiesAccordionDetails>
				<section style={{marginLeft: '-12px'}}
					className="dm-edit-property-fields">
					<PropertiesFormLabel	control={<Checkbox onChange={(value) => setProp((props: ImageProps) => props.link_enabled = value.target.checked)} 
						size="small"
						checked={imageProps.link_enabled}/>} 
						label="Link Enabled" />
				</section>
				{ imageProps.link_enabled &&
					<section className="dm-edit-property-fields">
						<PropertyLabel>Link</PropertyLabel>
						<FullPropertiesField sx={{width: '100%', "MuiInputBase-root": {width: `100%`}}}
							id="search_google"
							value={imageProps.link}
							size="small"
							type="text"
							onChange={(event) => {
								setProp((props: ImageProps) => props.link = event.target.value)
							}}
						/>
					</section>
				}
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Layout hide={['width', 'height', 'padding']}/>
		<Corners />
		<Border />
		<Shadow />
	</>
}
