import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react'
export interface  DesignInterface {
	id?: string
	campaign_id: string
	type: 'mailpiece'|'landing_page'
	dimensions?: string
	sides?: string
	state?: string
	proofed_at?: string | null
	proofed_by?: string | null
	proofed_by_name?: string | null
}
export interface DesignContextInterface { 
	design: DesignInterface 
	setDesign: Dispatch<SetStateAction<DesignInterface>> 
}

const DesignContext = createContext(null)

const useDesign = (): DesignContextInterface => {
	const [design, setDesign] = useContext(DesignContext)

	return { design, setDesign }
}

const DesignProvider = ({value, children}: PropsWithChildren<{value: [design: DesignInterface, setDesign: Dispatch<SetStateAction<DesignInterface>>]}>) => (
	<DesignContext.Provider value={value}>{children}</DesignContext.Provider>
)

export { useDesign, DesignProvider }