import React, { useEffect, useState } from 'react'

import { useNode } from '@craftjs/core'
import { ImageProps, ImageReplaceResponse } from '../../../types/props'
import { Layout } from '../../Rightbar/Properties/Layout'
import { Corners } from '../../Rightbar/Properties/Corners'
import { Border } from '../../Rightbar/Properties/Border'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../Rightbar/Properties/PropertiesAccordion'
import { LightButton } from '../../Buttons/LightButton'
import { PropertiesButton, PropertiesButtonGroup } from '../../Rightbar/Properties/PropertiesFields'
import { Shadow } from '../../Rightbar/Properties/Shadow'
import { Uploaded } from '../Uploaded/Uploaded'
import { Pixabay } from '../Pixabay/Pixabay'

export const ImageProperties = () => {
	const { actions: {setProp}, imageProps } = useNode((node) => ({
		imageProps: node.data.props as ImageProps
	}))
	const [open, setOpen] = useState<boolean>(true)
	const [mode, setMode] = useState<string>('properties')
	const [library, setLibrary] = useState<string|null>(null)

	useEffect(() => {
		return () => {
			if (imageProps.crop) {
				onCropDone()
			}
		}
	}, [])

	const onCrop = () => {
		setProp((props:ImageProps) => {
      props.crop = true
      props.tempImageHeight = props.imageHeight
      props.tempImageWidth = props.imageWidth
      props.tempHeight = props.height
      props.tempWidth = props.width
			props.tempImageX = props.imageX
			props.tempImageY = props.imageY
    })
  }

	const onCropDone = () => {
    setProp((props:ImageProps) => {
      props.crop = false
      props.imageHeight = props.tempImageHeight
      props.imageWidth = props.tempImageWidth
      props.height = props.tempHeight
      props.width = props.tempWidth
			props.imageX = props.tempImageX
			props.imageY = props.tempImageY
    })
  }

	const onCancelCrop = () => {
		setProp((props:ImageProps) => {
      props.crop = false
    })	
	}

	const onReplaceImage = (image: ImageReplaceResponse) => {
		setProp((props: ImageProps) => {
			props.url = image.url
			props.imageHeight = "auto"
			props.imageWidth = "auto"
			props.imageX = 0
			props.imageY = 0

			if (image.id) {
				props.id = image.id
			}
		})

		setMode('properties')
	}

	if (mode === 'replace') {
		return <>
			<section className="dm-edit-property-fields">
				<LightButton aria-label="cancel" 
					sx={{flex: 1, margin: '4px'}}
	        onClick={() => setMode('properties')}>
	        Cancel
	      </LightButton>
			</section>
			<section className="dm-edit-property-fields">
				<PropertiesButtonGroup>
					{ 
						['Uploaded', 'Pixabay'].map(item => <PropertiesButton key={item}
							onClick={() => setLibrary(item)}
							className={item === library ? 'active' : ''}>
							{ item } 
						</PropertiesButton>)
					}
				</PropertiesButtonGroup>
			</section>
			{
				library === 'Uploaded' ?
				<Uploaded type='replace' 
					onImageSelected={onReplaceImage} /> :
				<Pixabay type='replace' 
					onImageSelected={onReplaceImage} />
			}
		</>
	}
	
	return <>
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Image"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertiesButtonGroup sx={{marginLeft: '8px'}}>
						{
							['scaleX', 'scaleY'].map((item) => <PropertiesButton key={item}
								className={(imageProps as any)[item] === -1 ? 'active':''}
								onClick={() => setProp((props: any) => props[item] = -props[item])}>
								<svg style={{width: 18}}>
									<use href={`assets/icons/settings.svg#${item}`} />
								</svg>
							</PropertiesButton>)
						}
					</PropertiesButtonGroup>
				</section>
				{ imageProps.crop ? 
					<section className="dm-edit-property-fields">
						<LightButton aria-label="save crop" 
							sx={{flex: 1, margin: '0 4px 0 8px'}}
							onClick={onCropDone}>
							Done
						</LightButton>
						<LightButton aria-label="cancel crop" 
							sx={{flex: 1, margin: '0 4px 0 8px'}}
							onClick={onCancelCrop}>
							Cancel
						</LightButton>
					</section> : 
					<>
						<section className="dm-edit-property-fields">
							<LightButton aria-label="crop image" 
								sx={{flex: 1, margin: '4px 4px 4px 8px'}}
								onClick={onCrop}>
								Crop Image
							</LightButton>
						</section>
						<section className="dm-edit-property-fields">
							<LightButton aria-label="replace image" 
								sx={{flex: 1, margin: '4px 4px 8px 8px'}}
								onClick={() => {
									setMode('replace') 
									setLibrary('Uploaded')
								}}>
								Replace
							</LightButton>
						</section>
					</>
				}
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Layout hide={['width', 'height']}/>
		<Corners />
		<Border />
		<Shadow />
	</>
}
