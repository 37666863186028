import React, { useEffect, useRef, useState } from 'react'

import { Button } from '@mui/material'
import { ColorResult, SketchPicker } from 'react-color'
import { getRgbaParts, rgba2hex } from '../../../services/helperService'
import { PropertyLabel } from './PropertyLabel'
import { PropertiesSlider, PropertiesField } from './PropertiesFields'

interface ColorPickerProps {
	color: string
	hideOpacity?: boolean
	label?: string
	onColorChange: (color: string) => void
}

export const ColorPicker = ({color, onColorChange, hideOpacity = false, label = 'Color'}: ColorPickerProps) => {
	const [picker, setPicker] = useState<boolean>(false)
	const [background, setBackground] = useState(rgba2hex(color))
  const colorPickerRef = useRef(null)

	const onOpacityUpdate = (value: number) => {
		let rgba = getRgbaParts(color)

		if (rgba) {
			onColorChange(`rgba(${rgba[1]},${rgba[2]},${rgba[3]},${value/100})`)
		}
	}

	useEffect(() => {
		setBackground(rgba2hex(color))
	}, [color])

	const handleClickOutside = (event: MouseEvent) => {
		if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
			onPickerClosed()
		}
	}

	const onPickerOpen = () => {
		if (!picker) {
			setPicker(true)
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			onPickerClosed()
		}
	}

	const onPickerClosed = () => {
		setPicker(false)
		document.removeEventListener("mousedown", handleClickOutside)
	}

	return <>
		<PropertyLabel>{ label }</PropertyLabel>
		<div ref={colorPickerRef}
			style={{position: 'relative', flex: 1, display: 'flex'}}>
			<Button className="dm-edit-btn"
				onClick={onPickerOpen}
				sx={{flex: 1, width: '100%', minWidth: 110, fontWeight: '400 !important'}}>
				<div style={{ marginRight: '4px', width: 20, height: 20, border: '1px solid #B7BFC9', borderRadius: '1px', padding: '1px', boxSizing: 'border-box'}}>
					<div style={{backgroundColor: color, width: 16, height: 16}}></div>
				</div>
				{background.hex}
			</Button>	
			{	picker ? 
				<div style={{position: 'absolute', zIndex: 100, left: '8px'}}>
					<SketchPicker
						color={color}
						width="160"
						onChangeComplete={(color: ColorResult) => onColorChange(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)}
					/>
				</div> : null
			}
		</div>
		{
			!hideOpacity &&
			<section style={{margin: 0}}
				className="dm-edit-property-fields">
				<PropertyLabel>Opacity</PropertyLabel>
				<PropertiesField value={Math.round(background.opacity)}
					size="small"
					type="number"
					inputProps={{min: 0, max: 100}}
					onChange={event => onOpacityUpdate(parseInt(event.target.value))}>
				</PropertiesField>
				<PropertiesSlider value={Math.round(background.opacity)}
					step={1}
					min={0}
					max={100}
					onChange={(_, value) => onOpacityUpdate(value as number)} />
			</section>
		}
	</>
}
