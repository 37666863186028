import React, { CSSProperties } from 'react'

import { VideoProps } from '../../../../types/props'
import { VideoProperties } from './VideoProperties'
import { Node, useEditor, useNode } from '@craftjs/core'
import { useDevice } from '../../../../contexts/ViewProvider'
import { getStyle } from '../../../../services/helperService'
import './Video.scss'

export const Video = (videoProps: VideoProps) => {
	const {connectors: {connect}, parent} = useNode((node) => ({parent: node.data.parent}))
	const { actions: {history: {ignore}}, query: {node} } = useEditor()
	const {device} = useDevice()
  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {...getStyle(videoProps.style, videoProps.mobileStyle, device, parentNode)}

	return <div className={`dm-edit-video ${!videoProps.url && !videoProps.variable?.preview ? 'dm-edit-video--empty' : ''}`}
		ref={ref => connect(ref)}
		style={style}>
		{ (videoProps.url || videoProps.variable?.preview) ?
			<video controls={videoProps.controls} 
				autoPlay={videoProps.autoplay}
				loop={videoProps.loop}
				muted={videoProps.muted}>
				<source src={videoProps.url || videoProps.variable?.preview} type="video/mp4"/>
			</video> :
			<div style={{textAlign: 'center'}}>Please select a video</div>
		}
	</div>
}

Video.craft = {
  displayName: 'Video',
  custom: {},
  props: {
		controls: true, 
		autoplay: true, 
		loop: true,
		style: {}
	},
  related: {
    properties: VideoProperties
  }
}
