import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from './PropertiesAccordion'
import { PropertiesSelect, PropertiesButton, PropertiesButtonGroup, PropertiesField } from './PropertiesFields'
import { MenuItem } from '@mui/material'
import { PropertyLabel } from './PropertyLabel'
import { TextProps } from '../../../types/props'
import { ColorPicker } from './ColorPicker'
import { EditorState, Modifier, RichUtils } from 'draft-js'
import { getSelectionCustomInlineStyle } from 'draftjs-utils'
import { useDevice } from '../../../contexts/ViewProvider'

const fontList = ["Alegreya","Averia Serif Libre","Bitter","Cousine","Epilogue","Genos","Grandstander","Ibarra Real Nova","Kodchasan","Lato","Mali","Montserrat","Noto Sans","Nunito","Open Sans","Overpass","Playfair Display SC","Poppins","Quantico","Raleway","Roboto","Rosario","Source Sans Pro","Spectral SC","Taviraj","Tourney","Ubuntu","Urbanist","Work Sans"]
export const INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
	{label: 'Strikethrough', style: 'STRIKETHROUGH'}
]

export const Typography = () => {
	const { actions: {setProp}, id, props } = useNode((node) => ({
		id: node.id,
		props: node.data.props
	}))
	const [open, setOpen] = useState<boolean>(true)
	const {device} = useDevice()

	let fonts: any[] = []
	fontList.forEach((font, index) => fonts.push(
		<MenuItem key={index}
			value={font}>
			<img style={{height: '20px', width: 'auto'}} src={`assets/fonts/${font.replace(/\s/g, '')}@2x.png`} alt={font}/>
		</MenuItem>))

	const onApplyStyleToTheElement = (type: string, value?: any) => {
		if (type === 'fontSize') {
			if(value) {
				setProp((props: any) => {
					if (device === 'mobile') {
						props.mobileStyle.fontSize = `${value}px`
					} else {
						props.style.fontSize = `${value}px`
					}
				})
			}
		} if (type === 'fontFamily') {
			setProp((props: any) => props.style.fontFamily = value)
		}
	}

	const onFontSizeChange = (size: string) => {
		if (!size) size = '0'

		let new_state = props.state

		if (props.state.getSelection().isCollapsed()) {
			return setProp((props: TextProps) => props.style.fontSize = `${size}px`)
		}

		let current = getSelectionCustomInlineStyle(new_state, ['FONTSIZE']).FONTSIZE
		
		if (current) {
			new_state = RichUtils.toggleInlineStyle(props.state, current)
		}

		new_state = RichUtils.toggleInlineStyle(new_state, `fontsize-${size}`)
		setProp((props: TextProps) => props.state = new_state)
	}

	const getFontSize = () => {
		try {
			let size = getSelectionCustomInlineStyle(props.state, ['FONTSIZE']).FONTSIZE

			return !size ? props.style.fontSize : size.substring(9) == '0' ? '' : size.substring(9)
		} catch {
			return 18
		}
	}


	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Typography"/>
		<PropertiesAccordionDetails>
			<section className="dm-edit-property-fields">
				<PropertyLabel>Align</PropertyLabel>
				<PropertiesButtonGroup>
					{ 
						['left', 'center', 'right', 'justify'].map(item => <PropertiesButton key={item}
							onClick={() => setProp((props: any) => props.style.textAlign = item)}
							className={props.style.textAlign === item ? 'active' : ''}>
							<svg style={{width: 18, height: 18}}>
								<use href={`assets/icons/settings.svg#align-${item}`} />
							</svg>
						</PropertiesButton>)
					}
				</PropertiesButtonGroup>			
			</section>
			<section className="dm-edit-property-fields">
				<PropertyLabel>Font</PropertyLabel>
				<PropertiesSelect value={props.style.fontFamily}
	        size="small"
	        onChange={(event) => onApplyStyleToTheElement('fontFamily', event.target.value)}>
	        {fonts}
	      </PropertiesSelect>
			</section>
			<section className="dm-edit-property-fields">
				<PropertyLabel>Size</PropertyLabel>
				<PropertiesField value={parseInt(device === 'mobile' && props.mobileStyle?.fontSize ? props.mobileStyle.fontSize : props.style.fontSize)}
		      size="small"
		      type="number"
		      inputProps={{min: 0}}
		      onChange={event => onApplyStyleToTheElement('fontSize', event.target.value)}>
				</PropertiesField>
			</section>
			{/* <section className="dm-edit-property-fields">
				<PropertyLabel>Height</PropertyLabel>
				<PropertiesField value={props.style.lineHeight}
		      size="small"
		      type="number"
		      inputProps={{min: 0, step: 0.1}}
		      onChange={event => setProp((props: any) => props.style.lineHeight = parseFloat(event.target.value))}>
				</PropertiesField>
			</section> */}
			<section className="dm-edit-property-fields">
				<PropertyLabel>Effect</PropertyLabel>
				<InlineControls onChange={(state: EditorState) => setProp((props: TextProps) => props.state = state)}
					state={props.state} />
			</section>
			<section className="dm-edit-property-fields">
				<ColorPicker color={props.style.color} 
					onColorChange={(color: string) => setProp((props: any) => props.style.color = color)}/>
			</section>
			</PropertiesAccordionDetails>
	</PropertiesAccordion>
}

export const InlineControls = ({onChange, state}: {onChange: (state: EditorState) => void, state: EditorState}) => {
	const { actions: {setProp}} = useNode()

	const onTextCommand = (command: string) => {
		if (state.getSelection().isCollapsed()) {
			let selection = undefined;

			let currentContent = state.getCurrentContent()
			
			selection = state.getSelection().merge({
				anchorKey: currentContent.getFirstBlock().getKey(),
				anchorOffset: 0,  
	
				focusOffset: currentContent.getLastBlock().getText().length, 
				focusKey: currentContent.getLastBlock().getKey(),
			})

			let forcedEditorState = EditorState.forceSelection(state, selection)
			onChange(RichUtils.toggleInlineStyle(forcedEditorState, command))
		} else {
			onChange(RichUtils.toggleInlineStyle(state, command))
		}
  }

  const checkStyle = (style: string) => {
    if (state && state.getCurrentInlineStyle) {
      const currentStyles = state.getCurrentInlineStyle()
      return currentStyles.has(style)
    }

    return false
  }

	return <PropertiesButtonGroup>
		{ INLINE_STYLES.map(item => <PropertiesButton key={item.style}
				onClick={() => onTextCommand(item.style)}
				className={ checkStyle(item.style) ? 'active': ''}>
				<svg>
					<use href={`assets/icons/typography.svg#${item.label.toLowerCase()}`} />
				</svg>
			</PropertiesButton>)
		}
	</PropertiesButtonGroup>	
}

export const SubControls = ({onChange, state}: {onChange: (state: EditorState) => void, state: EditorState}) => {
	const onTextCommand = (command: string) => {
		const newContentState = Modifier.applyInlineStyle(state.getCurrentContent(), state.getSelection(),command)
		const newState = EditorState.set(state, {
			currentContent: newContentState
		})
		onChange(newState)
  }

	const checkStyle = (style: string) => {
    if (state && state.getCurrentInlineStyle) {
      const currentStyles = state.getCurrentInlineStyle()
      return currentStyles.has(style)
    }

    return false
  }

	return <PropertiesButtonGroup>
	{ ['SUPERSCRIPT','SUBSCRIPT'].map(item => <PropertiesButton key={item}
			onClick={() => onTextCommand(item)}
			className={ checkStyle(item) ? 'active': ''}>
			<svg>
				<use href={`assets/icons/typography.svg#${item.toLowerCase()}`} />
			</svg>
		</PropertiesButton>)
	}
</PropertiesButtonGroup>	
}
