import React, { useEffect, useState } from 'react'

import { Image } from './components/Images/Image/Image'
import { WebsiteImage } from './components/Images/WebsiteImage/WebsiteImage'
import { Text } from './components/Elements/Text/Text'
import { Editor } from '@craftjs/core'
import { Card } from './components/Workspace/Wrapper/Card/Card'
import { Wrapper } from './components/Workspace/Wrapper/Wrapper'
import { WebsiteWrapper } from './components/Workspace/WebsiteWrapper/WebsiteWrapper'
import { LandingPage } from './components/Workspace/WebsiteWrapper/LandingPage/LandingPage'
import { QR } from './components/Elements/Postcard/QR/QR'
import { Form } from './components/Elements/Website/Form/Form'
import { PopupForm } from './components/Elements/Website/PopupForm/PopupForm'
import { Video } from './components/Elements/Website/Video/Video'
import { Section } from './components/Elements/Website/Section/Section'
import { WebButton } from './components/Elements/Website/WebButton/WebButton'
import { Column } from './components/Elements/Website/Section/Column/Column'
import { Container } from './components/Elements/Container/Container'
import { Map } from './components/Elements/Map/Map'
import { WebMap } from './components/Elements/Website/WebMap/WebMap'
import { Rightbar } from './components/Rightbar/Rightbar'
import { RenderNode } from './components/Workspace/RenderNode/RenderNode'
import { request } from './services/helperService'
import { CompanyInterface, UserProvider } from './contexts/UserContext'
import { Renderer } from './components/Renderer/Renderer'
import './App.scss'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

function App() {
  const [user, setUser] = useState<CompanyInterface>(null)
  const [save, setSave] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(true)
  
  useEffect(() => {
    request.get(process.env.REACT_APP_API_URL + 'editor/company')
      .then((response) => {
        if (response.data.status === 'success') {
          setUser(response.data.data)
          let favicon = document.createElement('link')
          favicon.id = 'favicon'
          favicon.rel = 'icon'
          favicon.type = 'image/png'
          favicon.href = response.data.data.favicon
          
          document.getElementsByTagName('head')[0].appendChild(favicon)
        }
      })
      .catch((error) => {
        window.open(process.env.REACT_APP_API_URL + 'login', '_self')
      })
  }, [])

  const changeCallback = () => {
    if (!save) {
      setSave(true)
    }
  }

  const onToggleEditor = (state: boolean) => {
    setEnabled(state)
  }

  return <UserProvider value={[user, save, setSave]}>
    <Editor resolver={{
        WebButton, 
        Form, 
        PopupForm, 
        WebMap, 
        Renderer, 
        Image, 
        Text, 
        Card, 
        QR, 
        Wrapper , 
        Container, 
        Column, 
        Map, 
        Rightbar, 
        WebsiteWrapper, 
        LandingPage, 
        Section, 
        Video, 
        WebsiteImage
      }}
      onRender={RenderNode}
      enabled={enabled}
      onNodesChange={changeCallback}>
      { user &&
        <Renderer onToggleEditor={onToggleEditor}/>
      }
    </Editor>
  </UserProvider>
}

export default App
