import React, { useState } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { Button, Checkbox, IconButton, MenuItem, TextField } from '@mui/material'
import { FormPropsField, PopupFormProps } from '../../../../types/props'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../../Rightbar/Properties/PropertiesAccordion'
import { PropertiesSelect, PropertiesField, PropertiesFormLabel, PropertiesSlider, PropertiesButtonGroup, PropertiesButton, PropertiesIconButton } from '../../../Rightbar/Properties/PropertiesFields'
import { PropertyLabel } from '../../../Rightbar/Properties/PropertyLabel'
import { ColorPicker } from '../../../Rightbar/Properties/ColorPicker'
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd'
import { getRandomId } from '@craftjs/utils'
import { fontList } from '../../../../data/fonts'
import { Background } from '../../../Rightbar/Properties/Background'
import { Corners } from '../../../Rightbar/Properties/Corners'
import { Mixed } from '../../../Rightbar/Properties/Mixed'
import Popper from '@mui/material/Popper'
import { Shadow } from '../../../Rightbar/Properties/Shadow'


export const PopupFormProperties = () => {
	const {props, id, dom} = useNode(node => ({
		props: node.data.props as PopupFormProps, 
		id: node.id,
		dom: node.dom
	}))
	const {actions: {setProp}} = useEditor()
	const [view, setView] = useState<string>('')
  const [menu, setMenu] = useState<Element>(null)
	const [open, setOpen] = useState<boolean>(true)
	const [openTheme, setOpenTheme] = useState<boolean>(true)
	const [openButton, setOpenButton] = useState<boolean>(true)
	const [openButtonPopper, setOpenButtonPopper] = useState<boolean>(false)
	const [openField, setOpenField] = useState<{group_index: number, field_index: number}>(null)
	const [openFieldSection, setOpenFieldSection] = useState<string>('main')

	let fonts: any[] = []
	fontList.forEach((font, index) => fonts.push(
		<MenuItem key={index}
			value={font}>
			<img style={{height: '20px', width: 'auto'}} src={`assets/fonts/${font.replace(/\s/g, '')}@2x.png`} alt={font}/>
		</MenuItem>))

	const onRemoveField = (group_index: number, index: number) => {
		setProp(id, (props: PopupFormProps) => {
			let new_fields = [...props.form.fields]

			new_fields[group_index].splice(index, 1)

			props.form.fields = new_fields
		})
	}

	const getTypeSpecificSettings = (field: FormPropsField) => {
		switch (field.type) {
			case 'select':
				let has_options: boolean = field.options && field.options.length > 0
				let buttonLabel = has_options ? `Dropdown Options (${field.options.length})` : `Add Dropdown Options`
				let buttonStyle = has_options ? {}: 
					{
						backgroundColor: '#FFDDDD',
						color: '#E80000',
						'&:hover': {
							backgroundColor: '#FFC4C4',
						}
					}
				return <Button sx={{
						fontWeight: '500!important',
						margin: '8px 0',
						width: '100%',
						fontSize: '12px',
						textTransform: 'none',
						...buttonStyle
					}}
					onClick={() => setOpenFieldSection('options')}>
						{ buttonLabel }
					</Button>
		}
	}

	const getFields = (group: FormPropsField[], group_index: number) => {
		let fields: JSX.Element[] = []
		group.forEach((field, field_index) => {
			fields.push(<Draggable key={field_index}
				index={field_index}
				draggableId={field.key}>
				{
					(provided) => <div {...provided.draggableProps}
						ref={provided.innerRef}
						className="dm-edit-form-props__field">
						<div className="dm-edit-form-props__field-main"
							onClick={(event) => {
								setMenu(event.currentTarget)
								setOpenField({
									group_index, 
									field_index
								})
								setOpenFieldSection('main')
							}}>
							<div className="dm-edit-form-props__field-drag"
								{...provided.dragHandleProps}>
			          <svg>
			            <use href="assets/icons/settings.svg#drag" />
			          </svg>
							</div>
							<div style={{flex: 1}}>{field.label}</div>
							<IconButton className="dm-edit-form-props__field-gear">
			          <svg>
			            <use href="assets/icons/settings.svg#gear" />
			          </svg>
			        </IconButton>
						</div>
						<IconButton className="dm-edit-form-props__field-remove"
							onClick={() => onRemoveField(group_index, field_index)}>
		          <svg>
		            <use href="assets/icons/toolbar.svg#close" />
		          </svg>
		        </IconButton>
					</div>
				}
			</Draggable>)
		})

		return fields
	}

	const onSwitchView = () => {
		setProp(id, (props: PopupFormProps) => props.open = !props.open)
	}

	return <div>
		<Button sx={{
			backgroundColor: '#FFF4CF',
			color: '#B26D2D',
			textTransform: 'none',
			width: '100%',
			fontSize: '12px',
			marginBottom: '8px',
			'&:hover': {
				backgroundColor: '#B26D2D',
				color: '#FFFFFF'
			}
		}}
		onClick={onSwitchView}>
			{ props.open ? 'Hide' : 'Show'} Form Configuration
		</Button>
		{/* Button Label */}
		<PropertiesAccordion expanded={openTheme} onChange={() => setOpenTheme(!openTheme)}>
			<PropertiesAccordionSummary label="Button Label"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Label</PropertyLabel>
					<PropertiesField value={props.label}
						size="small"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: PopupFormProps) => props.label = event.target.value)}>
					</PropertiesField>
				</section>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.style.color} 
						hideOpacity={true}
						label="Color"
						onColorChange={(color: string) => setProp(id, (props: PopupFormProps) => props.style.color = color)}/>
					<PropertiesIconButton className={props.style.fontWeight === 'bold' ? 'active':''}
						onClick={event => setProp(id, (props: PopupFormProps) => props.style.fontWeight = props.style.fontWeight === 'bold' ? 'normal' : 'bold' )}>
						<svg>
							<use href={`assets/icons/typography.svg#bold`} />
						</svg>
					</PropertiesIconButton>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Size</PropertyLabel>
					<PropertiesField value={props.style.fontSize}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: PopupFormProps) => props.style.fontSize = parseInt(event.target.value))}>
					</PropertiesField>
					<PropertyLabel>Tracking</PropertyLabel>
					<PropertiesField value={parseFloat(`${props.style.letterSpacing}`)}
						size="small"
						type="number"
						inputProps={{min: 0, step: 0.1}}
						onChange={event => setProp(id, (props: PopupFormProps) => props.style.letterSpacing = `${event.target.value}px`)}>
					</PropertiesField>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>

		{/* Button Shape */}
		<PropertiesAccordion expanded={openTheme} onChange={() => setOpenTheme(!openTheme)}>
			<PropertiesAccordionSummary label="Button Shape"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.style.backgroundColor} 
						hideOpacity={true}
						label="Color"
						onColorChange={(color: string) => setProp(id, (props: PopupFormProps) => props.style.backgroundColor = color)}/>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Radius</PropertyLabel>
					<PropertiesField value={parseInt(`${props.style.borderRadius}`)}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: PopupFormProps) => props.style.borderRadius = `${event.target.value}px`)}>
					</PropertiesField>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Shadow />
	</div>
}
