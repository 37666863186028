import React, { useEffect, useState } from 'react'
import { ROOT_NODE, useEditor } from '@craftjs/core'
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import './Layers.scss'
import { useDesign } from '../../../contexts/DesignContext'

export const Layers = () => {
	const {actions, tree, selected} = useEditor((state, query) => ({
		tree: {...query.node(ROOT_NODE).toNodeTree().nodes},
		selected: state.events.selected.values().next().value
	}))
	const [editing, setEditing] = useState<string>(null)
	const [expanded, setExpanded] = useState<string[]>([])
	const { design } = useDesign()

	const getNodes = (id: string) => {
		let nodes = tree[id].data.nodes.slice(0)
		// if (id !== ROOT_NODE && design.type.toLowerCase() === 'mailpiece') 
		if (id !== ROOT_NODE && tree[id].data.name !== 'LandingPage') {
			nodes.reverse()
		} 
		
		return nodes
	}

	useEffect(() => {
		if (editing) {
			document.addEventListener("mousedown", handleClickOutside)

			return () => document.removeEventListener("mousedown", handleClickOutside)
		}
  }, [editing])

	useEffect(() => {
		let node = tree[selected]

		if (node && node.data && node.data.name !== 'Card' ) {
			let parent = tree[node.data.parent]
			let new_expanded = [...expanded]
			while (parent && parent.id !== ROOT_NODE) {
				if (!expanded.includes(parent.id)) {
					new_expanded.push(parent.id)
				}

				parent = tree[parent.data.parent]
			}

			setExpanded(new_expanded)
		}

		let layers = document.getElementsByClassName('dm-edit-layers')[0]
		if(layers) {
			layers.scrollTo({
				behavior: 'smooth',
				top: 100
			})
		}
	}, [selected])

	const handleClickOutside = (event: MouseEvent) => {
    let label = document.getElementById(`label-${editing}`)
		if (label && label != event.target) {
			if (label.innerText) {
				actions.setCustom(editing, (custom) => custom.displayName = label.innerText)
			}
      setEditing(null)
		}
	}

	const onHideLayer = ($event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
		$event.stopPropagation()
		actions.selectNode(null)
		actions.setProp(id, (props) => props.style.display = props.style.display && props.style.display === 'none' ? 'initial':'none')
	}

	const onLockLayer = ($event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
		$event.stopPropagation()
		actions.selectNode(null)
		actions.setProp(id, (props) => props.style.pointerEvents = props.style.pointerEvents && props.style.pointerEvents === 'none' ? 'initial':'none')
	}

	const onLayerClick = (id: string) => {
		if (tree[id].data.name !== 'Card' && !isHidden(tree[id].data.props) && !isLocked(tree[id].data.props)) {
			actions.selectNode(id)
		}
	}

	const onLayerDoubleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
		setEditing(id)
	}

	const isExpanded = (id: string): boolean => {
		if (id === ROOT_NODE) return true

		return expanded.includes(id)
	}

	const onToggleLayer = (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation()
		let index = expanded.indexOf(id)
		let new_expanded = [...expanded]

		if (index > -1) {
			new_expanded.splice(index, 1)
		} else {
			new_expanded.push(id)
		}

		setExpanded(new_expanded)
	}

	const isHidden = (props: any) => {
		return props.style && props.style.display && props.style.display === 'none'
	}

	const isLocked = (props: any) => {
		return props.style && props.style.pointerEvents && props.style.pointerEvents === 'none'
	}

	const onDragEnd = (result: DropResult) => {
		if (result.destination) {
			let new_siblings: string[] = tree[result.destination.droppableId].data.nodes
			let current_index = new_siblings.indexOf(result.draggableId)
			let index: number = result.destination.index

			if (result.type !== 'landing') {
				if (new_siblings.length > 0) {
					index = new_siblings.length - result.destination.index
				}
		
				if (current_index > -1 && current_index >= index) {
					index -= 1
				}
			}
	
			actions.move(result.draggableId, result.destination.droppableId, index)
		}

		if (result.combine && tree[result.combine.draggableId].data.isCanvas) {
			let new_siblings: string[] = tree[result.combine.draggableId].data.nodes

			actions.move(result.draggableId, result.combine.draggableId, new_siblings.length - 1)
		}
	}

	const getNodeType = (id: string): string => {
		let node = tree[id].data

		if (node.name === 'LandingPage') return 'landing'

		if (node.name === 'Section' && node.nodes.length > 0) {
			let first_child = tree[node.nodes[0]].data

			if (first_child.name === 'Column') return 'column'
		}

		return 'section'
	}

	const displayNodes = (id: string) => {
		return <Accordion key={id}
			expanded={isExpanded(id)}>
			<AccordionSummary onClick={() => onLayerClick(id)}
				onDoubleClick={(event) => onLayerDoubleClick(event, id)}
				className={`dm-edit-layers__item
					${ ['Card', 'LandingPage'].includes(tree[id].data.name) ? ' dm-edit-layers__item--card ' : '' }
					${ selected && selected === id && !['Card', 'LandingPage'].includes(tree[selected].data.displayName) ? `dm-edit-layers__item--active`: ''}
				`}
				expandIcon={ 
					tree[id].data.nodes.length > 0 ?
					<div className="dm-edit-layer__toggle" onClick={(event) => onToggleLayer(id, event)}>
						<svg>
							<use href="assets/icons/settings.svg#arrow"/>
						</svg>
					</div> : null
				}>
				<section>
					<svg>
						<use href={`assets/icons/elements.svg#mono-${tree[id].data.displayName.toLowerCase()}`} />
					</svg>
					<span id={`label-${id}`}
						suppressContentEditableWarning={true}
						contentEditable={editing === id}>
						{ tree[id].data.custom && tree[id].data.custom.displayName ? tree[id].data.custom.displayName : tree[id].data.displayName}
					</span>
				</section>
				{
					tree[id].data.name !== 'Card' &&
					<section>
						<IconButton className={`dm-edit-layer__show ${isHidden(tree[id].data.props) && 'dm-edit-layer__show--active'}`}
							size="small"
							onClick={(event) => onHideLayer(event, id)}>
							<svg>
								<use href={`assets/icons/layers.svg#${isHidden(tree[id].data.props) ? 'show' : 'hide'}`} />
							</svg>
						</IconButton>
						<IconButton className={`dm-edit-layer__show ${isLocked(tree[id].data.props) && 'dm-edit-layer__show--active'}`}
							size="small"
							onClick={(event) => onLockLayer(event, id)}>
							<svg>
								<use href={`assets/icons/layers.svg#${isLocked(tree[id].data.props) ? 'unlock' : 'lock'}`} />
							</svg>
						</IconButton>
					</section>
				}
			</AccordionSummary>
			<AccordionDetails >
				<Droppable isDropDisabled={!isExpanded(id)}
					isCombineEnabled
					droppableId={id}
					type={getNodeType(id)}>
					{(provided, snapshot) => (
						<div ref={provided.innerRef}>
							{ getNodes(id).map((node: string, index: number) => 
									<Draggable key={node}
										index={index}
										draggableId={node}>
										{(provided) => ( 
											<div ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}>
												{displayNodes(node)}
											</div>
										)}
									</Draggable>
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</AccordionDetails>		
		</Accordion>
	}

	return <div className="dm-edit-layers dm-edit-rightbar__section">
		<Accordion expanded={true}
			className="dm-edit-layers__root">
			<AccordionDetails>
				<DragDropContext onDragEnd={onDragEnd}>
					{ getNodes(ROOT_NODE).map((node: string) => displayNodes(node)) }
				</DragDropContext>
			</AccordionDetails>
		</Accordion>
	</div>
}
