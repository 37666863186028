import React, { useState } from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { InputMask } from "@react-input/mask"
import { FormProps, FormPropsField } from '../../../../types/props'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const FormRenderer = ({form}: {form: FormProps}) => {
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  let model: any = {}
  const fieldStyle = {
		display: 'block',
		width: '100%', 
		borderRadius: form.fieldStyle.borderRadius,
		'.MuiInputBase-root': {
			width: '100%',
			borderRadius: form.fieldStyle.borderRadius,
			backgroundColor: form.fieldStyle.backgroundColor,
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: `${form.fieldStyle.borderColor}!important`
		},
		'.MuiInputLabel-root.Mui-focused': {
			color: `${form.fieldStyle.borderColor}!important`
		},
		'.MuiFilledInput-underline:after': {
			borderBottomColor: `${form.fieldStyle.borderColor}!important`
		},
		'.MuiFilledInput-underline:before': {
			borderBottomColor: `${form.fieldStyle.borderColor}!important`
		},
		'.Mui-error': {
			'.MuiOutlinedInput-notchedOutline': {
				borderColor: `#d32f2f!important`
			},
			'.MuiFilledInput-underline:after': {
				borderBottomColor: `#d32f2f!important`
			},
			'.MuiFilledInput-underline:before': {
				borderBottomColor: `#d32f2f!important`
			},
		}
	}

  const checkValue = (value: string, type: string, key: string) => {
		switch (type) {
			case 'email':
				let new_errors: {[key: string]: string} = {...errors}
				let valid = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
				if (valid) {
					delete new_errors[key]
					setErrors(new_errors)
				} else if (!valid && new_errors[key]) {
					new_errors[key] = 'Please enter a valid email address.'
					setErrors(new_errors)
				}
		}
	}

  const getField = (field: FormPropsField, index: number) => {
    let extraSettings = {}
  
    switch(field.type) {
      case 'email': 
        extraSettings = {
          onBlur: (event: any) => checkValue(event.target.value, field.type, field.key),
          ...(errors[field.key] && {error: true, helperText: errors[field.key]})
        }
        break
      case 'number': 
        extraSettings = {
          type: 'number'
        }
        break
      case 'phone':
        extraSettings = {
          InputProps: {
            inputComponent: InputMaskComponent
          }
        }
    }
  
    switch(field.type) {
      case 'phone': 
      case 'text': 
      case 'number':
      case 'email': 			
        return <TextField key={index}
          sx={{
            ...fieldStyle,
            backgroundColor: form.fieldStyle.backgroundColor,
            'input': {
              borderRadius: 'inherit',
              backgroundColor: form.fieldStyle.backgroundColor
            }
          }}
          size={form.fieldStyle.size}
          value={model[field.key]}
          label={form.fieldStyle.floatingLabel ? field.label: ''}
          placeholder={field.label}
          required={field.required}
          variant={form.fieldStyle?.fieldType} 
          { ...extraSettings }
          />
      case 'select': 
        return <FormControl sx={{
            ...fieldStyle
          }} 
          size={form.fieldStyle.size}
          fullWidth>
          <InputLabel id={field.key}>
            { field.label }
          </InputLabel>
          <Select value={model[field.key]}
            labelId={field.key}
            label={ field.label }
            variant={form.fieldStyle.fieldType}
            sx={{
              backgroundColor: `${form.fieldStyle.backgroundColor}!important`
            }}>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      case 'date':
        return <DatePicker sx={{
          ...fieldStyle
        }}
        slotProps={{ textField: {
          size: form.fieldStyle.size
        }}}
        label={field.label}/>
      case 'opt-in': 
        return  <FormControlLabel sx={{marginBottom: '16px'}}
          control={<Checkbox checked={true} onChange={() => console.log('hello')} name="gilad" />}
          label={field.label || "I agree to receive event related information and offers via phone, email, and text"}
        />
    }
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <form style={{
      display: 'flex',
      flexDirection: 'column',
      gap: form.marginY
    }}>
      {	form.fields &&
        form.fields[form.activeStep].map((group, index) => <div key={index}
            className="dm-edit-form__group"
            style={{gap: form.marginX}}>
            { group.map((field, field_index) => getField(field, field_index)) }
          </div>)
      }
      <Button sx={{display: 'block', textTransform: 'none', ...form.button.style}}>
        { form.button.label }
      </Button>
    </form>
  </LocalizationProvider>
}

const InputMaskComponent = ({ inputRef, ...props }: any) => {
  return <InputMask ref={inputRef} mask="+1 (___) ___-____" replacement="_" {...props} />
}