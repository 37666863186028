import { useNode } from '@craftjs/core'
import { MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { BlockProps } from '../../../types/props'
import { Background } from '../../Rightbar/Properties/Background'
import { Border } from '../../Rightbar/Properties/Border'
import { Corners } from '../../Rightbar/Properties/Corners'
import { Layout } from '../../Rightbar/Properties/Layout'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../Rightbar/Properties/PropertiesAccordion'
import { PropertiesSelect } from '../../Rightbar/Properties/PropertiesFields'
import { PropertyLabel } from '../../Rightbar/Properties/PropertyLabel'
import { Shadow } from '../../Rightbar/Properties/Shadow'

export const ContainerProperties = () => {
	const { actions: {setProp}, props } = useNode((node) => ({
		props: node.data.props as BlockProps
	}))
	const [open, setOpen] = useState<boolean>(true)

	return <>
		<Layout />
		{/* <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Alignment"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Direction</PropertyLabel>
					<PropertiesSelect size="small"
		        value={ props.style.flexDirection || 'column' }
		        onChange={(event) => setProp((props: BlockProps) => props.style.flexDirection = event.target.value as 'row'|'column')}>
		        <MenuItem value="column">Column</MenuItem>
		        <MenuItem value="row">Row</MenuItem>
		      </PropertiesSelect>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Align</PropertyLabel>
					<PropertiesSelect size="small"
		        value={ props.style.alignItems }
		        onChange={(event) => setProp((props: BlockProps) => props.style.alignItems = event.target.value as 'flex-start'|'flex-end'|'center')}>
		        <MenuItem value="flex-start">Start</MenuItem>
		        <MenuItem value="center">Center</MenuItem>
						<MenuItem value="flex-end">End</MenuItem>
		      </PropertiesSelect>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Justify</PropertyLabel>
					<PropertiesSelect size="small"
		        value={ props.style.justifyContent }
		        onChange={(event) => setProp((props: BlockProps) => props.style.justifyContent = event.target.value as 'flex-start'|'flex-end'|'center'|'space-between'|'space-around')}>
		        <MenuItem value="flex-start">Start</MenuItem>
		        <MenuItem value="center">Center</MenuItem>
						<MenuItem value="flex-end">End</MenuItem>
						<MenuItem value="space-between">Space Between</MenuItem>
						<MenuItem value="space-around">Space Around</MenuItem>
		      </PropertiesSelect>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion> */}
		<Background />
		<Corners />
		<Border />
		<Shadow />
	</>
}