import React, { useState } from 'react'

import { Properties } from './Properties/Properties'
import { Layers } from './Layers/Layers'
import { PropertiesButton, PropertiesButtonGroup } from './Properties/PropertiesFields'
import './Rightbar.scss'

export const Rightbar = () => {
  const [mode, setMode] = useState<string>('properties')

  return <section className="dm-edit-rightbar">
    <PropertiesButtonGroup sx={{margin: '0 0 7px 0', backgroundColor: '#E7E9EB'}}>
      { 
        ['properties', 'layers'].map((item) => <PropertiesButton key={item}
          onClick={() => setMode(item)}
          sx={{textTransform: 'capitalize'}}
          className={mode === item ? 'active' : ''}>
          {item}
        </PropertiesButton>)
      }
    </PropertiesButtonGroup>
    {mode === 'properties' ? <Properties /> : <Layers />}
  </section>
}
