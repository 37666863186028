import React, { useEffect, useState } from 'react'

import { PixabayImageInterface, useImages } from '../../../contexts/ImagesContext'
import { ImageButton } from '../ImageButton/ImageButton'
import { Button, FilledInput, FormControl, IconButton, Popper } from '@mui/material'
import { UploadedProps } from '../../../types/props'
import './Pixabay.scss'

export const Pixabay = ({onNodeAdded, onImageSelected, type}: UploadedProps) => {
  const {pixabay, searchPixabay} = useImages()
  const [filter, setFilter] = useState<string>('')

  useEffect(() => {
    setFilter(pixabay.filter)
  }, [])

  return <section className={`dm-edit-sidebar dm-edit-sidebar--${type} ${pixabay.filter !== filter ? 'dm-edit-sidebar--overlay' : ''}`}>
    <div className="dm-edit-sidebar__header">
      <h1 className="dm-edit-sidebar__heading">Stock Photography</h1>
    </div>
    { pixabay.images.length > 0 && <>
        <section className="dm-edit-sidebar__top">
          <FormControl id="pixabay-search"
            sx={{zIndex:100}}
            variant="filled">
            <FilledInput disableUnderline={true}
              value={filter}
              sx={{marginTop: '15px', ':hover': {backgroundColor: '#F5F7F7'}, '&.Mui-Focused': {backgroundColor: 'initial'}}}
              size="small"
              onChange={(event) => setFilter(event.target.value)}
              startAdornment={
                <svg style={{width: '24px', height: '24px', marginRight: '10px', color: '#7E8996'}}>
                  <use href={`assets/icons/layout.svg#search`}/>
                </svg>  
              }
            />
          </FormControl>
          <Popper id="search"
      			className="dm-edit-pixabay__popper"
      			open={pixabay.filter !== filter}
      			anchorEl={document.getElementById('pixabay-search')}
            style={{width: document.getElementById('pixabay-search') ? document.getElementById('pixabay-search').offsetWidth : 'auto'}}
            nonce
            onResize={() => {}}
            onResizeCapture={() => {}}
            placement="bottom-start">
            <Button variant="text"
              className="dm-edit-pixabay__search-btn"
              onClick={() => searchPixabay(filter)}>
              Search: {filter}
            </Button>
            <IconButton onClick={() => setFilter(pixabay.filter)}>
              <svg style={{width: 12, height: 12}}>
                <use href="assets/icons/toolbar.svg#close" />
              </svg>
            </IconButton>
      		</Popper>
        </section>
        <section className="dm-edit-sidebar__middle">
          <div>
            { pixabay.images.length } results
          </div>
        </section>
        <div className="dm-edit-images dm-edit-images--tiles">
          { pixabay.images.map((file: PixabayImageInterface, index: number) => 
              <ImageButton type="pixabay" 
                mode={type}
                onCreate={type === 'replace' ? onImageSelected : onNodeAdded}
                key={index} 
                url={file.largeImageURL} 
                id={file.id} 
                name={file.largeImageURL}/>
            )
          }
        </div>
      </>
    }
  </section>
}
