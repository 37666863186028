import React, { useState } from 'react'
import { Button, Checkbox, CircularProgress, Dialog, FormControlLabel, SxProps, TextField, Theme } from '@mui/material'
import { approveDesign } from '../../services/designService'
import { DesignContextInterface, DesignInterface, useDesign } from '../../contexts/DesignContext'
import { CompanyInterface, useUser } from '../../contexts/UserContext' 
import './Approve.scss'

interface ApproveProps {
	onClose: (event: any) => void
	open: boolean
}

export const Approve = ({onClose, open = false}: ApproveProps) => {
	const [name, setName] = useState<string>('')
	const [approving, setApproving] = useState<boolean>(false)
	const labels: any = {
		image: 'Image quality is good', 
		safe: 'Safe and bleed are correct', 
		cope: 'Copy, spelling, and formatting are correct', 
		address: 'Address is accurate', 
		variables: 'Personalization rendered correctly'
	}

	const [checkboxes, setCheckboxes] = useState<any>({
		image: false, 
		safe: false, 
		cope: false, 
		address: false, 
		variables: false
	})

	const { design, setDesign }: DesignContextInterface = useDesign()
	const {user}: {user: CompanyInterface} = useUser()
	const onCheckboxToggle = (key: string, checked: boolean) => {
		let copy_checkboxes: any = {...checkboxes}
		copy_checkboxes[key] = checked

		setCheckboxes(copy_checkboxes)
	}

	const onApprove = () => {
		setApproving(true)
		approveDesign(design.campaign_id, design.id)
			.then((response) => {
				let new_design = {
					...design,
					proofed_at: response.data.design.proofed_at,
					proofed_by: response.data.design.proofed_by,
					proofed_by_name: response.data.design.proofed_by_name
				}

				setDesign(new_design)
				onClose({approved: true})
				setApproving(false)
			})
	}

	const checkButton = () => {
		return checkboxes.image 
			&& checkboxes.safe 
			&& checkboxes.cope 
			&& checkboxes.address 
			&& checkboxes.variables 
			&& name && name === user.user.full_name
	}

	return <Dialog open={open}
		sx={{
			'.MuiPaper-root': {boxShadow: '0 4px 58px 0 rgba(37,50,65,0.3)', borderRadius: '8px'}
		}}>
		<section className="dm-edit-approve">
			<h1>Approve Postcard</h1>
			<div>Review all of the elements of your creative, prior to accepting the final proof to get printed.</div>
			<section className="dm-edit-approve__checks">
				{
					Object.keys(checkboxes).map((key: string) => <FormControlLabel key={key}
						sx={{display: 'block'}}
						control={<Checkbox name={key}
							checked={checkboxes[key]} 
							onChange={(event, checked) => onCheckboxToggle(key, checked)} />}
						label={labels[key] }
					/>)
				}
			</section>
			<div className="dm-edit-approve__signature">Signature</div>
			<p className="dm-edit-approve__note">Please type your full name "{user.user.full_name}" below to sign.</p>
			<TextField value={name}
				sx={{
					marginBottom: '20px',
					width: '100%',
					'input': {
						fontFamily: 'La Belle Aurore, cursive',
						fontSize: 28,
						textAlign: 'center',
						':placeholder': {
							fontFamily: 'Inter'
						}
					}
				}}
				onChange={(event) => setName(event.target.value)}
			/>
			<Button onClick={() => onApprove()}
				disabled={!checkButton() || approving}
				variant="contained"
        sx={{
					display: 'block',
          backgroundColor: '#07C27D',
          color: '#FFF',
          fontSize: '12px',
    			lineHeight: '20px',
    			fontWeight: 'bold',
    			textTransform: 'none',
    			boxShadow: 'none',
    			padding: '6px 16px',
    			margin: '0 auto',
    			":hover": {
    				backgroundColor: "#068a59",
    				boxShadow: 'none'
    			}
        }}>
				<span style={{color: approving ? 'transparent':'inherit'}}>Approve and Send to Print</span>
				{
					approving &&
					<div style={{
							width: 24,
							height: 24,
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)'
						}}>
						<CircularProgress size={24} 
							color="inherit"/>
					</div>
				}
      </Button>
			<Button onClick={() => onClose({approved: false})}
        sx={{
					display: 'block',
          color: '#1868FD',
          fontSize: '12px',
    			lineHeight: '20px',
    			fontWeight: 'bold',
    			textTransform: 'none',
    			boxShadow: 'none',
    			padding: '6px 16px',
    			margin: '16px auto',
    			":hover": {
    				boxShadow: 'none',
    			}
        }}>
        Nevermind, don't approve now.
      </Button>
		</section>
	</Dialog>
}
