import React, { LegacyRef, useState } from 'react'

import { IconButton } from '@mui/material'
import classNames from 'classnames'
import './Toolbar.scss'

interface ToolbarProps {
  sidebar: string 
  onSidebarChange: (value: string) => void
}

export const Toolbar = React.forwardRef(({sidebar, onSidebarChange}: ToolbarProps, ref) => {
  const items: string[] = ['elements', 'images', 'pixabay']

  return <div ref={ref as LegacyRef<any>}
    className="dm-edit-toolbar">
    {
      items.map((item: string) => 
        <IconButton aria-label="Add" 
          key={item}
          className={classNames('dm-edit-toolbar__item', {
            'dm-edit-toolbar__item--active': sidebar === item
          })}
          onClick={(event) => {
            event.preventDefault()
            onSidebarChange(item)}}>
          <svg>
            <use href={`assets/icons/toolbar.svg#${item}`}/>
          </svg>     
        </IconButton>)
    }
  </div>
})
