import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react'

export interface CompanyInterface {
	address_formatted: string
	city: string
	client_id: number
	favicon: string
	logo: string
	name: string
	phone: string
	state: string
	street: string
	street_two: string
	user: {
		full_name: string
	}
	full_name: string
	zip: string
}

const UserContext = createContext(null)

const useUser = () => {
	const [user, save, setSave] = useContext(UserContext)

	return {user, save, setSave}
}

const UserProvider = ({value, children}: PropsWithChildren<{value: [ user: CompanyInterface, save: boolean, setSave: Dispatch<SetStateAction<boolean>> ]}>) => (
	<UserContext.Provider value={value}>{children}</UserContext.Provider>
)

export { useUser, UserProvider }