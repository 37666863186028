import React, { ElementType } from 'react'

import { Node, useEditor } from '@craftjs/core'
import { ItemControl } from './ItemControl'
import { PropertiesFormLabel } from './PropertiesFields'
import { Checkbox } from '@mui/material'
import { useDevice } from '../../../contexts/ViewProvider'
import './Properties.scss'

export interface SelectedInterface {
	id: string
	node: Node
	properties?: "" | ElementType<any>
	deletable: boolean
}

export const Properties = () => {
	const { device } = useDevice()
	const { actions: {setProp}, selected } = useEditor((state, query) => {
    const selected_ids: Set<string> = state.events.selected
    const currentNodeId = selected_ids.size == 1 ? selected_ids.values().next().value : null

    let selected: SelectedInterface
		let currentNode 

    if ( currentNodeId ) {
      currentNode = state.nodes[currentNodeId]
    }

		if (currentNode && currentNode.data.name !== 'Card' && currentNode.data.name !== 'LandingPage') {
			selected = {
				id: currentNodeId,
				node: currentNode,
				properties: currentNode.related && currentNode.related.properties,
				deletable: query.node(currentNodeId).isDeletable()
			}
		}

    return {
      selected
    }
  })

	const onToggleHideOnMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
		let new_state = event.target.checked
		let mobile_style = {...selected.node.data.props.mobileStyle} || {}

		if (new_state) {
			mobile_style.display = 'none'
		} else {
			delete mobile_style.display
		}

		setProp(selected.id, (props: any) => props.mobileStyle = mobile_style)
	}

	return <div className="dm-edit-rightbar__section">
		{
			selected ? 
			<div className="dm-edit-properties">
				<ItemControl selected={selected}/>
				{	device === 'mobile' &&
					<PropertiesFormLabel  sx={{marginLeft: '-4px'}}
						control={
							<Checkbox size="small"
								checked={selected.node.data.props.mobileStyle?.display === 'none'} 
								onChange={onToggleHideOnMobile}
							/>} 
						label="Hide on mobile" />
				}
				{ selected.properties && React.createElement(selected.properties) }
			</div> :
			<div className="dm-edit-properties__empty">
				<svg>
					<use href="assets/icons/settings.svg#no_properties" />
				</svg>
				<div>No Properties Available</div>
				<div>Select an element on the canvas.</div>
			</div>
		}
	</div>
}
