import React from 'react'
import { PostcardStart } from './PostcardStart/PostcardStart'
import { LandingPageStart } from './LandingPageStart/LandingPageStart'
import { useDesign } from '../../contexts/DesignContext'
import { useEditor } from '@craftjs/core'

import './Start.scss'

export interface StartProps {
	onClose: (event: any) => void
}

export const Start = () => {
	const {actions: {history}, query} = useEditor()
	const queryParams = new URLSearchParams(window.location.search)
	const {setDesign} = useDesign()

	const onPostcardStart = (selected: any): void => {
		let default_design = {
			campaign_id: queryParams.get('campaign_id'),
			type: queryParams.get('type') as 'mailpiece'|'landing_page',
		}

		if (selected.template) {
			let template = selected.template

			setDesign({
				dimensions: template.dimensions,
				state: template.state,
				...default_design
			})

			history.ignore().deserialize(JSON.parse(template.state))						
		} else {
			setDesign({
			  dimensions: selected.dimensions,
				sides: selected.sides,
			  ...default_design,
			})
		}
	}

	const onLandingPageStart = () => {
		let default_design = {
			campaign_id: queryParams.get('campaign_id'),
			type: queryParams.get('type') as 'mailpiece'|'landing_page'
		}

		setDesign(default_design)
	}

	const renderStart = () => {
		switch(queryParams.get('type')) {
			case 'mailpiece':
				return <PostcardStart onClose={onPostcardStart}/>
			case 'landing_page':
				return <LandingPageStart onClose={onLandingPageStart}/>
		}
	}

	return renderStart()
}
