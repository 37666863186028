import { Button, Select, Slider, TextField, styled, Checkbox, FormControlLabel, RadioGroup } from '@mui/material'

export const PropertiesField = styled(TextField)`
	background-color: #F5F7F7;
	border-radius: 4px;
	height: 32px;
  width: 82px;
  flex: 1;

	.MuiOutlinedInput-notchedOutline {
		border: none;
	}

  .MuiOutlinedInput-input {
    padding: 8px;
		font-size: 12px;
		line-height: 16px;
    letter-spacing: -0.17;
  }
`

export const LargePropertiesField = styled(PropertiesField)`
  min-width: 234px;

  .MuiInputBase-root {
    min-width: 234px;
  }
`

export const FullPropertiesField = styled(PropertiesField)`
  flex: 1;

  .MuiInputBase-root {
    width: 100% !important;
  }
`

export const PropertiesSelect = styled(Select)`
  width: unset;
  flex: 1;  
  border-radius: 4px;
  background-color: #F5F7F7;
	height: 32px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.17;

  .MuiSelect-select {
    padding: 8px 32px 8px 8px;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  svg {
    color: rgba(42, 57, 74, 0.5);
  }
`

export const SmallPropertiesSelect = styled(PropertiesSelect)`
  min-width: 82px;
`

export const PropertiesSlider = styled(Slider)`
  flex: 1;
  margin-right: 12px;

	.MuiSlider-thumb {
		box-shadow: 0 2px 8px 0 rgba(37, 50, 65, 0.3);
		background-color: #fff;

    &:before {
      box-shadow: none;
    }
	}

	.MuiSlider-rail {
		background-color: #DADFE3;
	}

	.MuiSlider-track {
		background-color: #2F77FD;
    border: none;
	}
`

export const PropertiesButtonGroup = styled('div')`
  display: flex;
  flex: 1;
  max-height: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #F5F7F7;
`

export const PropertiesIconButton = styled(Button)`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: #F5F7F7;
  color: #2A394A;

  svg {
    opacity: 0.5;
  }

  &.active svg {
    opacity: 1;
  }
`

export const PropertiesButton = styled(Button)`
  flex: 1;
  color: #2A394A;
  opacity: 0.5;
  text-transform: none;
  margin: 2px;
  min-width: unset;
  font-size: 12px;
  letter-spacing: 0.1px

  &:hover {
    opacity: 1;
    color: #1868FD;
  }

  &.active {
    background-color: #FFF;
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`

export const PropertiesFormLabel = styled(FormControlLabel)`
  margin: 4px;

  .MuiCheckbox-root {
    padding: 6px;
  }

  .MuiFormControlLabel-label {
    color: #7E8996;
    font-size: 12px;
    margin-right: 6px;
  }
`

export const PropertiesRadioGroup = styled(RadioGroup)`
  flex-direction: row;

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .MuiTypography-root {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
  }

  .MuiRadio-root {
    padding: 6px;
  }

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`
