import React, { useEffect, useState } from 'react'

import { StartProps } from '../Start'
import { getTemplates } from '../../../services/designService'
import { LightButton } from '../../../components/Buttons/LightButton'
import { Template } from '../../../types/main'
import axios from 'axios'

export const PostcardStart = ({onClose}: StartProps) => {
	const availablePostcardSizes = [
		{
			size: [6, 11],
			note: 'Get noticed in the mailbox with a unique size that stands out.'
		}, 
		{
			size: [6, 9],
			note: 'The more common size used, uisque neque diam.'
 		},
		{
			size: [4, 6],
			note: 'The lowest cost lorem ipsum dolor sit amet.'
		}
	]
	const availableLetterSizes = [
		{
			sides: 'single',
			size: [11, 8.5],
			note: 'Letter size mailpiece. Lorem ipsum dolor sit amet.'
		},
		{
			sides: 'double',
			size: [11, 8.5],
			note: 'Letter size mailpiece. Lorem ipsum dolor sit amet.'
		}
	]
	const [templates, setTemplates] = useState<Template[]>([])
	const [mode, setMode] = useState('size')
	const source = axios.CancelToken.source()

	useEffect(() => {
		getTemplates(source.token).then((response) => {
			setTemplates(response.data.templates)
		}).catch(e=>{})

		return () => {
			source.cancel()
		}
	}, [])

	return <section className={`dm-edit-start dm-edit-start--${mode}`}>
		{ mode === 'templates' &&
			<>
				<div className="dm-edit-start__heading">
					Choose a template
				</div>
				<div onClick={() => setMode('size')}
					className="dm-edit-start__back">
					<svg>
						<use href="assets/icons/toolbar.svg#back-arrow" />
					</svg>
					Back to postcard sizes
				</div>
			</>

		}
		{	mode === 'size' ?
			<>
				<div className="dm-edit-start__heading">
					Choose a postcard size
				</div>
				<div className="dm-edit-start__sizes">
					{
						availablePostcardSizes.map((item, index) => <div className="dm-edit-start__size"
							key={index}>
							<div className="dm-edit-start__box">
								<div className="dm-edit-start__box-out" style={{width: item.size[1]*13, height: item.size[0]*13}}>
									<div className="dm-edit-start__box-crnr"></div>
									<div className="dm-edit-start__box-size">
										{ `${item.size[0]} x ${item.size[1]}` }
									</div>
								</div>
							</div>
							<div >
								{ item.note }
							</div>
							<LightButton aria-label="preview" 
								sx={{
									width: '100%',
									display: 'none',
									margin: '24px 0 8px 0'
								}}
								variant='contained'
								onClick={() => {setMode('templates')}}>
								Choose a Template
							</LightButton>
							<LightButton aria-label="preview" 
								sx={{
									width: '100%',
									display: 'none',
									margin: 0
								}}
								onClick={() => onClose({dimensions: `${item.size[0]}x${item.size[1]}`})}>
								Use a Blank Canvas
							</LightButton>
						</div>)
					}
				</div>	
				<div className="dm-edit-start__sizes dm-edit-start__sizes--letter">
					{
						availableLetterSizes.map((item, index) => <div className="dm-edit-start__size dm-edit-start__size--letter"
							key={index}>
							<div className="dm-edit-start__box">
								<div className="dm-edit-start__box-out" style={{width: item.size[1]*10, height: item.size[0]*10}}>
									<div className="dm-edit-start__box-crnr"></div>
									<div className="dm-edit-start__box-size">
										{ `${item.size[0]} x ${item.size[1]}` }
									</div>
								</div>
							</div>
							<div >
								<strong>{ item.sides === 'single' ? 'Single-sided. ':'Double-sided. ' }</strong>{ item.note }
							</div>
							<LightButton aria-label="preview" 
								sx={{
									width: '100%',
									display: 'none',
									margin: '24px 0 8px 0'
								}}
								variant='contained'
								onClick={() => {setMode('templates')}}>
								Choose a Template
							</LightButton>
							<LightButton aria-label="preview" 
								sx={{
									width: '100%',
									display: 'none',
									margin: 0
								}}
								onClick={() => onClose({dimensions: `${item.size[0]}x${Math.floor(item.size[1])}`, sides: item.sides})}>
								Use a Blank Canvas
							</LightButton>
						</div>)
					}
				</div>	
			</> : 
			<div className="dm-edit-start__templates">
				{
					templates.map((template, index) => <div className="dm-edit-start__template"
						key={index}
						onClick={() => onClose(template)}>
						<img src={template.preview_url}/>
						<div className="dm-edit-start__template-label">
							{template.label}
						</div>
					</div>)
				}
			</div>
		}
	</section>
}
