import React, { createContext, PropsWithChildren, useContext } from 'react'

export interface VariablesInterface {
	builtInVariables: VariableInterface[],
	customVariables: VariableInterface[]
}

export interface VariableInterface {
	client_id: number
	created_at: string
	deleted_at: string
	id: number
	name: string
	type: string
	ui_group: string
	ui_group_sort: number
	updated_at: string
	variable_name: string
}

export interface PreviewVariablesInterface {
	random?: PreviewVariableInterface[]
	longest?: PreviewVariableInterface[]
	shortest?: PreviewVariableInterface[]
}

export interface PreviewVariableInterface {
	id: number
	type: string
	value: string
	variable_name: string
}

const VariablesContext = createContext([])

const useVariables = () => {
	const [variables, preview, previewVariables] = useContext(VariablesContext)

	return {variables, preview, previewVariables}
}

const VariablesProvider = ({value, children}: PropsWithChildren<{value: [ variable: VariablesInterface, preview: string, previewVariables: PreviewVariablesInterface ]}>) => (
	<VariablesContext.Provider value={value}>{children}</VariablesContext.Provider>
)

export { useVariables, VariablesProvider }