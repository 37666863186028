import React, { CSSProperties, PropsWithChildren } from 'react'

import { useNode } from '@craftjs/core'
import { MapProps } from '../../../types/props'
import { MapProperties } from './MapProperties'
import { getBorder, getInnerDimensions } from '../../../services/helperService'

export const Map = (props: PropsWithChildren<MapProps>) => {
  const {connectors: {connect}} = useNode()

  let style: CSSProperties = {
    width: props.width, 
    height: props.height, 
    ...getBorder(props.border),
    boxSizing: 'border-box'
  }

  if (props.absolute) {
    style.position = 'absolute'
    style.left = props.x
    style.top = props.y
  }

  let address= props.address.replace(/\s/g, '+'),
    map_url = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAVheFMspuy0343AI7clyiz3svRyuyJpd8&maptype=${props.maptype}&zoom=${props.zoom}&size=${parseInt(props.width)}x${parseInt(props.height)}&scale=2&center=${address}&markers=${address}`
  return <div ref={ref => connect(ref)}
    style={{...style, ...props.style}}>
    <img alt="address_map" style={{...getInnerDimensions(props), borderRadius: 'inherit'}} src={map_url}></img>
  </div>
}

Map.craft = {
  displayName: 'Map',
  props: {
    maptype: 'roadmap',
    width: '300px', 
    height: '300px',
    absolute: true,
    x: 0,
    y: 0,
    initial: true,
    zoom: 19, 
    style: {
      backgroundColor: 'rgba(229, 229, 229, 1)',
      marginLeft: '0px',
      marginRight: '0px',
      marginTop: '0px',
      marginBottom: '0px'
    }
  },
  related: {
    properties: MapProperties
  },
  onClick: () => {}
}