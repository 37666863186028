import React, { useEffect } from 'react'

import { UserComponent, Node } from '@craftjs/core'
import { useZoom } from '../../../contexts/ViewProvider'
import { WrapperProps } from '../../../types/props'

export const WebsiteWrapper: UserComponent<WrapperProps> = (props) => {
	const {zoom} = useZoom()

	useEffect(() => {
		if (!props.size && props.setSize) {
			let wrapper = document.getElementsByClassName('dm-edit-cardscale')[0].getElementsByTagName('div')[0]
			if (wrapper) {
				let new_size = {
					width: Math.ceil(wrapper.clientWidth),
					height: Math.ceil((wrapper.clientHeight + 50))
				}

				props.setSize(new_size)
			}
		}
	}, [])

	return <div style={{transform: `scale(${zoom/100}) translateX(-50%)`}}
		className="dm-edit-card-wrapper">
		{props.children}
	</div>
}

WebsiteWrapper.craft = {
	displayName: 'Website',
	props: {
	},
	rules: {
		canMoveIn: (selected: Node[]) => false,
		canMoveOut: (selected: Node[]) => false
	}
}
