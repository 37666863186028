import React, { useEffect, useState } from 'react'

import { Element, Frame, Node, UserComponent } from '@craftjs/core'
import { Card } from './Wrapper/Card/Card'
import { DesignInterface, useDesign } from '../../contexts/DesignContext'
import { useZoom } from '../../contexts/ViewProvider'
import { Wrapper } from './Wrapper/Wrapper'
import { WebsiteWrapper } from './WebsiteWrapper/WebsiteWrapper'
import { LandingPage } from './WebsiteWrapper/LandingPage/LandingPage'

import './Workspace.scss'
import { Section } from '../Elements/Website/Section/Section'

export const Workspace: UserComponent = () => {
	const {zoom} = useZoom()
	let { design }: { design: DesignInterface } = useDesign()
	const [size, setSize] = useState<{width: number, height: number}>()

	useEffect(() => {
		if (!size) {
			let wrapper = document.getElementsByClassName('dm-edit-cardscale')[0].getElementsByTagName('div')[0]
			if (wrapper) {
				let new_size = {
					width: Math.ceil(wrapper.clientWidth),
					height: Math.ceil((wrapper.clientHeight + 50))
				}

				setSize(new_size)
			}
		}
	}, [])

	const getEmptyDesigh = () => {
		switch(design.type) {
			case 'mailpiece': 
				return 	<Wrapper size={size}
					setSize={setSize}>
					<Element canvas is={Card}
						custom={{displayName: "Front"}}
						id ="root"
						background="#fff">
					</Element>
					{
						(!design.sides || design.sides === 'double') &&
						<Element canvas is={Card}
							id ="root2"
							custom={{displayName: "Back"}}
							background="#fff">
						</Element>
					}
				</Wrapper>
			case 'landing_page':
				return <WebsiteWrapper size={size}
					setSize={setSize}>
					<Element canvas is={LandingPage}
						custom={{displayName: "Landing"}}
						id ="root"
						background="#fff">
						<Element canvas
		          is={Section}
		          type="body"
		          height="100px">
							</Element>
					</Element>
				</WebsiteWrapper>
		}
	}

  return <div className="dm-edit-workspace">
		<div className={`dm-edit-cardscale`}
			style={{
				width: size ? size.width * (zoom/100) : 'auto',
				height: size ? size.height * (zoom/100): 'auto'
			}}>
			<Frame>
				{ !design.state &&
					getEmptyDesigh()
				}
			</Frame>
		</div>
	</div>
}
