import React, { useState } from 'react'

import { Menu, MenuItem } from '@mui/material'
import { useVariables, VariableInterface, VariablesInterface } from '../../../contexts/VariablesContext'
import { LightButton } from '../../Buttons/LightButton'
import { VariablesProps } from '../../../types/props'

export const Variables = ({label, type, onVariableSelected}: VariablesProps) => {
	const [menu, setMenu] = useState<Element>(null)
	const {variables}: {variables: VariablesInterface} = useVariables()

	const onAddVariable = (variable: VariableInterface) => {
		setMenu(null)
		onVariableSelected(variable)
	}

	const mapVariables = (variables: VariableInterface[]) => {
		return variables.map((variable: VariableInterface) => {
			if (variable.type === type) {
				return <MenuItem key={variable.id}
					onClick={() => onAddVariable(variable)}>
					{ variable.name }
				</MenuItem>
			}
		})
	}

	return <section className="dm-edit-property-fields">
		<LightButton aria-label="add-variable" 
			disabled={variables.builtInVariables.length === 0 && variables.customVariables.length === 0}
			sx={{flex: 1, marginLeft: 0}}
			onClick={(event) => {
				setMenu(event.currentTarget)
			}}>
			{ label }
		</LightButton>
		<Menu sx={{'.MuiPaper-root': {boxShadow: '0 4px 18px 0 rgba(37,50,65,0.24)'}}}
			MenuListProps={{sx: {padding: 0, width: 288}}}
			anchorEl={menu}
			open={menu !== null}
			onClose={() => setMenu(null)}>
			{
				mapVariables([...variables.builtInVariables, ...variables.customVariables])
			}
		</Menu>
	</section>
}