import React, {CSSProperties, useEffect, useRef} from 'react'

import { Node, useEditor, useNode } from '@craftjs/core'
import { getBorder, getStyle, request } from '../../../services/helperService'
import { UploadedInterface, useImages } from '../../../contexts/ImagesContext'
import { ImageProps } from '../../../types/props'
import { WebsiteImageProperties } from './WebsiteImageProperties'
import { useDevice } from '../../../contexts/ViewProvider'
import './WebsiteImage.scss'

export const WebsiteImage = (imageProps: ImageProps) => {
  const { connectors: {connect}, actions: {setProp}, id, parent } = useNode((node) => ({
          id: node.id,
          selected: node.events.selected,
          parent: node.data.parent
        }))
  const { actions: {history: {ignore}}, query: {node} } = useEditor()
  const { uploadedImages, setUploadedFiles } = useImages()

  const {device} = useDevice()
  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {
    ...getStyle(imageProps.style, imageProps.mobileStyle, device, parentNode), 
    ...getBorder(imageProps.border)
  }
  let attributes: any = {}

  if (imageProps.link_enabled && imageProps.link) {
    attributes = { href: imageProps.link }
  } 

  if (imageProps.fit === 'full') {
    style.top = `${imageProps.grid_top*(device === 'mobile' ? 8 : 15)}px`
    style.height = `${imageProps.grid_height*(device === 'mobile' ? 8 : 15)}px`
  }

  useEffect(() => {
    if (imageProps.url && imageProps.url.includes('pixabay')) {
      request.post(process.env.REACT_APP_API_URL + `editor/images/pixabay/save`, {id: imageProps.id})
        .then((response) => {
          let asset: UploadedInterface = response.data.asset
          setUploadedFiles([...uploadedImages, asset])
          ignore().setProp(id, (props: ImageProps) => {props.url = asset.asset_url})
        })
    }
  }, [imageProps.url])

  return <div ref={ref => connect(ref)}
    style={{
      ...style,
      overflow: 'hidden',
      transform: `scaleX(${imageProps.scaleX}) scaleY(${imageProps.scaleY})`,
      cursor: attributes.href ? 'pointer' : 'initial'
    }}
    {...attributes}>
      <img className={`dm-edit-web-image ${imageProps.fit === 'contain' ? 'dm-edit-web-image--contain':''}`}
        style={{
          width: imageProps.imageWidth,
          height: imageProps.imageHeight}}
        draggable="false"
        src={imageProps.url || imageProps.variable?.preview}/>
  </div>
}

WebsiteImage.craft = {
  displayName: 'Image',
  custom: {
    mailpiece: {
      resizableProps: {
        lockAspectRatio: true
      }
    }
  },
  props: {
    scaleX: 1,
    scaleY: 1,
  },
  related: {
    properties: WebsiteImageProperties
  }
}
