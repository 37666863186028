import React, { useEffect, useMemo, useState } from 'react'

import { UserComponent, Node } from '@craftjs/core'
import { throttle } from 'lodash'
import { CoordinatesProvider } from '../../../contexts/CoordinatesContext'
import { useZoom } from '../../../contexts/ViewProvider'
import { WrapperProps } from '../../../types/props'

export const Wrapper: UserComponent<WrapperProps> = (props) => {
	let [coordinates, setCoordinates] = useState({x: 0, y:0})
	const {zoom} = useZoom()
	const onDragOver = useMemo(() => {
    const throttled = throttle((e) => setCoordinates({x: e.clientX, y: e.clientY}), 300)
    return (e: any) => {
      e.persist()
      return throttled(e)
    }
  }, [])

	useEffect(() => {
		if (!props.size && props.setSize) {
			let wrapper = document.getElementsByClassName('dm-edit-cardscale')[0].getElementsByTagName('div')[0]
			if (wrapper) {
				let new_size = {
					width: Math.ceil(wrapper.clientWidth),
					height: Math.ceil((wrapper.clientHeight + 50))
				}

				props.setSize(new_size)
			}
		}
	}, [])

	return <CoordinatesProvider value={coordinates}>
		<div onDragOver={onDragOver}
			style={{transform: `scale(${zoom/100}) translateX(-50%)`}}
			className={`dm-edit-card-wrapper`}>
			{props.children}
		</div>
	</CoordinatesProvider>
}

Wrapper.craft = {
	displayName: 'PostCard',
	props: {
		bleed: true
	},
	rules: {
		canMoveIn: (selected: Node[]) => false,
		canMoveOut: (selected: Node[]) => false
	}
}
