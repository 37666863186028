import React, { CSSProperties } from 'react'

import { Button } from '@mui/material'
import { WebButtonProperties } from './WebButtonProperties'
import { WebButtonProps } from '../../../../types/props'
import { useEditor, useNode } from '@craftjs/core'
import { getBorder, getRgbaParts, getStyle } from '../../../../services/helperService'
import { useDevice } from '../../../../contexts/ViewProvider'

export const WebButton = (webButtonProps: WebButtonProps) => {
  const {actions: {history}, query: {node}} = useEditor()
  const {actions: {setProp}, connectors: {connect}, id, parent} = useNode((state) => ({
    id: state.id,
    parent: state.data.parent
  }))
  const {device} = useDevice()
	let parentNode = node(parent).get()
  let border = getBorder(webButtonProps.border)
  let style: CSSProperties = {
    ...border,
		...getStyle(webButtonProps.style, webButtonProps.mobileStyle, device, parentNode)
	}
  let rgba = webButtonProps.style.backgroundColor ? getRgbaParts(webButtonProps.style.backgroundColor) : null
  let hover


  if (rgba) {
    hover = `rgba(${rgba[1]},${rgba[2]},${rgba[3]},${parseInt(rgba[4]) - 0.1})`
  }

  return <Button ref={ref => connect(ref)}
    variant="outlined"
    sx={{
      ...style,
      '&:hover': {
        backgroundColor: rgba ? hover : 'inherit',
        ...border
      }
    }}>
    <div>{ webButtonProps.label }</div>
  </Button>
}

WebButton.craft = {
  displayName: 'Button',
  name: 'Button',
  props: {
    label: 'Button',
    border: {
      sides: ['full'],
      width: '1px',
      color: 'rgba(25, 118, 210, 1)',
      style: 'solid'
    },
    style: {
      padding: '5px',
      borderRadius: '1px',
      backgroundColor: 'rgba(25, 118, 210, 1)',
      color: 'rgba(255, 255, 255, 1)'
    },
    mobileStyle: {}
  },
  related: {
    properties: WebButtonProperties
  }
}
