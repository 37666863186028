import React, { useEffect, useState } from 'react'

import { useNode } from '@craftjs/core'
import { Checkbox, FormControlLabel } from '@mui/material'
import { DesignInterface, useDesign } from '../../../../contexts/DesignContext'
import { VariableInterface } from '../../../../contexts/VariablesContext'
import { VideoProps } from '../../../../types/props'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../../Rightbar/Properties/PropertiesAccordion'
import { FullPropertiesField } from '../../../Rightbar/Properties/PropertiesFields'
import { PropertyLabel } from '../../../Rightbar/Properties/PropertyLabel'
import { Variables } from '../../../Rightbar/Properties/Variables'
import { getVariablesPreview } from '../../../../services/designService'

export const VideoProperties = () => {
	const [open, setOpen] = useState<boolean>(true)
	const { actions: {setProp}, videoProps } = useNode<{videoProps: VideoProps}>((node) => ({
		videoProps: node.data.props as VideoProps
	}))
	let {design}: {design: DesignInterface} = useDesign()

	let blockStyle = {
		flex: 1, 
		display: 'flex', 
		alignItems: 'center'
	}

	const onVariableSelected = (variable: VariableInterface) => {
		getVariablesPreview(design, [variable.id], 'random')
			.then((response) => {
				if (response.data && response.data.data) {
					setProp((props: VideoProps) => props.variable = {name: variable.name, id: variable.id, preview: response.data.data[0].value})
				}
			})
	}

	return <>
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Video Settings"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<div style={blockStyle}>
						<PropertyLabel>Autoplay</PropertyLabel>
						<Checkbox size="small" 
							checked={videoProps.autoplay}
							onChange={(event) => setProp((props: VideoProps) => props.autoplay = event.target.checked)}/>
					</div>
					<div style={blockStyle}>
						<PropertyLabel>Loop</PropertyLabel>
						<Checkbox size="small" 
							checked={videoProps.loop} 
							onChange={(event) => setProp((props: VideoProps) => props.loop = event.target.checked)}/>
					</div>
				</section>
				<section className="dm-edit-property-fields">
					<div style={blockStyle}>
						<PropertyLabel>Controls</PropertyLabel>
						<Checkbox size="small" 
							checked={videoProps.controls} 
							onChange={(event) => setProp((props: VideoProps) => props.controls = event.target.checked)}/>
					</div>
					<div style={blockStyle}>
						<PropertyLabel>Muted</PropertyLabel>
						<Checkbox size="small" 
							checked={videoProps.muted} 
							onChange={(event) => setProp((props: VideoProps) => props.muted = event.target.checked)}/>
					</div>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>

		{	videoProps.type === 'static' ?
			<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
				<PropertiesAccordionSummary label="Link"/>
				<PropertiesAccordionDetails>
					<Variables label="Assign Variable" 
						type="IMAGE" 
						onVariableSelected={onVariableSelected}/>
					<section style={{marginTop: '8px'}}
						className="dm-edit-property-fields">
						<PropertyLabel>Link</PropertyLabel>
						<FullPropertiesField sx={{width: '100%', "MuiInputBase-root": {width: `100%`}}}
							id="search_google"
							value={videoProps.url}
							size="small"
							type="text"
							onChange={(event) => {
								setProp((props: VideoProps) => props.url = event.target.value)
							}}
						/>
					</section>
				</PropertiesAccordionDetails>
			</PropertiesAccordion> : 
			<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
				<PropertiesAccordionSummary label="Variable"/>
				<PropertiesAccordionDetails>
					<Variables label={videoProps.variable ? `Using: ${videoProps.variable.name}` : 'Assign Variable'}
						type="VIDEOURL" 
						onVariableSelected={onVariableSelected}/>
				</PropertiesAccordionDetails>
			</PropertiesAccordion>
		}
	</>
}