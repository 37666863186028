import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react'

interface ImagesProviderInterface {
	value: [ 
		uploaded: UploadedInterface[], 
		setUploadedFiles: Dispatch<SetStateAction<UploadedInterface[]>>,
		pixabay: PixabayInterface,
		searchPixabay: (filter: string) => void
	]
}
export interface UploadedInterface {
	s3_object: string
	file_name: string
	type: string
	updated_at: string
	created_at: string
	asset_url: string
}

export interface PixabayInterface {
	filter: string
	images: PixabayImageInterface[]
}

export interface PixabayImageInterface {
	id: number
	previewURL: string
	previewWidth: string
	previewHeight: string
	largeImageURL: string
	imageWidth: number
	imageHeight: number
}

const ImagesContext = createContext([])

const useImages = () => {
	const [uploaded, setUploadedFiles, pixabay, searchPixabay] = useContext(ImagesContext)

	return {
		uploadedImages: uploaded, 
		setUploadedFiles,
		pixabay,
		searchPixabay
	}
}

const ImagesProvider = ({value, children}: PropsWithChildren<ImagesProviderInterface>) => (
	<ImagesContext.Provider value={value}>{children}</ImagesContext.Provider>
)

export { useImages, ImagesProvider }