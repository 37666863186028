import React, { useEffect, useState } from 'react'

import { useNode } from '@craftjs/core'
import { MenuItem } from '@mui/material'
import { MapProps } from '../../../types/props'
import { Background } from '../../Rightbar/Properties/Background'
import { Layout } from '../../Rightbar/Properties/Layout'
import { PropertyLabel } from '../../Rightbar/Properties/PropertyLabel'
import { PropertiesAccordionSummary, PropertiesAccordion, PropertiesAccordionDetails } from '../../Rightbar/Properties/PropertiesAccordion'
import { FullPropertiesField, PropertiesSlider, PropertiesField, PropertiesSelect } from '../../Rightbar/Properties/PropertiesFields'
import { Corners } from '../../Rightbar/Properties/Corners'
import { Border } from '../../Rightbar/Properties/Border'
import { Shadow } from '../../Rightbar/Properties/Shadow'

export const MapProperties = () => {
	const [open, setOpen] = useState<boolean>(true)
	const { actions: {setProp}, mapProps } = useNode<{mapProps: MapProps}>((node) => ({
		mapProps: node.data.props as MapProps
	}))

	useEffect(() => {
		setProp((props: any) => props.temp_address = props.address)
		let address_input = document.getElementById('search_google') as HTMLInputElement

		const autocomplete = new google.maps.places.Autocomplete(address_input, {})
		google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace()
			if (place.place_id) {
				setProp((props: any) => {
					props.address = place.formatted_address
					props.temp_address = props.address
				})
			} else {
				// Handle address not found
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <>
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Address"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Address</PropertyLabel>
					<FullPropertiesField id="search_google"
	          value={mapProps.temp_address || ''}
	          size="small"
	          type="text"
	          onChange={(event) => {
							setProp((props: any) => props.temp_address = event.target.value)
						}}
	        />	
				</section>			
				<section className="dm-edit-property-fields">
					<PropertyLabel>Style</PropertyLabel>
					<PropertiesSelect size="small"
		        value={ mapProps.maptype }
		        onChange={(event) => setProp((props: MapProps) => props.maptype = event.target.value as string)}>
		        <MenuItem value="roadmap">Roadmap</MenuItem>
		        <MenuItem value="satellite">Satelite</MenuItem>
		        <MenuItem value="hybrid">Hybrid</MenuItem>
		        <MenuItem value="terrain">Terrain</MenuItem>
		      </PropertiesSelect>
				</section>			
				<section className="dm-edit-property-fields">
					<PropertyLabel>Zoom</PropertyLabel>
					<PropertiesField value={mapProps.zoom}
			      size="small"
			      type="number"
			      inputProps={{min: 10, max: 25}}
			      onChange={event => setProp((props: MapProps) => props.zoom = parseInt(event.target.value))}>
					</PropertiesField>	
					<PropertiesSlider value={mapProps.zoom}
						step={1}
						min={10}
						max={25}
						onChange={(_, value) => setProp((props: MapProps) => props.zoom = value as number)} />
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Layout />
		<Background />
		<Corners />
		<Border />
		<Shadow />
	</>
}
