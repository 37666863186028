import React, { useState } from 'react'

import { PropertiesAccordionSummary, PropertiesAccordion, PropertiesAccordionDetails } from './PropertiesAccordion'
import { Mixed } from './Mixed'

export const Corners = () => {
	const [open, setOpen] = useState<boolean>(true)

	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Corners"/>
		<PropertiesAccordionDetails>
			<Mixed property="borderRadius" />
		</PropertiesAccordionDetails>
	</PropertiesAccordion>
}
