import React, { useState } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { Button, Checkbox, FormControlLabel, IconButton, MenuItem, Radio, TextField } from '@mui/material'
import { FormProps, FormPropsField } from '../../../../types/props'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../../Rightbar/Properties/PropertiesAccordion'
import { PropertiesSelect, PropertiesField, PropertiesFormLabel, PropertiesSlider, PropertiesButtonGroup, PropertiesButton, PropertiesIconButton, PropertiesRadioGroup } from '../../../Rightbar/Properties/PropertiesFields'
import { PropertyLabel } from '../../../Rightbar/Properties/PropertyLabel'
import { ColorPicker } from '../../../Rightbar/Properties/ColorPicker'
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd'
import { getRandomId } from '@craftjs/utils'
import { fontList } from '../../../../data/fonts'
import { Background } from '../../../Rightbar/Properties/Background'
import { Corners } from '../../../Rightbar/Properties/Corners'
import { Mixed } from '../../../Rightbar/Properties/Mixed'
import Popper from '@mui/material/Popper'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


export const FormProperties = () => {
	const {props, id, dom} = useNode(node => ({
		props: node.data.props as FormProps, 
		id: node.id,
		dom: node.dom
	}))
	const {actions: {setProp}} = useEditor()
  const [menu, setMenu] = useState<Element>(null)
	const [open, setOpen] = useState<boolean>(true)
	const [openTheme, setOpenTheme] = useState<boolean>(true)
	const [openButton, setOpenButton] = useState<boolean>(true)
	const [openContainer, setOpenContainer] = useState<boolean>(true)
	const [openButtonPopper, setOpenButtonPopper] = useState<boolean>(false)
	const [openField, setOpenField] = useState<{group_index: number, field_index: number}>(null)
	const [openFieldSection, setOpenFieldSection] = useState<string>('main')

	let fonts: any[] = []
	fontList.forEach((font, index) => fonts.push(
		<MenuItem key={index}
			value={font}>
			<img style={{height: '20px', width: 'auto'}} src={`assets/fonts/${font.replace(/\s/g, '')}@2x.png`} alt={font}/>
		</MenuItem>))

	const onRemoveField = (group_index: number, index: number) => {
		setProp(id, (props: FormProps) => {
			let new_fields = [...props.fields[props.activeStep]]

			if (new_fields[group_index].length > 1) {
				new_fields[group_index].splice(index, 1)
			} else {
				new_fields.splice(group_index, 1)
			}

			props.fields[props.activeStep] = new_fields
		})
	}

	const onAddGroup = () => {
		setProp(id, (props: FormProps) => {
			props.fields[props.activeStep] = [...props.fields[props.activeStep], [{
				key: getRandomId().toLowerCase(),
				type: 'text',
				label: 'New Field',
				required: true
			}]]

			let draft_height = dom.clientHeight
      if (dom.clientHeight < draft_height ) {
        let y_gap = `span ${Math.ceil(draft_height/15)}`

        if (props.style.gridRowEnd !== y_gap) {
          props.style.gridRowEnd = y_gap
        }
      }
		})
	}

	const onAddStep = () => {
		setProp(id, (props: FormProps) => {
			props.fields = [...props.fields, []]
			props.activeStep = props.fields.length - 1
		})
	
	}

	const getTypeSpecificSettings = (field: FormPropsField) => {
		switch (field.type) {
			case 'select':
				let has_options: boolean = field.options && field.options.length > 0
				let buttonLabel = has_options ? `Dropdown Options (${field.options.length})` : `Add Dropdown Options`
				let buttonStyle = has_options ? {}: 
					{
						backgroundColor: '#FFDDDD',
						color: '#E80000',
						'&:hover': {
							backgroundColor: '#FFC4C4',
						}
					}
				return <Button sx={{
						fontWeight: '500!important',
						margin: '8px 0',
						width: '100%',
						fontSize: '12px',
						textTransform: 'none',
						...buttonStyle
					}}
					onClick={() => setOpenFieldSection('options')}>
						{ buttonLabel }
					</Button>
			case 'date':
				return <LocalizationProvider dateAdapter={AdapterDayjs}>
					<PropertyLabel>Min Date</PropertyLabel>
					<DatePicker sx={{
						background: '#F5F7F7',
						'.MuiOutlinedInput-notchedOutline': {
							border: 'none'
						},
						'.MuiInputBase-input': {
							height: 17,
							fontSize: '12px',
							padding: '8px'
						},
						'.MuiSvgIcon-root': {
							fontSize: '18px'
						}
					}}
					slotProps={{ textField: {
						size: 'small'
					}}}/>
					<PropertyLabel>Max Date</PropertyLabel>
					<DatePicker sx={{
						background: '#F5F7F7',
						'.MuiOutlinedInput-notchedOutline': {
							border: 'none'
						},
						'.MuiInputBase-input': {
							height: 17,
							fontSize: '12px',
							padding: '8px'
						},
						'.MuiSvgIcon-root': {
							fontSize: '18px'
						}
					}}
					slotProps={{ textField: {
						size: 'small'
					}}}/>
				</LocalizationProvider>
		}
	}

	const getFields = (group: FormPropsField[], group_index: number) => {
		let fields: JSX.Element[] = []
		group.forEach((field, field_index) => {
			fields.push(<Draggable key={field_index}
				index={field_index}
				draggableId={field.key}>
				{
					(provided) => <div {...provided.draggableProps}
						ref={provided.innerRef}
						className="dm-edit-form-props__field">
						<div className="dm-edit-form-props__field-main"
							onClick={(event) => {
								setMenu(event.currentTarget)
								setOpenField({
									group_index, 
									field_index
								})
								setOpenFieldSection('main')
							}}>
							<div className="dm-edit-form-props__field-drag"
								{...provided.dragHandleProps}>
			          <svg>
			            <use href="assets/icons/settings.svg#drag" />
			          </svg>
							</div>
							<div style={{flex: 1}}>{field.label}</div>
							<IconButton className="dm-edit-form-props__field-gear">
			          <svg>
			            <use href="assets/icons/settings.svg#gear" />
			          </svg>
			        </IconButton>
						</div>
						<IconButton className="dm-edit-form-props__field-remove"
							onClick={() => onRemoveField(group_index, field_index)}>
		          <svg>
		            <use href="assets/icons/toolbar.svg#close" />
		          </svg>
		        </IconButton>
					</div>
				}
			</Draggable>)
		})

		return fields
	}

	const getFieldSettings = (type: string) => {
		switch (type) {
			case 'main':
				return <>
					<PropertyLabel>Type</PropertyLabel>
					<PropertiesSelect value={props.fields[props.activeStep][openField.group_index][openField.field_index].type}
						size="small"
						sx={{width: '100%', margin: 0}}
						onChange={(event) => setProp(id, (props: FormProps) => props.fields[props.activeStep][openField.group_index][openField.field_index].type = event.target.value as string)}>
						<MenuItem value="date">Date</MenuItem>
						<MenuItem value="email">Email</MenuItem>
						<MenuItem value="number">Number</MenuItem>
						<MenuItem value="opt-in">Opt in</MenuItem>
						<MenuItem value="phone">Phone</MenuItem>
						<MenuItem value="select">Select</MenuItem>
						<MenuItem value="text">Text</MenuItem>
					</PropertiesSelect>
					{
						getTypeSpecificSettings(props.fields[props.activeStep][openField.group_index][openField.field_index])
					}
					<PropertyLabel>Label</PropertyLabel>
					<PropertiesField value={props.fields[props.activeStep][openField.group_index][openField.field_index].label}
						size="small"
						type="text"
						sx={{width: '100%'}}
						onChange={(event) => setProp(id, (props: FormProps) => props.fields[props.activeStep][openField.group_index][openField.field_index].label = event.target.value as string)}
					></PropertiesField>
					<PropertyLabel>Key</PropertyLabel>
					<PropertiesField value={props.fields[props.activeStep][openField.group_index][openField.field_index].key}
						size="small"
						type="text"
						sx={{width: '100%'}}
						onChange={(event) => setProp(id, (props: FormProps) => props.fields[props.activeStep][openField.group_index][openField.field_index].key = event.target.value as string)}
					></PropertiesField>
					<PropertiesFormLabel  sx={{marginLeft: '-4px'}}
						control={<Checkbox checked={props.fields[props.activeStep][openField.group_index][openField.field_index].required} size="small"/>} 
						label="Required" />
				</>
			case 'options':
				return <>
					<Button sx={{
							textTransform: 'none',
							fontWeight: '500!important'
						}}
						onClick={() => setOpenFieldSection('main')}>
						Go Back
					</Button>
					<PropertyLabel style={{width: '100%', marginBottom: '8px', lineHeight: '18px'}}>
						Dropdown Options ({props.fields[props.activeStep][openField.group_index][openField.field_index].options.length})
					</PropertyLabel>
					{
						props.fields[props.activeStep][openField.group_index][openField.field_index].options.map((item, index) => 
							<div style={{width: '100%', marginBottom: '4px', display: 'flex', alignItems: 'center'}}>
								<TextField value={item.label}
									key={index}
									size="small"
									type="text"
									placeholder="Enter Option"
									sx={{
										flex: 1,
										marginRight: '6px',
										'.MuiOutlinedInput-input': {
											padding: '8px',
											height: '18px',
											lineHeight: '18px',
											fontSize: '12px'
										},
										'.MuiOutlinedInput-notchedOutline': {
											borderColor: '#DADADA'
										}
									}}
									onChange={(event) => setProp(id, (props: FormProps) => props.fields[props.activeStep][openField.group_index][openField.field_index].options[index].label = event.target.value as string)}
								></TextField>
								<IconButton className="dm-edit-form-props__field-remove"
									onClick={() => setProp(id, (props: FormProps) => {
										let options = [...props.fields[props.activeStep][openField.group_index][openField.field_index].options]
										options.splice(index, 1)
										props.fields[props.activeStep][openField.group_index][openField.field_index].options = options
									})}>
									<svg>
										<use href="assets/icons/toolbar.svg#close" />
									</svg>
								</IconButton>
							</div>
						)
					}
					<Button sx={{
							fontWeight: '500!important',
							textTransform: 'none', 
							margin: '10px auto 0',
							display: 'block'
						}}
						onClick={() => setProp(id, (props: FormProps) => props.fields[props.activeStep][openField.group_index][openField.field_index].options.push({label: '', value: ''}))}>
						+ Add Option
					</Button>
				</>
			case 'button':
				return <>
					<PropertyLabel>Label</PropertyLabel>
					<PropertiesField value={props.button.label}
						size="small"
						type="text"
						sx={{width: '100%'}}
						onChange={(event) => setProp(id, (props: FormProps) => props.button.label = event.target.value as string)}
					></PropertiesField>
				</>
		}
	}

	const onDragEnd: OnDragEndResponder = (result) => {
		if (result.destination) {
			setProp(id, (props: FormProps) => {
				let new_fields = [...props.fields[props.activeStep]]
				let field = {...new_fields[parseInt(result.source.droppableId)].splice(result.source.index, 1)[0]}

				if (field) {
					new_fields[parseInt(result.destination.droppableId)].splice(result.destination.index, 0, field)
				}
				
				if (new_fields[parseInt(result.source.droppableId)].length === 0) {
					new_fields.splice(parseInt(result.source.droppableId), 1)
				}

				props.fields[props.activeStep] = new_fields
			})
		}
	}

	return <div>
		{/* Fields */}
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Form Elements"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertiesRadioGroup onChange={(event) => setProp(id, (props: FormProps) => props.type = event.target.value)}>
						<FormControlLabel value="single" control={<Radio checked={props.type === 'single'}/>} label="Single" />
						<FormControlLabel value="multiple" control={<Radio checked={props.type === 'multiple'}/>} label="Multiple Steps" />
					</PropertiesRadioGroup>
				</section>
				{ props.type === 'multiple' &&
					<section className="dm-edit-property-fields">
						{
							props.fields.map((field, index) => <Button key={index}
								className={`dm-edit-property__step ${props.activeStep !== index ? 'dm-edit-property__step--inactive' : ''}`}
								onClick={() => setProp(id, props => props.activeStep = index)}>
								{index + 1}
							</Button>)
						}
						<Button className="dm-edit-property__step dm-edit-property__step--add"
							onClick={onAddStep}>
							+
						</Button>
					</section>
				}
				<DragDropContext onDragEnd={onDragEnd}>
					{ props.fields[props.activeStep].map((group, group_index) => 
						<Droppable key={group_index}
							droppableId={group_index.toString()}>
							{
								(provided, snapshot) => <div className="dm-edit-form-props__group"
									ref={provided.innerRef}
									{...provided.droppableProps}>
									{ getFields(group, group_index) }
									{ provided.placeholder }
								</div>
							}
						</Droppable>)
					}
				</DragDropContext>
				<Button size="small" 
					variant="text" 
					className='dm-edit-form-props__add'
					disableRipple={true}
					onClick={() => onAddGroup()}>
					+ Add Form Element
				</Button>
				<div className="dm-edit-form-props__group">
					<div className="dm-edit-form-props__field">
						<div className="dm-edit-form-props__field-main"
							onClick={(event) => {
								setMenu(event.currentTarget)
								setOpenButtonPopper(true)
								setOpenFieldSection('button')
							}}>
							<div style={{flex: 1}}>{props.button.label}</div>
							<IconButton className="dm-edit-form-props__field-gear">
								<svg>
									<use href="assets/icons/settings.svg#gear" />
								</svg>
							</IconButton>
						</div>
					</div>
				</div>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>

		{/* Field Properties */}
		<PropertiesAccordion expanded={openTheme} onChange={() => setOpenTheme(!openTheme)}>
			<PropertiesAccordionSummary label="Form Elements Style"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Type</PropertyLabel>
					<PropertiesSelect value={props.fieldStyle.fieldType}
						size="small"
						onChange={(event) => setProp(id, props => props.fieldsStyle.type = event.target.value)}>
						<MenuItem value="outlined">Oulined</MenuItem>
						<MenuItem value="filled">Filled</MenuItem>
					</PropertiesSelect>
				</section>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.fieldStyle.backgroundColor} 
						hideOpacity={true}
						label="Fill"
						onColorChange={(color: string) => setProp(id, (props: FormProps) => props.fieldStyle.backgroundColor = color)}/>
				</section>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.fieldStyle.borderColor} 
						hideOpacity={true}
						label="Border"
						onColorChange={(color: string) => setProp(id, (props: FormProps) => props.fieldStyle.borderColor = color)}/>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Type</PropertyLabel>
					<PropertiesSelect value={props.fieldStyle.size}
						size="small"
						onChange={(event) => setProp(id, props => props.fieldStyle.size = event.target.value)}>
						<MenuItem value="small">Small</MenuItem>
						<MenuItem value="medium">Medium</MenuItem>
					</PropertiesSelect>
					<PropertyLabel>Radius</PropertyLabel>
					<PropertiesField value={props.fieldStyle.borderRadius}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: FormProps) => props.fieldStyle.borderRadius = parseInt(event.target.value))}>
					</PropertiesField>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Margin X</PropertyLabel>
					<PropertiesField value={props.marginX}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: FormProps) => props.marginX = parseInt(event.target.value))}>
					</PropertiesField>
					<PropertyLabel>Margin Y</PropertyLabel>
					<PropertiesField value={props.marginY}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: FormProps) => props.marginY = parseInt(event.target.value))}>
					</PropertiesField>
				</section>
				<PropertiesFormLabel  sx={{marginLeft: '-4px'}}
					control={
						<Checkbox size="small"
							checked={props.fieldStyle.floatingLabel} 
							onChange={(event) => setProp(id, (props: FormProps) => props.fieldStyle.floatingLabel = event.target.checked)}
						/>} 
					label="Floating Label" />
			</PropertiesAccordionDetails>
		</PropertiesAccordion>

		{/* Button Properties */}
		<PropertiesAccordion expanded={openButton} onChange={() => setOpenButton(!openButton)}>
			<PropertiesAccordionSummary label="Button"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Align</PropertyLabel>
					<PropertiesButtonGroup>
						{
							['flex-start', 'center', 'flex-end'].map(item => <PropertiesButton key={item}
								onClick={() => setProp(id, props => props.button.style.alignSelf = item)}
								className={ props.button.style.alignSelf === item ? 'active' : '' }>
								<svg style={{width: 18, height: 18}}>
									<use href={`assets/icons/settings.svg#${item}`} />
								</svg>
							</PropertiesButton>)
						}
					</PropertiesButtonGroup>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Width</PropertyLabel>
					<PropertiesSlider value={parseInt(props.button.style.width)}
						step={1}
						min={0}
						max={100}
						onChange={(_, value) => setProp(id, (props: FormProps) => props.button.style.width = `${value}%`)} />
				</section>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.button.style.color} 
						hideOpacity={true}
						label="Label"
						onColorChange={(color: string) => setProp(id, (props: FormProps) => props.button.style.color = color)}/>
					<PropertiesIconButton className={props.button.style.fontWeight === 'bold' ? 'active':''}
						onClick={event => setProp(id, (props: FormProps) => props.button.style.fontWeight = props.button.style.fontWeight === 'bold' ? 'normal' : 'bold' )}>
						<svg>
							<use href={`assets/icons/typography.svg#bold`} />
						</svg>
					</PropertiesIconButton>
				</section>
				<section className="dm-edit-property-fields">
					<ColorPicker color={props.button.style.backgroundColor} 
						hideOpacity={true}
						label="Button"
						onColorChange={(color: string) => setProp(id, (props: FormProps) => props.button.style.backgroundColor = color)}/>
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Radius</PropertyLabel>
					<PropertiesField value={parseInt(props.button.style.borderRadius)}
						size="small"
						type="number"
						inputProps={{min: 0}}
						onChange={event => setProp(id, (props: FormProps) => props.button.style.borderRadius = `${event.target.value}px`)}>
					</PropertiesField>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>

		{/* Container Properties */}
		<PropertiesAccordion expanded={openContainer} onChange={() => setOpenContainer(!openContainer)}>
			<PropertiesAccordionSummary label="Container"/>
			<section className="dm-edit-property-fields">
				<ColorPicker color={props.style.backgroundColor} 
					hideOpacity={false}
					label="Color"
					onColorChange={(color: string) => setProp(id, (props: FormProps) => props.style.backgroundColor = color)}/>
			</section>
			<section className="dm-edit-property-fields">
				<PropertyLabel>Radius</PropertyLabel>
				<PropertiesField value={props.style.borderRadius}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => setProp(id, (props: FormProps) => props.style.borderRadius = parseInt(event.target.value))}>
				</PropertiesField>
				<PropertyLabel>Padding</PropertyLabel>
				<PropertiesField value={props.style.padding || 0}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => setProp(id, (props: FormProps) => props.style.padding = parseInt(event.target.value))}>
				</PropertiesField>
			</section>
		</PropertiesAccordion>
		{	(openField || openButtonPopper) &&
			<Popper id="form-menu"
				className="dm-edit-form-props__menu"
				open={menu !== null} 
				anchorEl={menu} 
				placement="left-start"
				onResize={() => {}}
				onResizeCapture={() => {}}
				nonce>
				{
					getFieldSettings(openFieldSection)
				}
			</Popper>
		}
	</div>
}
