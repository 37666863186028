import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react'

import { CharacterMetadata, CompositeDecorator, ContentBlock, ContentState, convertFromRaw, convertToRaw, DraftInlineStyle, Editor, EditorState, Modifier } from 'draft-js'
import { useEditor, useNode } from '@craftjs/core'
import { TextProps } from '../../../types/props'
import { TextProperties } from './TextProperties'
import { InlineControls } from '../../Rightbar/Properties/Typography'
import { Button, Popover } from '@mui/material'
import { PreviewVariableInterface, useVariables } from '../../../contexts/VariablesContext'
import draftToHtml from 'draftjs-to-html'
import { useDevice } from '../../../contexts/ViewProvider'
import { getStyle } from '../../../services/helperService'
import { PropertyLabel } from '../../Rightbar/Properties/PropertyLabel'
import { FullPropertiesField } from '../../Rightbar/Properties/PropertiesFields'
import 'draft-js/dist/Draft.css'
import './Text.scss'

export const Text = (textProps: TextProps) => {
  const {actions: {history}, query: {node}} = useEditor()
  const {actions: {setProp}, connectors: {connect}, id, selected, dom, parent} = useNode((state) => ({
    id: state.id,
    selected: state.events.selected,
    dom: state.dom,
    parent: state.data.parent
  }))
  const {preview, previewVariables} = useVariables()
  const [displayState, setDisplayState] = useState<EditorState>(null)
  const [linkData, setLinkData] = useState<{entity: string, anchor: HTMLButtonElement, url: string, label: string}>({entity: null, anchor: null, url: '', label: ''})
  const {device} = useDevice()
  
  const addFontTag = () => {
    let style = document.createElement('style')
    style.innerHTML = `@import url('https://fonts.googleapis.com/css2?family=${textProps.style.fontFamily.replace(/\s/g, '+')}:ital,wght@0,400;0,700;1,400;1,700&display=swap');`
    document.head.appendChild(style)
  }
  let parentNode = node(parent).get()
  let style = {
    maxHeight: '100%',
    ...textProps.style
  }

  if (textProps.type === 'landing_page') {
    style = {
      maxHeight: '100%',
      ...getStyle(textProps.style, textProps.mobileStyle, device, parentNode),
      position: 'relative'
    }
  } else {
    style = {
      ...style,
      width: textProps.width, 
      position: 'absolute',
      top: textProps.y, 
      left: textProps.x, 
    }
  }

  useEffect(() => {
    if (textProps.content) {
      const decorator = linkDecorator()
      let new_state = EditorState.createWithContent(convertFromRaw(textProps.content), decorator)
      history.ignore().setProp(id, (props: TextProps) => {
        props.state = new_state
      })
    }

    addFontTag()

    return () => {
      if (textProps.editing) {
        onSave()
      }
    }
  }, [])

  useEffect(() => {
    if (!selected && textProps.editing) {
      onSave()
    }
  }, [selected])


  useEffect(() => {
    if (linkData.anchor) resetLinkData()
  }, [textProps.editing])

  useEffect(() => {
    addFontTag()
  }, [textProps.style.fontFamily])

  useEffect(() => {
    if (preview) {
      let content = textProps.state.getCurrentContent()
      let new_state = textProps.state
      content.getBlocksAsArray().forEach((block: ContentBlock, count) => {
        previewVariables[preview].forEach((item: PreviewVariableInterface) => {
          let text = content.getBlocksAsArray()[count].getText()
          let index = text.indexOf(item.variable_name)
          if (index > 0) {
            let selection = new_state.getSelection().merge({
              anchorKey: block.getKey(),
              anchorOffset: index,  
        
              focusOffset: index + item.variable_name.length, 
              focusKey: block.getKey(),
            })
  
            new_state = EditorState.forceSelection(new_state, selection)
            content = Modifier.replaceText(content, selection, item.value, new_state.getCurrentInlineStyle())
            new_state = EditorState.createWithContent(content)
          }
        })
      })

      setDisplayState(new_state)
    } else {
      setDisplayState(null)
    }
  }, [preview])

  function findLinkEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    contentBlock.findEntityRanges((character: CharacterMetadata) => {
      const entityKey = character.getEntity()

      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
  }

  const Link: Function = (props: any) => {
    const { url, linkText } = props.contentState
      .getEntity(props.entityKey)
      .getData()

    return <a href={ url }>
      { linkText || props.children }
    </a>
  }

  const linkDecorator = () => new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ])

  const resetLinkData = () => {
    setLinkData({
      entity: null,
      anchor: null, 
      url: '',
      label: ''
    })
  }

  const onToggleEditing = (editing: boolean) => {
    history.ignore().setProp(id, (props: TextProps) => props.editing = editing)
  }

  const onTextChange = (state: EditorState) => {
    setProp((props: TextProps) => {
      props.state = state

      let draft_height = dom.getElementsByClassName('DraftEditor-root')[0].clientHeight
      if (dom.clientHeight < draft_height ) {
        let y_gap = `span ${Math.ceil(draft_height/15)}`

        if (props.style.gridRowEnd !== y_gap) {
          props.style.gridRowEnd = y_gap
        }
      }
    })
  }

  const onSave = () => {
    history.ignore().setProp(id, (props: TextProps) => {
      if (props.state?.getCurrentContent) {
        let contentState: ContentState = props.state.getCurrentContent()
        let raw = convertToRaw(contentState)
        let html = draftToHtml(raw)
        props.content = raw
        props.editing = false
        props.html = html
      }
    }) 

    history.ignore().setProp('ROOT', (props) => {
      if (textProps.state?.getCurrentContent) {
        let text = textProps.state.getCurrentContent().getPlainText()
        let variables = [...text.matchAll(/({{)(.*?)(}})/g)].map(item => item[0])
        let new_vars = props.variables ? {...props.variables} : {}
        new_vars[id] = variables
        props.variables = new_vars
      }
    })
  }

  const customStyleFn = (style: DraftInlineStyle, block: ContentBlock) => {
    let response: CSSProperties = {}

    style.toArray().forEach(item => {
      if (item.includes('fontsize'))
        response.fontSize = `${item.substring(9)}px`
    })

    return response
  }

  const openLinkPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    const state: EditorState = textProps.state
    const selection = state.getSelection()
    let url = ''
    let label = ''

    if (!selection.isCollapsed()) {
      const contentState = state.getCurrentContent()
      const startKey = state.getSelection().getStartKey()
      const startOffset = state.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
      
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
        label = linkInstance.getData().label
      } else {
        const endOffset = state.getSelection().getEndOffset()

        label = blockWithLinkAtBeginning.getText().slice(startOffset, endOffset)
        url = 'https://dmio.com'
        // addLink(url, label)
      }

      setLinkData({
        entity: linkKey, 
        anchor: event.currentTarget,
        url,
        label
      })
    }
  }

  const onSaveLink = () => {
    if (linkData.entity) {
      updateLink(linkData.entity)
    } else {
      addLink()
    }
  }

  const addLink = () => {
    const decorator = linkDecorator()
    const currentContent = textProps.state.getCurrentContent()

    currentContent.createEntity('LINK', 'MUTABLE', {
      url: linkData.url
    })

    const entityKey = currentContent.getLastCreatedEntityKey()
    const selection = textProps.state.getSelection()
    const textWithEntity = Modifier.replaceText(
      currentContent, 
      selection, 
      linkData.label, 
      textProps.state.getCurrentInlineStyle(),
      entityKey
    )

    const newState = EditorState.createWithContent(textWithEntity, decorator)

    setProp((props: TextProps) => props.state = newState)
    resetLinkData()
  }

  const updateLink = (linkKey: string) => {
    const state: EditorState = textProps.state
    const contentState = state.getCurrentContent()
    const decorator = linkDecorator()
    contentState.replaceEntityData(linkKey, {url: linkData.url})
    const newState = EditorState.set(state, {currentContent: contentState})

    setProp((props: TextProps) => props.state = newState)
    resetLinkData()
  }

  return <div className={`dm-edit-text ${textProps.editing ? 'dm-edit-text--editing' : ''}`}
    ref={ref => connect(ref)}
    onDoubleClick={() => onToggleEditing(true)}
    style={style}>
    {
      textProps.editing && <>
        <div className="dm-edit-text__cntrls">
          <InlineControls onChange={(state) => setProp((props: TextProps) => props.state = state)}
            state={textProps.state} />
          <Button onClick={() => onSave()}
            sx={{fontSize: 12, textTransform: 'none'}}>
            Done
          </Button>
          <Button onClick={openLinkPopover}
            sx={{fontSize: 12, textTransform: 'none'}}>
            Link
          </Button>
          <Popover id={id}
            open={linkData.anchor !== null}
            anchorEl={linkData.anchor}
            onClose={() => setLinkData({entity: null, anchor: null, url: '', label: ''})}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <section className="dm-edit-property-fields"
              style={{width: 200, padding: '10px'}}>
              <PropertyLabel>URL</PropertyLabel>
              <FullPropertiesField id="search_google"
                value={linkData.url}
                size="small"
                type="text"
                onChange={(event) => setLinkData({...linkData, url: event.target.value})}
              />	
            </section>
            <Button onClick={onSaveLink}>Save</Button>	
          </Popover>
        </div>
      </>
    }
    {
      !textProps.editing && textProps.html &&
      <div dangerouslySetInnerHTML={{__html: textProps.html}}>
      </div> 
    }
    { (textProps.editing || !textProps.html) && textProps.state.isInCompositionMode && 
      <Editor placeholder="Enter your text here..."
        readOnly={!textProps.editing}
        editorState={preview && displayState && displayState.isInCompositionMode ? displayState : textProps.state} 
        customStyleFn={customStyleFn}
        onChange={onTextChange} /> }

  </div>
}

Text.craft = {
  displayName: 'Text',
  props: {
    state: EditorState.createEmpty(),
    text: 'Paceholder',
    width: '50%', 
    height: 'auto',
    initial: true, 
    absolute: false,
    variables: [],
    x: 0,
    y: 0,
    style: {
      color: '#000000',
      fontFamily: 'Roboto',
      fontSize: '20px',
      backgroundColor: 'transparent',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      textAlign: 'left',
      marginLeft: '0px',
      marginRight: '0px',
      marginTop: '0px',
      marginBottom: '0px',
      lineHeight: 1.2,
      opacity: 1
    },
    mobileStyle: {
      
    }
  },
  custom: {
    mailpiece: {
      resizableProps: {
        enable: { top: false, right: true, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }
      }
    }
  },
  rules: {
  },
  related: {
    properties: TextProperties
  }  
}
