import React, { ChangeEvent, createRef, DragEvent, useState } from 'react'

import { useImages } from '../../../contexts/ImagesContext'
import { request } from '../../../services/helperService'
import { CompanyInterface, useUser } from '../../../contexts/UserContext'
import { Backdrop, Button, CircularProgress } from '@mui/material'
import './Dropzone.scss'

export const Dropzone = ({onImageAdded}: {onImageAdded: (url: string) => void}) => {
	const [ dropzoneActive, setDropzoneActive ] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const inputRef = createRef<HTMLInputElement>()
	const {uploadedImages, setUploadedFiles} = useImages()
	const {user}: {user: CompanyInterface} = useUser() 
	
	const onFileAdded = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files) {
			uploadFile(event.target.files[0])
		}
	}

	const onDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		setDropzoneActive(true) 
	}

	const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		setDropzoneActive(false) 
	}

	const onDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			uploadFile(event.dataTransfer.files[0])
		}
		setDropzoneActive(false) 
	}

	const uploadFile =(file: File): void => {
		setLoading(true)
    let fd = new FormData()
    fd.append('upload', file) 
		
    request.post(process.env.REACT_APP_API_URL + `editor/images`, fd)
      .then((response: any) => {
				setLoading(false)

        if (response.data.status === 'success') {
          setUploadedFiles([...uploadedImages, response.data.asset])
					onImageAdded(response.data.asset.asset_url)
        }
      })
  }

	return <>
    { loading && <Backdrop sx={{ position: 'absolute', backgroundColor: 'rgba(256,256,256, 0.5)', color: '#1868FD', zIndex: 1300, top: '56px' }}
      open={true}>
      <CircularProgress color="inherit" />
    </Backdrop> }
		<div className={`dm-edit-dropzone ${dropzoneActive ? 'dm-edit-dropzone--active' : ''}`}
			onDragLeaveCapture={ (event) => onDragLeave(event) }
			onDragOver={ (event) => onDragOver(event) }
			onDragLeave={ () => setDropzoneActive(false) }
			onDrop={ (event) => onDrop(event) }>
			<div>
				<svg>
	        <use href="assets/icons/toolbar.svg#images"/>
	      </svg>
				<span>Drag one or more images here to upload</span>
				<Button sx={{textTransform: 'none', padding: 0, color: '#1364FD'}}
					onClick={ () => inputRef?.current?.click() }>
					or browse
				</Button>
				<input className="dm-edit-dropzone__input"
					onChange={ (event) => onFileAdded(event) }
					ref={ inputRef }
					type="file"/>
				<div className="dm-edit-dropzone__note">
					File formats jpg, jpeg, png, and svg.
				</div>
			</div>
		</div>
	</>
}
