import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { Backdrop, CircularProgress, Drawer } from '@mui/material'
import { UploadedInterface, ImagesProvider, PixabayInterface } from '../../contexts/ImagesContext'
import { Header } from '../Header/Header'
import { Elements } from '../Elements/Elements'
import { request } from '../../services/helperService'
import { Toolbar } from '../Toolbar/Toolbar'
import { CompanyInterface, useUser } from '../../contexts/UserContext'
import { Start } from '../../dialogs/Start/Start'
import { NodeTree, useEditor } from '@craftjs/core'
import { Workspace } from '../Workspace/Workspace'
import { DesignInterface, DesignProvider } from '../../contexts/DesignContext'
import { Uploaded } from '../Images/Uploaded/Uploaded'
import { Pixabay } from '../Images/Pixabay/Pixabay'
import { Instagram } from '../Images/Instagram/Instagram'
import { getDesign, getVariablesPreview, saveDesign } from '../../services/designService'
import { Rightbar } from '../Rightbar/Rightbar'
import { ViewProvider } from '../../contexts/ViewProvider'
import { PreviewVariablesInterface, VariableInterface, VariablesInterface, VariablesProvider } from '../../contexts/VariablesContext'
import './Renderer.scss'
import _ from 'lodash'

export const Renderer = ({onToggleEditor}: {onToggleEditor: (state: boolean) =>  void}) => {
	const {actions: {selectNode, history, clearEvents, setOptions}, query} = useEditor()
	const [zoom, setZoom] = useState<number>(100)
	const [device, setDevice] = useState<string>('desktop')
	const [design, setDesign] = useState<DesignInterface>(null)
	const [variables, setVariables] = useState<VariablesInterface>(null)
	const [preview, setPreview] = useState<'random'|'longest'|'shortest'|''>('')
	const [previewVariables, setPreviewVariables] = useState<PreviewVariablesInterface>({})
	const [sidebar, setSidebar] = useState<string>('')
	const [open, setOpen] = useState<boolean>(false)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedInterface[]>([])
	const [pixabay, setPixabay] = useState<PixabayInterface>({filter: '', images: []})
	const [mode, setMode] = useState<string>('edit')
	const {user, setSave}: {user: CompanyInterface, setSave: Dispatch<SetStateAction<boolean>>} = useUser() 
	const queryParams = new URLSearchParams(window.location.search)
	const drawerRef = useRef(null)
	const toolbarRef = useRef(null)

	const searchPixabay = (filter: string): void => {
		let params = {}
		if (filter) {
			params = {query: filter}
		}

		request.get(process.env.REACT_APP_API_URL + `editor/images/pixabay/search`, {params})
      .then(response => {
        if (response.data.status == 'success') {
					setPixabay({filter: filter, images: response.data.images})
				}
			}) 
	}

	useEffect(() => {
		if (queryParams.get('design')) {
			getDesign(queryParams.get('design'), queryParams.get('campaign_id'))
				.then((response) => {
					if (response.data.status == 'success') {
						let design: DesignInterface = response.data.data   
						history.ignore().deserialize(JSON.parse(design.state))	
						if (design.proofed_at) {
							onToggleEditor(false)
						}		
						setDesign(design)   			
					}
				})
		}

		request.get(process.env.REACT_APP_API_URL + `editor/images`)
			.then((response: any) => {
				if (response.data.status == 'success') {
					setUploadedFiles(response.data.assets)
				}
			})

		request.get(process.env.REACT_APP_API_URL + `editor/design/campaign/${queryParams.get('campaign_id')}/variables`)
			.then((response: any) => {
				if (response.data.status == 'success') {
					setVariables(response.data.variables)
				}
			})

		searchPixabay('')
  }, [])



	const onDrawerClosed = () => {
		document.removeEventListener("mousedown", handleClickOutside)
		setSidebar('')
		setOpen(false)
	}

	const handleClickOutside = (event: MouseEvent) => {
		let target = event.target as Element
		let classes = target.classList.toString()

		if (drawerRef.current && !drawerRef.current.contains(event.target) 
			&& toolbarRef.current && !toolbarRef.current.contains(event.target)
			&& !classes.includes('MuiMenuItem-root') 
			&& !classes.includes('MuiBackdrop-invisible') 
			&& !classes.includes('dm-edit-pixabay__popper')
			&& !classes.includes('dm-edit-pixabay__search-btn')) {
			onDrawerClosed()
		}
	}

	const onSitebarChange = (section: string) => {
		if (!open) {
			selectNode(null)
			setOpen(true)
			document.addEventListener("mousedown", handleClickOutside)
		}

		if (section !== sidebar) {
			setSidebar(section)
		} else if (open) {
			onDrawerClosed()
		}
	}

	const onNodeAdded = (id: string) => {
		onDrawerClosed()
		// selectNode(id)
	}

	const onSave = (type: 'save'|'save_and_render_pdf'): void => {
		// setOptions((options) => options.enabled = false)
		selectNode(null)
		setMode('preview')
		onToggleEditor(false)

		let new_design = {...design}
		new_design.state = query.serialize() 

		saveDesign(new_design, type).then((response) => {
			if (response.data.status === 'success') {
				setSave(false)
				clearEvents()
				if (response.data.design_id) {
					if (!new_design.id) {
						new_design.id = response.data.design_id

						//Update URL for reload
						if ('URLSearchParams' in window) {
							var searchParams = new URLSearchParams(window.location.search)
							searchParams.set('design', new_design.id)
							var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
							window.history.pushState(null, '', newRelativePathQuery)
						}
					}

					setDesign(new_design)
				}

				if (type === 'save_and_render_pdf' && response.data.state && response.data.state.pdf_url) {
					window.open(response.data.state.pdf_url, '_blank')
				}
			}
		})
		.finally(() => {
			setMode('edit')
			onToggleEditor(true)
		})
	}

	const onPreview = (type: 'random'|'longest'|'shortest') => {
		let  variables_obj: {[key: string]: any[]} = query.node('ROOT').get().data.props.variables
		let varibale_ids: number[] = []

		if (variables_obj) {
			let list = _.union(...Object.values(variables_obj))
			let all_variables: VariableInterface[] = [...variables.builtInVariables, ...variables.customVariables]

			all_variables.forEach(item => {
				let name = item.variable_name
				name = name.slice(2, name.length - 2)

				if (list.includes(name)) varibale_ids.push(item.id)
			})

			getVariablesPreview(design, varibale_ids, type)
				.then((response) => {
					if (response.data && response.data.data) {
						let new_variables = {...previewVariables}
						new_variables[type] = response.data.data

						setPreviewVariables(new_variables)
						setPreview(type)
					}
				})
		}
	}

	const onModeChange = (mode: 'proof'|'edit'|'approved') => {
		
		if (mode === 'proof') {
			selectNode(null)
			onToggleEditor(false)
			setMode(mode)
		} else if (mode === 'edit') {
			onToggleEditor(true)
			setPreview('')
			setMode(mode)
		} else if (mode === 'approved') {
			onToggleEditor(false)
			setMode('edit')
		}
	}

	const renderSwitch = () => {
		switch(sidebar) {
			case 'images': 
				return <Uploaded type="addNode"
					onNodeAdded={(tree: NodeTree) => onNodeAdded(tree.rootNodeId)}/>
			case 'elements':
				return <Elements onNodeAdded={(id: string) => onNodeAdded(id)}/>
			case 'pixabay': 
				return <Pixabay type="addNode"
					onNodeAdded={(tree: NodeTree) => onNodeAdded(tree.rootNodeId)}/>
			case 'instagram': 
				return <Instagram />
		}
	}

	return <DesignProvider value={[design, setDesign]}> 
    <Backdrop sx={{ color: '#fff', zIndex: 1300 }}
      open={mode === 'preview'}>
      <CircularProgress color="inherit" />
    </Backdrop>
		<div className={`dm-edit-renderer ${ mode === 'proof' ? 'dm-edit-renderer--proof' : ''}`}>
			<ViewProvider value={{zoom: [zoom, setZoom], device: [device, setDevice], mode}}>
				<Header mode={design && (design.type.toLowerCase() == 'landing_page' || design.dimensions) ? mode : ''}
					design={design}
					onSave={onSave} 
					onModeChange={onModeChange}
					onPreview={onPreview}
					device={device}
					setDevice={setDevice}/>
				<ImagesProvider value={[uploadedFiles, setUploadedFiles, pixabay, searchPixabay]}>
					<Drawer ref={drawerRef}
						anchor="left" 
						variant="persistent"
						open={open}
						onClose={onDrawerClosed}
						transitionDuration={300}
						SlideProps={{easing: { enter: 'ease-out', exit: 'ease-in' }}}
						hideBackdrop={true}
						ModalProps={{sx: {pointerEvents: 'none', zIndex: 1190}}}
						PaperProps={{sx: {pointerEvents: 'initial', width: '368px', border: 'none', boxShadow: '10px 2px 24px 0 rgba(0, 0, 0, 0.07)'}}}>
						{
							renderSwitch()
						}
					</Drawer>
					{ design ? 
						<>
							{	mode !== 'proof' && !design.proofed_at &&
									<Toolbar ref={toolbarRef}
										sidebar={open ? sidebar : ''}
										onSidebarChange={(section: string) => onSitebarChange(section)}/>
							}							
							<VariablesProvider value={[variables, preview, previewVariables]}>
								<div style={{marginTop: '56px', flex: 1, position: 'relative', overflow: 'scroll'}}>
									<Workspace />
								</div>
								{	mode !== 'proof' && !design.proofed_at &&
									<Rightbar />
								}
							</VariablesProvider>
						</> : queryParams.get('design') ?
				    <Backdrop sx={{ color: '#fff', zIndex: 1300 }}
							open={true}>
							<CircularProgress color="inherit" />
						</Backdrop> : 
						<Start />
					}
				</ImagesProvider>
			</ViewProvider>
		</div>
	</DesignProvider>
}
