import { Button, ButtonProps } from '@mui/material'

export const DarkButton = (props: ButtonProps) => 
	<Button variant='contained'
		onClick={props.onClick}
		aria-label={props['aria-label']}
		sx={{
			width: '80px',
			backgroundColor: '#1868FD',
			fontSize: '12px',
			lineHeight: '20px',
			fontWeight: 'bold',
			textTransform: 'none',
			boxShadow: 'none',
			padding: '6px',
			marginLeft: '7px',
			":hover": {
				backgroundColor: "#0d3c94",
				boxShadow: 'none'
			},
			...props.sx
		}}>
		{ props.children }
	</Button>
