import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react'
interface ViewProviderInterface {
	value: {
		zoom: [ 
			zoom: number, 
			setZoom: Dispatch<SetStateAction<number>>
		]
		device: [ 
			device: string, 
			setDevice: Dispatch<SetStateAction<string>>
		] 
		mode: string
	}
}

const ViewContext = createContext(null)

const useZoom = () => {
	const {zoom} = useContext(ViewContext)

	return {zoom: zoom[0], setZoom: zoom[1]}
}

const useDevice = () => {
	const {device} = useContext(ViewContext)

	return {device: device[0], setDevice: device[1]}
}

const useMode = () => {
	const {mode} = useContext(ViewContext)

	return mode
}

const ViewProvider = ({value, children}: PropsWithChildren<ViewProviderInterface>) => (
	<ViewContext.Provider value={value}>{children}</ViewContext.Provider>
)

export { useZoom, useDevice, ViewProvider, useMode }