import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { MenuItem } from '@mui/material'
import { QRProps } from '../../../../types/props'
import { Background } from '../../../Rightbar/Properties/Background'
import { Corners } from '../../../Rightbar/Properties/Corners'
import { Layout } from '../../../Rightbar/Properties/Layout'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../../Rightbar/Properties/PropertiesAccordion'
import { PropertyLabel } from '../../../Rightbar/Properties/PropertyLabel'
import { FullPropertiesField, PropertiesSelect } from '../../../Rightbar/Properties/PropertiesFields'
import { Border } from '../../../Rightbar/Properties/Border'

export const QRProperties = () => {
	const [open, setOpen] = useState<boolean>(true)
	const { actions: {setProp}, QRProps } = useNode<{QRProps: QRProps}>((node) => ({
		QRProps: node.data.props as QRProps
	}))

	return <>
		<PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Link"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Link</PropertyLabel>
					<FullPropertiesField sx={{width: '100%', "MuiInputBase-root": {width: `100%`}}}
		        id="search_google"
		        value={QRProps.value}
		        size="small"
		        type="text"
		        onChange={(event) => {
							setProp((props: any) => props.value = event.target.value)
						}}
		      />
				</section>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Quality</PropertyLabel>
					<PropertiesSelect size="small"
		        value={ QRProps.level }
		        onChange={(event) => setProp((props: QRProps) => props.level = event.target.value as 'L' | 'M' | 'Q' | 'H')}>
		        <MenuItem value="L">Low</MenuItem>
		        <MenuItem value="M">Medium</MenuItem>
		        <MenuItem value="Q">Medium-High</MenuItem>
		        <MenuItem value="H">High</MenuItem>
		      </PropertiesSelect>
				</section>
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
		<Layout square={true} hide={['height']}/>
		<Background />
		<Corners />
		<Border equal={true}/>
	</>
}