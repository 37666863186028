import React, { CSSProperties, useEffect } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { FormProps } from '../../../../types/props'
import { FormProperties } from './FormProperties'
import { useDevice } from '../../../../contexts/ViewProvider'
import { getStyle } from '../../../../services/helperService'
import { FormRenderer } from '../FormRenderer/FormRenderer'
import './Form.scss'

export const Form = (formProps: FormProps) => {
	const {connectors: {connect}, actions: {setProp}, id, dom, parent} = useNode(node => ({
		id: node.id, 
		dom: node.dom,
		parent: node.data.parent
	}))
	const { actions: {history: {ignore}}, query: {node} } = useEditor()
  const {device} = useDevice()
	let parentNode = node(parent).get()
	let style: CSSProperties = {
		...getStyle(formProps.style, formProps.mobileStyle, device, parentNode)
	}

	useEffect(() => {
		if (dom) {
			setProp((props: FormProps) => {
				let draft_height = dom.getElementsByTagName('form')[0].clientHeight
	
				if (dom.clientHeight < draft_height ) {
					let y_gap = `span ${Math.ceil(draft_height/15) + 1}`
		
					if (props.style.gridRowEnd !== y_gap) {
						props.style.gridRowEnd = y_gap
					}
				}
			})
		}
	}, [formProps.fields])

	return <div ref={ref => connect(ref)}
		id={id}
		onClick={(event) => event.stopPropagation()}
		style={{boxSizing: 'border-box', ...style}}>
		<FormRenderer form={formProps}/>
	</div>
}

Form.craft = {
	props: {
		marginX: 16,
		marginY: 16,
		style: {
			padding: 10,
			gridColumnStart: 1,
			gridColumnEnd: 'span 14',
			gridRowStart: 1,
			gridRowEnd: 'span 15',
			backgroundColor: 'rgba(255, 255, 255, 0.1)'
		},
		button: {
			style: {
				borderRadius: 0,
				alignSelf: 'center',
				backgroundColor: 'rgba(25, 118, 210, 1)',
				color: 'rgba(255, 255, 255, 1)',
				width: '50%'
			},
			label: 'Submit'
		},
		fieldStyle: {
			size: 'small',
			margin: 0,
			borderRadius: 0,
			fieldType: 'outlined',
			backgroundColor: 'rgba(255, 255, 255, 1)',
			borderColor: 'rgba(25, 118, 210, 1)',
			floatingLabel: true,
		},
		activeStep: 0,
		type: 'single',
		fields: [[
			[
				{
					key: 'first_name',
					type: 'text',
					label: 'First Name',
					required: true
				},
				{
					key: 'last_name',
					type: 'text',
					label: 'Last Name',
					required: true
				},
			],
			[
				{
					key: 'reason',
					type: 'select',
					label: 'Reason',
					required: true,
					options: [
						{
							label: 'First',
							value: 'first'
						},
						{
							label: 'Second',
							value: 'second'
						}
					]
				}
			]
		]]
	},
	related: {
    properties: FormProperties
  }
}
