import { CancelToken } from "axios"
import { DesignInterface } from "../contexts/DesignContext"
import { request } from "./helperService"

const dimensions: any = {
	'6x11': {
		height_px: 640,
		width_px: 1120
	},
	'6x9': {
		height_px: 640,
		width_px: 928
	},
	'4x6':{
		height_px: 448,
		width_px: 640
	},
	'11x8':{
		height_px: 1120,
		width_px: 880
	}
}

export const getDesign = (design_id: string, campaign_id: string) => {
	return request.get(process.env.REACT_APP_API_URL + `editor/design/${design_id}/campaign/${campaign_id}/get`)
}

export const getTemplates = (token: CancelToken) => {
	return request.get(process.env.REACT_APP_API_URL + `editor/templates`, {cancelToken: token})
}

const generateTree = (type: string) => {
	const head_node = document.head.outerHTML

	let tree: any = {
		head_node
	}

	switch (type) {
		case 'mailpiece':
			const cards = document.getElementsByClassName('dm-edit-card')
			cards[0].classList.add('dm-edit-card--front')
			tree['card_0'] = cards[0].outerHTML

			if (cards[1]) {
				cards[1].classList.add('dm-edit-card--back')
				tree['card_1'] = cards[1].outerHTML
			}
			break
		case 'landing_page': 
			tree['html'] = document.getElementsByClassName('dm-edit-landing')[0].outerHTML
	}

	return tree
}

export const saveDesign = (design: DesignInterface, action: 'save'|'save_and_render_pdf') => {
	let params: any = {
		...design, 
		type: design.type.toUpperCase(),
		tree_encoded: generateTree(design.type.toLowerCase())
	}

	if (design.type.toLowerCase() === 'mailpiece') {
		params = {...params, ...dimensions[design.dimensions]}
		if (action === 'save') {
			params.async = true
		}
	}

	return request.post(process.env.REACT_APP_API_URL + `editor/design/campaign/${design.campaign_id}/save_and_process`, params)
}

export const approveDesign = (campaign_id: string, design: string) => {
	return request.post(process.env.REACT_APP_API_URL + `editor/design/campaign/${campaign_id}/proof`, {id: design})
}

export const getVariablesPreview = (design: DesignInterface, variables: number[], type: string) => {
	return request.post(process.env.REACT_APP_API_URL + `editor/design/campaign/${design.campaign_id}/variable_preview/${type}`, { variable_ids: variables })
}
