import React, { useEffect, useRef, useState } from 'react'

import { Button } from '@mui/material'
import { ColorResult, SketchPicker } from 'react-color'
import { rgba2hex } from '../../../services/helperService'
import { PropertyLabel } from './PropertyLabel'
import { PropertiesSlider, PropertiesField } from './PropertiesFields'

interface ColorPickerProps {
	color: string, 
	onColorChange: (color: string) => void
}

export const GradientPicker = ({color, onColorChange}: ColorPickerProps) => {
	const [picker, setPicker] = useState<string>(null)
	const [leftColor, setLeftColor] = useState(null)
	const [rightColor, setRightColor] = useState(null)
	const [angle, setAngle] = useState<string>(null)
	const [percent, setPercent] = useState<string>(null)
  const colorPickerRef = useRef(null)

	const onAngleUpdate = (value: string) => {
		onColorChange(`linear-gradient(${value}deg,${leftColor.rgba} ${percent}%,${rightColor.rgba})`)
	}

	const onPercentUpdate = (value: number) => {
		onColorChange(`linear-gradient(${angle}deg,${leftColor.rgba} ${value}%,${rightColor.rgba})`)
	}

	const onColorPicked = (color: ColorResult, side: string) => {
		let new_color = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
		if (side === 'left') {
			onColorChange(`linear-gradient(${angle}deg,${new_color} ${percent}%,${rightColor.rgba})`)
		} else {
			onColorChange(`linear-gradient(${angle}deg,${leftColor.rgba} ${percent}%,${new_color})`)
		}
	}

	useEffect(() => {
		let start = color.indexOf('(')
		let finish = color.indexOf('deg')
		let subString = color.substring(start+1, finish)
		let new_string = color.slice(finish + 3)

		setAngle(subString)
		
		start = new_string.indexOf('rgba')
		finish = new_string.indexOf(')')
		subString = new_string.substring(start, finish + 1)

		setLeftColor({
			...rgba2hex(subString),
			rgba: subString
		})

		new_string = new_string.slice(finish + 1)
		start = 0
		finish = new_string.indexOf('%')

		setPercent(new_string.substring(start, finish).trim())

		new_string = new_string.slice(finish + 1)
		start = new_string.indexOf('rgba')
		finish = new_string.indexOf(')')
		subString = new_string.substring(start, finish + 1)

		setRightColor({
			...rgba2hex(subString),
			rgba: subString
		})
	}, [color])

	const handleClickOutside = (event: MouseEvent) => {
		if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
			onPickerClosed()
		}
	}

	const onPickerOpen = (side: string) => {
		if (!picker) {
			setPicker(side)
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			onPickerClosed()
		}
	}

	const onPickerClosed = () => {
		setPicker(null)
		document.removeEventListener("mousedown", handleClickOutside)
	}

	return<>
	 <section className="dm-edit-property-fields">
		<PropertyLabel>Color</PropertyLabel>
			{ leftColor && rightColor &&
				<div ref={colorPickerRef}
					style={{position: 'relative', flex: 1, display: 'flex'}}>
					<Button className="dm-edit-btn"
						onClick={() => onPickerOpen('left')}
						sx={{flex: 1, fontWeight: '400 !important'}}>
						<div style={{ marginRight: '4px', width: 20, height: 20, border: '1px solid #B7BFC9', borderRadius: '1px', padding: '1px', boxSizing: 'border-box'}}>
							<div style={{backgroundColor: leftColor.rgba, width: 16, height: 16}}></div>
						</div>
						{leftColor.hex}
					</Button>	
					<Button className="dm-edit-btn"
						onClick={() => onPickerOpen('right')}
						sx={{flex: 1, fontWeight: '400 !important'}}>
						<div style={{ marginRight: '4px', width: 20, height: 20, border: '1px solid #B7BFC9', borderRadius: '1px', padding: '1px', boxSizing: 'border-box'}}>
							<div style={{backgroundColor: rightColor.rgba, width: 16, height: 16}}></div>
						</div>
						{rightColor.hex}
					</Button>	
					{	picker ? 
						<div style={{position: 'absolute', zIndex: 100, left: '8px'}}>
							<SketchPicker
								color={picker === 'left' ? leftColor.rgba : rightColor.rgba}
								width="160"
								onChangeComplete={ (color: ColorResult) => onColorPicked(color, picker)}
							/>
						</div> : null
					}
				</div>
			}
		</section>
		{ angle &&
			<section className="dm-edit-property-fields">
				<PropertyLabel>Angle</PropertyLabel>
				<PropertiesField value={parseInt(angle)}
					size="small"
					type="number"
					inputProps={{minx: 0, max: 360}}
					onChange={event => onAngleUpdate(event.target.value)}>
				</PropertiesField>
				<PropertiesSlider value={parseInt(angle)}
					step={1}
					min={0}
					max={360}
					onChange={(_, value) => onAngleUpdate(value.toString())} />
			</section>
		}
		{
			percent &&
			<section className="dm-edit-property-fields">
				<PropertyLabel>Start</PropertyLabel>
				<PropertiesField value={parseInt(percent)}
					size="small"
					type="number"
					inputProps={{minx: 0, max: 100}}
					onChange={event => onPercentUpdate(parseInt(event.target.value))}>
				</PropertiesField>
				<PropertiesSlider value={parseInt(percent)}
					step={1}
					min={0}
					max={100}
					onChange={(_, value) => onPercentUpdate(value as number)} />
			</section>
		}
	</>
}
