import React, { useEffect, useState } from 'react'

import { UploadedInterface, useImages } from '../../../contexts/ImagesContext'
import { Dropzone } from '../Dropzone/Dropzone'
import { ImageButton } from '../ImageButton/ImageButton'
import { FilledInput, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { UploadedProps } from '../../../types/props'

import '../Images.scss'

export const Uploaded = ({type, onImageSelected, onNodeAdded}: UploadedProps) => {
  const [layout, setLayout] = useState<string>('tiles')
  const [sort, setSort] = useState<string>('name')
  const [mode, setMode] = useState<string>('images')
  const {uploadedImages}: {uploadedImages: UploadedInterface[]} = useImages()
  const [images, setImages] = useState<UploadedInterface[]>(uploadedImages)

  uploadedImages.sort((a:UploadedInterface, b:UploadedInterface) => {
    let compare = sort === 'name' ? a.file_name.toLowerCase() > b.file_name.toLowerCase() : a.created_at < b.created_at

    return compare ? 1 : -1
  })

  useEffect(() => {
    setImages(uploadedImages)
  }, [uploadedImages])

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value) {
      setImages(uploadedImages.filter(item => item.file_name.toLowerCase().includes(event.target.value)))
    } else {
      setImages(uploadedImages)
    }
  }

  const onImageAdded = (url: string) => {
    setMode('images')

    if (type === 'replace') {
      onImageSelected({url})
    }
  }
  
  return <section className={`dm-edit-sidebar dm-edit-sidebar--${type}`}>
    <div className="dm-edit-sidebar__header">
      <h1 className="dm-edit-sidebar__heading">{mode==='add' ? 'Add ' : ''}Assets</h1>
      <IconButton sx={{width: '32px', height: '32px', color: '#2A394A', '&:hover': { color: '#1868FD', backgroundColor: '#D6E9FF'}}}
        onClick={() => setMode(mode === 'add' ? 'images' : 'add')}>
        <svg style={{width: '12px', height: '12px'}}>
          <use href={`assets/icons/toolbar.svg#${mode === 'images' ? 'elements' : 'close'}`}/>
        </svg>   
      </IconButton>
    </div>
    { uploadedImages.length > 0 && mode === 'images' ? 
      <>
        <section className="dm-edit-sidebar__top">
          <FormControl variant="filled">
            <FilledInput disableUnderline={true}
              id="search"
              sx={{marginTop: '15px'}}
              size="small"
              onChange={onFilterChange}
              startAdornment={
                <svg style={{width: '24px', height: '24px', marginRight: '10px', color: '#7E8996'}}>
                  <use href={`assets/icons/layout.svg#search`}/>
                </svg>  
              }
            />
          </FormControl>
          <IconButton aria-label="View" 
            className={`dm-edit-imgs-layout dm-edit-imgs-layout--${layout}`}
            onClick={() => {
              let new_layout = layout === 'list' ? 'tiles' : 'list'
              setLayout(new_layout)
            }}>
            <svg style={{color: '#2A394A'}}>
              <use href={`assets/icons/layout.svg#${layout === 'list' ? 'tiles' : 'list'}`}/>
            </svg>     
          </IconButton>
        </section>
        <section className="dm-edit-sidebar__middle">
          <div>
            { images.length } {`item${images.length != 1 ? 's':''}`}
          </div>
          <Select	classes={{select: 'dm-edit-toptext__select'}}
            value={sort}
            margin="dense"
            size="small"
            onChange={(event) => setSort(event.target.value)}>
            <MenuItem value="last_added">Last Added</MenuItem>
            <MenuItem value="name">Name</MenuItem>
          </Select>
        </section>
        <div className={`dm-edit-images dm-edit-images--${layout}`}>
          { images.map((file: UploadedInterface, index: number) => {
              return  <ImageButton 
                mode={type}
                onCreate={type === 'replace' ? onImageSelected : onNodeAdded}
                key={index} 
                url={file.asset_url} 
                name={file.file_name} />
            }) }
        </div>
      </> : <Dropzone onImageAdded={onImageAdded}/>
    }
  </section>
}
