import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { PropertiesAccordionSummary, PropertiesAccordion, PropertiesAccordionDetails } from './PropertiesAccordion'
import { ColorPicker } from './ColorPicker'
import { FullPropertiesField, PropertiesButton, PropertiesButtonGroup, PropertiesField, PropertiesSelect } from './PropertiesFields'
import { GradientPicker } from './GradientPicker'
import { Uploaded } from '../../Images/Uploaded/Uploaded'
import { Pixabay } from '../../Images/Pixabay/Pixabay'
import { ImageReplaceResponse } from '../../../types/props'
import { request } from '../../../services/helperService'
import { UploadedInterface, useImages } from '../../../contexts/ImagesContext'
import { PropertyLabel } from './PropertyLabel'
import { MenuItem } from '@mui/material'

export const Background = () => {
	const { actions: {setProp}, props } = useNode((node) => ({
		props: node.data.props
	}))

	const [open, setOpen] = useState<boolean>(true)
	const [library, setLibrary] = useState<string|null>(null)
	const {uploadedImages, setUploadedFiles} = useImages()

	const isActive = (item: string) => {
		switch (item) {
			case 'image':
				return props.style.backgroundRepeat ? true : false
			case 'color':
				return !props.style.backgroundRepeat && props.style.backgroundColor ? true : false
			case 'gradient': 
				return props.style.background && props.style.background.includes('linear-gradient')
		}
	}

	const onChangeImage = () => {
		setLibrary('Uploaded')
	}

	const onImageSelected = (image: ImageReplaceResponse) => {
		let style = {...props.style}

    if (image.url.includes('pixabay')) {
      request.post(process.env.REACT_APP_API_URL + `editor/images/pixabay/save`, {id: image.id})
        .then((response) => {
          let asset: UploadedInterface = response.data.asset
          setUploadedFiles([...uploadedImages, asset])

					style.backgroundImage = `url(${asset.asset_url})`
					setProp((props: any) => props.style = style)
          // setProp(id, (props: ImageProps) => {props.url = asset.asset_url})
        })
    } else {
			style.backgroundImage = `url(${image.url})`
			setProp((props: any) => props.style = style)
		}

		setLibrary(null)
	}

	const getImageName = (url: string) => {
		if (!url) return null

		let index = url.lastIndexOf('/')
		return url.substring(index + 1, url.length - 1)
	}

	const onBackgroundModeChange = (mode: string) => {
		let style = {...props.style}

		switch (mode) {
			case 'color':
				if (style.background) {
					let start = style.background.indexOf('rgba')
					let finish = style.background.indexOf(')')
					style.backgroundColor = style.background.substring(start, finish + 1)
					delete style.background
				} else {
					if (style.backgroundSize) {
						delete style.backgroundRepeat
						delete style.backgroundSize
						delete style.backgroundPositionX
						delete style.backgroundPositionY
						delete style.backgroundImage
					}

					if (!style.backgroundColor) {
						style.backgroundColor = 'rgba(255, 255, 255, 1)'
					}
				}

				setProp((props: any) => props.style = style)
				break
			case 'gradient': 
				let bgColor = style.backgroundColor || 'rgba(229, 229, 229, 1)'
				style.background = `linear-gradient(180deg,${bgColor} 0%,rgba(255, 255, 255, 1))`
				if (style.backgroundSize) {
					delete style.backgroundRepeat
					delete style.backgroundSize
					delete style.backgroundPositionX
					delete style.backgroundPositionY
					delete style.backgroundImage
				}

				delete style.backgroundColor

				setProp((props: any) => props.style = style)
				break
			case 'image':
				delete style.background
				style.backgroundRepeat = 'no-repeat'
				style.backgroundSize = 'cover'
				style.backgroundPositionX = 'center'
				style.backgroundPositionY = 'center'

				setProp((props: any) => props.style = style)
				break
		}
	}

	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Background"/>
		<PropertiesAccordionDetails>
			<PropertiesButtonGroup sx={{margin: '4px 0'}}>
				{
					['color', 'gradient', 'image'].map(item => <PropertiesButton key={item}
						onClick={() => onBackgroundModeChange(item)}
						className={ isActive(item) ? 'active' : '' }>
						<svg style={{width: 18, height: 18}}>
							<use href={`assets/icons/settings.svg#${item}`} />
						</svg>
					</PropertiesButton>)
				}
			</PropertiesButtonGroup>
			{
				isActive('color') ?
					<section className="dm-edit-property-fields">
						<ColorPicker color={props.style.backgroundColor} 
							onColorChange={(color: string) => setProp((props: any) => props.style.backgroundColor = color)}/>
					</section> :
					isActive('gradient') ?
					<GradientPicker color={props.style.background} 
						onColorChange={(color: string) => setProp((props: any) => {
							props.style.background = color
						})}/>	:
					<>
						<section className="dm-edit-property-fields">
							<PropertyLabel>Image</PropertyLabel>
							<FullPropertiesField value={getImageName(props.style.backgroundImage) || ''}
								onClick={onChangeImage}></FullPropertiesField>
						</section>
						{
							library ?
							<>
								<section className="dm-edit-property-fields">
									<PropertiesButtonGroup>
										{ 
											['Uploaded', 'Pixabay'].map(item => <PropertiesButton key={item}
												onClick={() => setLibrary(item)}
												className={item === library ? 'active' : ''}>
												{ item } 
											</PropertiesButton>)
										}
									</PropertiesButtonGroup>
								</section>
								{
									library === 'Uploaded' ?
									<Uploaded type='replace' 
										onImageSelected={onImageSelected} /> :
									<Pixabay type='replace' 
										onImageSelected={onImageSelected} />
								}
							</> : 
							<>
								<section className="dm-edit-property-fields">
									<PropertyLabel>Size</PropertyLabel>
									<PropertiesSelect size="small"
						        value={ props.style.backgroundSize}
						        onChange={(event) => setProp((props: any) => props.style.backgroundSize = event.target.value)}>
						        <MenuItem value="contain">Contain</MenuItem>
						        <MenuItem value="cover">Cover</MenuItem>
						      </PropertiesSelect>
								</section>
								<section className="dm-edit-property-fields">
									<PropertyLabel>Pos X</PropertyLabel>
									<PropertiesSelect size="small"
						        value={ props.style.backgroundPositionX}
						        onChange={(event) => setProp((props: any) => props.style.backgroundPositionX = event.target.value)}>
										<MenuItem value="center">Center</MenuItem>
						        <MenuItem value="left">Left</MenuItem>
						        <MenuItem value="right">Right</MenuItem>
						      </PropertiesSelect>
								</section>
								<section className="dm-edit-property-fields">
									<PropertyLabel>Pos Y</PropertyLabel>
									<PropertiesSelect size="small"
						        value={ props.style.backgroundPositionY}
						        onChange={(event) => setProp((props: any) => props.style.backgroundPositionY = event.target.value)}>
						        <MenuItem value="bottom">Bottom</MenuItem>
										<MenuItem value="center">Center</MenuItem>
						        <MenuItem value="top">Top</MenuItem>
						      </PropertiesSelect>
								</section>
								<section className="dm-edit-property-fields">
									<PropertyLabel>Repeat</PropertyLabel>
									<PropertiesSelect size="small"
						        value={ props.style.backgroundRepeat}
						        onChange={(event) => setProp((props: any) => props.style.backgroundRepeat = event.target.value)}>
						        <MenuItem value="no-repeat">No Repeat</MenuItem>
						        <MenuItem value="repeat">Repeat</MenuItem>
						        <MenuItem value="repeat-x">Repeat X</MenuItem>
										<MenuItem value="repeat-y">Repeat Y</MenuItem>
						      </PropertiesSelect>
								</section>
							</>
						}
					</>
			}
		</PropertiesAccordionDetails>
	</PropertiesAccordion>
}
