import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Button, IconButton, Menu, MenuItem, SxProps, Theme } from "@mui/material"
import { useEditor, UserComponent } from '@craftjs/core'
import { CompanyInterface, useUser } from '../../contexts/UserContext'
import { LightButton } from '../Buttons/LightButton'
import { useZoom } from '../../contexts/ViewProvider'
import { Confirmation } from '../../dialogs/Confirmation/Confirmation'
import { DarkButton } from '../Buttons/DarkButton'
import { Approve } from '../../dialogs/Approve/Approve'
import { DesignInterface } from '../../contexts/DesignContext'
import { DateTime } from 'luxon'
import './Header.scss'

interface HeaderInterface {
  mode: string
  onSave:(type: 'save'|'save_and_render_pdf') => void
  onPreview: (type: 'random'|'longest'|'shortest') => void
  onModeChange: (mode: 'proof'|'edit'|'approved') => void
  design?: DesignInterface
  device: string
  setDevice: Dispatch<SetStateAction<string>>
}

const menuStyle: SxProps<Theme> = {
  '.MuiPaper-root': {
    width: 180,
    boxShadow: '0 4px 18px 0 rgba(37,50,65,0.24)',
    position: 'relative',
    overflow: 'visible',
    '&:before': {
      content: "close-quote",
      display: 'block',
      width: 24,
      height: 24,
      position: 'absolute',
      backgroundColor: '#FFFFFF',
      top: -4,
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)'
    }
  },
  '.MuiMenuItem-root': {
    fontSize: '14px',
    lineHeight: '20px',
    height: '32px',
    color: '#202B38',
    fontFamily: `'Inter', sans-serif`
  }
}

export const Header: UserComponent<HeaderInterface> = ({mode, onSave, onPreview, onModeChange, design, device, setDevice}) => {
  const {actions, root, canUndo, canRedo, selected, changeCallback} = useEditor((state, query) => {
    const selected_ids: Set<string> = state.events.selected
    const currentNodeId = selected_ids.size == 1 ? selected_ids.values().next().value : null
    let response: any = {
      state: state, 
      root: state.nodes.ROOT,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo()
    }

    if ( currentNodeId ) {
      let currentNode = state.nodes[currentNodeId]
      
      response.selected = {
        ...currentNode,
        deletable: query.node(currentNodeId).isDeletable()
      }
    }

    return response
  })
  const {user, save, setSave}: {user: CompanyInterface, save: boolean, setSave: Dispatch<SetStateAction<boolean>>} = useUser() 
  const [confirm, setConfirm] = useState({question: '', open: false})
  const [approve, setApprove] = useState<boolean>(false)
  const [select, setSelect] = useState<Element>(null)
  const {zoom, setZoom} = useZoom()
  const onBleedToggle = () => {
    let new_bleed = !root.data.props.bleed
    actions.history.ignore().setProp('ROOT', (props: any) => props.bleed = new_bleed)

    root.data.nodes.forEach((node_id: string) => {
      actions.history.ignore().setProp(node_id, (props: any) => props.bleed = new_bleed)
    })
  }

  const handleClickOutside = (event: MouseEvent) => {
    let workspace = document.getElementsByClassName('dm-edit-workspace')[0]
    let cardscale = document.getElementsByClassName('dm-edit-cardscale')[0]
		if ((workspace && workspace == event.target) ||
      (cardscale && cardscale == event.target)) {
      actions.selectNode(null)
		}
	}

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [selected])

  const handleKeyDown = (event: KeyboardEvent) => {
    var modifier = navigator.userAgent.indexOf("Mac") !== -1 ? event.metaKey : event.ctrlKey 

    switch (event.code) {
      case 'KeyZ':
        if (modifier) {
          if (event.shiftKey) actions.history.redo()
          else actions.history.undo()
        }

        return
    }

    if (selected && selected.data.name != 'Card') {
      let target = event.target
      if (target instanceof HTMLElement) {
        if(['INPUT', 'SPAN', 'H2', 'TEXTAREA'].includes(target.tagName) || (selected.data.name === 'Text' && selected.data.props.editing) ) {
          return
        }
      }

      switch (event.code) {
        case 'Escape': 
          actions.selectNode(null)
          break
        case 'Backspace': 
          if (selected.deletable) {
            onDelete()
          }
          break
        case 'ArrowDown':
        case 'ArrowUp':
          actions.setProp(selected.id, (props) => {
            if (props.y) {
              event.preventDefault()
              let delta = event.shiftKey ? 10 : 1
              props.y = event.code === 'ArrowUp' ? Math.ceil(props.y - delta) : Math.floor(props.y + delta)
            }
          })
          break
        case 'ArrowLeft':
        case 'ArrowRight':
          actions.setProp(selected.id, (props) => {
            if (props.x) {
              event.preventDefault()
              let delta = event.shiftKey ? 10 : 1
              props.x = event.code === 'ArrowRight' ? Math.floor(props.x + delta) : Math.ceil(props.x - delta)
            }
          })
          break
      }
    } 
  }

  const onProof = () => {
    onModeChange('proof')
    onZoomToFit(true)
    onBleedToggle()
  }

  const onEdit = (type: 'edit' | 'approved') => {
    onModeChange(type)
    onZoomTo100()
    onBleedToggle()
    setSave(false)
  }

  const onDelete = () => {
    let data = selected.data
    let name = data.custom && data.custom.displayName ? data.custom.displayName : data.name
    setConfirm({question: `Are you sure you want to delete the element "${name}"?`, open: true})
  }

  const onApproveProof = () => {
    setApprove(true)
  }

  const onConfirmResponse = (event: any) => {
    setConfirm({question: '', open: false})
    if (event.confirmed) {
      actions.delete(selected.id)
    }
  }

  const onApproveResponse = (event: any) => {
    if (event.approved) {
      onModeChange('approved')
      setSave(false)
    }

    setApprove(false)
  }

  const onZoomToFit = (proof: boolean = false) => {
    let available = proof ? window.innerWidth : window.innerWidth - 377
    let taken = document.getElementsByClassName('dm-edit-cardscale')[0].clientWidth

    setZoom(Math.floor(zoom * available/taken))
    setSelect(null)
  }

  const onZoomTo100 = () => {
    setZoom(100)
    setSelect(null)
  }

  return <header className="dm-edit-header">
    <section>
      <svg style={{width: 24, height: 34, margin: '0 13px'}}>
        <use href="assets/icons/header.svg#logo" />
      </svg>
      <div style={{marginLeft: '13px'}}>
        <div className="dm-edit-header__campaign">
          ABC Campaign
        </div>
        <div className="dm-edit-header__client">
          { user.name }
        </div>
      </div>
    </section>
    { mode === 'edit' ?
      <>
        <section>
          { !design.proofed_at && 
            <section>
              <IconButton aria-label="undo" 
                className="dm-edit-header__icon"
                disabled={!canUndo}
                onClick={() => {
                  actions.history.undo()
                }}>
                <svg>
                  <use href="assets/icons/header.svg#undo"/>
                </svg>        
              </IconButton>
              <IconButton aria-label="Redo" 
                className="dm-edit-header__icon"
                disabled={!canRedo}
                onClick={() => actions.history.redo()}>
                <svg>
                  <use href="assets/icons/header.svg#redo"/>
                </svg>     
              </IconButton>
              <div className="dm-edit-header__divider"></div>
            </section>
          }
          { design.type.toLowerCase() !== 'landing_page' &&
            <>
              <Button aria-label="ZoomOut" 
                className="dm-edit-header__btn dm-edit-header__btn--lft"
                disabled={zoom <= 50}
                onClick={() => setZoom(zoom - 10)}>
                -
              </Button>
              <Button id="zoom-select-btn"
                aria-label="ZoomSelect" 
                className="dm-edit-header__btn dm-edit-header__btn--cntr"
                onClick={(event) => setSelect(event.currentTarget)}>
                { zoom }%
              </Button>
              <Menu sx={menuStyle}
                anchorEl={select}
                open={select !== null}
                onClose={() => setSelect(null)}
                anchorOrigin={{
                  vertical: 51,
                  horizontal: -64,
                }}>
                <MenuItem onClick={onZoomTo100}>Zoom to 100%</MenuItem>
                <MenuItem onClick={() => onZoomToFit()}>Zoom to Fit</MenuItem>
              </Menu>
              <Button aria-label="ZoomIn" 
                className="dm-edit-header__btn dm-edit-header__btn--rght"
                disabled={zoom >= 300}
                onClick={() => setZoom(zoom + 10)}>
                +
              </Button>
            </>
          }
          { design.type.toLowerCase() === 'landing_page' &&
            <section>
              <IconButton aria-label="undo" 
                className={`dm-edit-header__icon ${device === 'mobile' ? 'dm-edit-header__icon--inactive':''}`}
                onClick={() => setDevice('desktop')}>
                <svg>
                  <use href="assets/icons/header.svg#desktop"/>
                </svg>        
              </IconButton>
              <IconButton aria-label="Redo" 
                className={`dm-edit-header__icon ${device === 'desktop' ? 'dm-edit-header__icon--inactive':''}`}
                onClick={() => setDevice('mobile')}>
                <svg>
                  <use href="assets/icons/header.svg#mobile"/>
                </svg>     
              </IconButton>
              <div className="dm-edit-header__divider"></div>
            </section>
          }
        </section>
        {
          (save && canUndo) ? 
          <section style={{minWidth: 194, display: 'flex', justifyContent: 'flex-end'}}>
            <DarkButton aria-label="Done" 
              onClick={() => onSave('save')}>
              Save
            </DarkButton>
          </section> : (
            design.type.toLowerCase() === 'mailpiece' ?
            <section style={{minWidth: 194}}>
              {
                design.proofed_at && 
                <div style={{fontSize: 12, marginRight: '10px'}}>
                  Approved and sent to print: <br/>
                  by {design.proofed_by_name} on { DateTime.fromFormat(design.proofed_at, 'yyyy-MM-dd hh:mm:ss').toLocaleString() }
                </div>
              }
              <LightButton aria-label="preview"   
                sx={{width: 100}}
                onClick={() => onSave('save_and_render_pdf')}>
                View PDF
              </LightButton>
              {
                !design.proofed_at && 
                <DarkButton aria-label="Done" 
                  onClick={onProof}>
                  Proof
                </DarkButton>
              }
            </section> :
            <section>
              <LightButton aria-label="preview"   
                sx={{width: 100}}
                onClick={() => onModeChange('proof')}>
                Preview
              </LightButton>
            </section>
          )
        }
      </> : 
      mode === 'proof' ?
      <>
        { design.type.toLowerCase() === 'mailpiece' &&
          <section>
            <LightButton aria-label="preview"   
              sx={{width: 156}}
              onClick={() => onPreview('random')}>
              Preview Random Data
            </LightButton>
            <LightButton aria-label="preview"   
              sx={{width: 156}}
              onClick={() => onPreview('longest')}>
              Preview Longest Data
            </LightButton>
            <LightButton aria-label="preview"   
              sx={{width: 156}}
              onClick={() => onPreview('shortest')}>
              Preview Shortest Data
            </LightButton>
          </section>
        }
        <section>
          <DarkButton aria-label="Done" 
            onClick={() => onEdit('edit')}>
            Edit
          </DarkButton>
          { design.type.toLowerCase() === 'mailpiece' &&
            <Button onClick={onApproveProof}
              sx={{
                backgroundColor: '#07C27D',
                color: '#FFF',
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: 'bold',
                textTransform: 'none',
                boxShadow: 'none',
                padding: '6px 16px',
                marginLeft: '7px',
                ":hover": {
                  backgroundColor: "#068a59",
                  boxShadow: 'none'
                }
              }}>
              Approve Proof
            </Button>
          }
        </section>    
      </> : <></>}
    <Confirmation open={confirm.open} 
      question={confirm.question}
      onClose={(event: any) => onConfirmResponse(event)}/>
    {
      mode === 'proof' &&
      <Approve open={approve} 
        onClose={onApproveResponse}/>
    }
  </header>
}
