import React, { CSSProperties } from 'react'
import { Button, Dialog, SxProps, Theme } from '@mui/material'
import './Confirmation.scss'

interface ConfirmationProps {
	onClose: (event: any) => void
	open: boolean
	question: string
}

export const Confirmation = ({onClose, open = false, question}: ConfirmationProps) => {
	const buttonStyle: SxProps<Theme> = {
		width: '76px',
		height: '36px',
		fontSize: '14px',
		fontWeight: 'bold',
		boxShadow: 'none',
		textTransform: 'none',
		margin: '0 4px',
	}

	return <Dialog open={open}
		sx={{
			'.MuiBackdrop-root': {backgroundColor: 'transparent'},
			'.MuiPaper-root': {boxShadow: '0 4px 58px 0 rgba(37,50,65,0.3)', borderRadius: '8px'}
		}}>
		<section className="dm-edit-confirm">
			{ question }
			<section className="dm-edit-confirm__buttons">
				<Button sx={{...buttonStyle, 
						background: '#E7E9EB', 
						color: '#445160', 
						'&:hover': {backgroundColor: '#CED2D6', boxShadow: 'none'}
					}}
					variant="contained"
					onClick={() => onClose({confirmed: false})}>
					Cancel
				</Button>
				<Button sx={{...buttonStyle, 
						background: '#CF362C', 
						color: '#FFF', 
						'&:hover': {backgroundColor: '#BA3128', boxShadow: 'none'}
					}}
					variant="contained"
					onClick={() => onClose({confirmed: true})}>
					Delete
				</Button>
			</section>
		</section>
	</Dialog>
}