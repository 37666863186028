import React from 'react'

import { useNode } from '@craftjs/core'
import QRCode from 'react-qr-code'
import { QRProps } from '../../../../types/props'
import { QRProperties } from './QRProperties'
import { getBorder, getInnerDimensions } from '../../../../services/helperService'

export const QR = (props: QRProps) => {
  const {connectors: {connect}} = useNode()
  let {x, y, width, height, ...QRProps} = props
  let size = getInnerDimensions(props)

  return <div ref={(ref: any) => connect(ref)}
    style={{left: x, top: y, position: 'absolute', ...size, ...getBorder(props.border)}}>
    <QRCode size={parseInt(size.width)} 
      {...QRProps}/>
  </div>
}

QR.craft = {
  displayName: 'QRCode',
  custom: {
    mailpiece: {
      resizableProps: {
        lockAspectRatio: true
      }
    }
  },
  props: {
    link: 'https://www.directmail.io/',
    width: '40px',
    height: '40px', 
    initial: true, 
    x: 0,
    y: 0,
    level: 'Q',
    style: {
      backgroundColor: 'rgba(256,256,256,1)'
    }
  },
  related: {
    properties: QRProperties
  }
}
