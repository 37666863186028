import React, { ReactElement } from 'react'

import { Image } from '../Image/Image'
import { useEditor } from '@craftjs/core'
import { Button as MaterialButton } from '@mui/material'
import { ImageButtonProps, ImageProps } from '../../../types/props'
import { DesignInterface, useDesign } from '../../../contexts/DesignContext'
import { WebsiteImage } from '../WebsiteImage/WebsiteImage'
import './ImageButton.scss'

export const ImageButton = ({mode, url, id, name, type = 'upload', onCreate}: ImageButtonProps) => {
  const {connectors} = useEditor()
  let {design}: {design: DesignInterface} = useDesign()
  const design_type = design.type.toLowerCase()
  let ImageElement: ReactElement<any>
  let new_props: ImageProps = {
    url,
    id
  }

  switch(design_type) {
    case 'mailpiece': 
      new_props = {
        ...new_props,
        initial: true, 
        x: 0,
        y: 0,
        width: 'auto',
        height: 'auto',
        absolute: true,
        imageHeight: '300px',
        imageWidth: 'auto',
        style: {
          opacity: 1,
          transform: 'rotate(0deg)'
        }
      }

      ImageElement = <Image {...new_props}
        absolute={true}/>
      break
    case 'landing_page': 
      new_props = {
        ...new_props,
        style: {
          opacity: 1,
          transform: 'rotate(0deg)',
          gridColumnStart: '1',
          gridColumnEnd: 'span 4',
          gridRowStart: '1',
          gridRowEnd: 'span 3'
        }
      }

      ImageElement = <WebsiteImage {...new_props} />
  }

  if (mode === 'addNode') {
    return <MaterialButton className="dm-edit-img-btn">
      <div className="dm-edit-img-btn__img">
        <img className="dm-edit-images__item" ref={(ref: any) => {
          return connectors.create(ref, ImageElement , { onCreate })
        }}
          src={url} />
      </div>
      { type === 'upload' && <div className="dm-edit-img-btn__name">{ name }</div> }
    </MaterialButton>
  }

  return <MaterialButton className="dm-edit-img-btn"
    onClick={() => onCreate({url, id})}>
    <div className="dm-edit-img-btn__img">
      <img className="dm-edit-images__item"
        src={url} />
    </div>
    { type === 'upload' && <div className="dm-edit-img-btn__name">{ name }</div> }
  </MaterialButton>
}
