import React, { PropsWithChildren, useEffect, useState, useRef } from 'react'

import { Node, useEditor } from '@craftjs/core'
import { getRandomId } from '@craftjs/utils'
import { IconButton, Menu, MenuItem, SxProps, Theme } from '@mui/material'
import { SelectedInterface } from './Properties'
import { useDesign } from '../../../contexts/DesignContext'

export const ItemControl = ({selected, children}: PropsWithChildren<{selected: SelectedInterface}>) => {
	const [menu, setMenu] = useState<Element>(null)
	const [editing, setEditing] = useState<boolean>(false)
	const {actions, query} = useEditor()
	const labelRef = useRef(null)
	const {design} = useDesign()
	
	useEffect(() => {
		if (editing) {
			document.addEventListener("mousedown", handleClickOutside)

			return () => document.removeEventListener("mousedown", handleClickOutside)
		}
  }, [editing])

	const handleClickOutside = (event: MouseEvent) => {
		if (labelRef && !labelRef.current.contains(event.target)) {
			if (labelRef.current.innerText) {
				actions.setCustom(selected.id, (custom) => custom.displayName = labelRef.current.innerText)
			}
      setEditing(false)
		}
	}

	const copyNode = (node: Node, newId: string) => {
		const newNode: Node = {
			...node,
			id: newId,
			events: {
				dragged: false,
				hovered: false,
				selected: false,
			},
		}
		return query.parseFreshNode(newNode).toNode()
	}

	const menuItemStyle: SxProps<Theme> = {
		fontSize: '12px',
		lineHeight: '18px',
		height: '35px',
		color: '#202B38',
		fontFamily: `'Inter', sans-serif`
	}

	const OnDuplicateItem =  async (node: Node, parentId: string, offset: boolean = false) => {
		if (!node || !parentId) return

		const newId = getRandomId()
		const newNode = copyNode(node, newId)
		
		newNode.data.nodes = []

		if (design.type === 'mailpiece' && offset) {
			newNode.data.props.x +=30 
			newNode.data.props.y +=30 
			newNode.data.props.duplicate = true
		}
		
		// Need to create parent node before create child node
		await actions.add(newNode, parentId)

		if (node.data.nodes.length > 0) {
			// add child nodes to the copy node
			node.data.nodes.forEach((childNodeId) => {
				const childNode = query.node(childNodeId).get()
				OnDuplicateItem(childNode, newId)
			})	
		}

		setMenu(null)
	}

	const onDeleteItem = () => {
		actions.delete(selected.id)
	}

	return <div className="dm-edit-item-cntrl">
		<span onDoubleClick={(event) => setEditing(true)}>
			<svg>
				<use href={`assets/icons/elements.svg#mono-${selected.node.data.displayName.toLowerCase().replaceAll(' ', '-')}`} />
			</svg>
			<span id="dm-edit-item-cntrl__label"
				ref={labelRef}
				suppressContentEditableWarning={true}
				contentEditable={editing}>
				{ selected.node.data.custom && selected.node.data.custom.displayName ? selected.node.data.custom.displayName : selected.node.data.displayName }
			</span>
		</span>
		{ children }
		<IconButton sx={{width: '24px', height: '24px', padding: 0}}
      onClick={(event) => setMenu(event.currentTarget)}>
      <svg>
        <use href={`assets/icons/settings.svg#menu`}/>
      </svg>   
    </IconButton>
		<Menu sx={{'.MuiPaper-root': {boxShadow: '0 4px 18px 0 rgba(37,50,65,0.24)'}}}
			MenuListProps={{sx: {padding: 0, width: 128}}}
			anchorEl={menu}
			open={menu !== null}
			onClose={() => setMenu(null)}>
			<MenuItem sx={{...menuItemStyle}}
				onClick={() => OnDuplicateItem(selected.node, selected.node.data.parent, true)}>
				Duplicate
			</MenuItem>
			<MenuItem sx={{...menuItemStyle, borderTop: '1px solid #DADFE3', color: '#D50000'}}
				onClick={onDeleteItem}>
				Delete
			</MenuItem>
		</Menu>
	</div>
}