import React, { CSSProperties, PropsWithChildren, useEffect } from 'react'

import { useEditor, useNode } from '@craftjs/core'
import { ColumnProps } from '../../../../../types/props'
import { useDevice } from '../../../../../contexts/ViewProvider'
import './Column.scss'

export const Column = (props: PropsWithChildren<ColumnProps>) => {
	const {connectors: {connect}, id, parent, selected} = useNode(node => ({
		id: node.id,
		parent: node.data.parent,
		selected: node.events.selected
	}))
	const {actions: {selectNode}, query: {node}} = useEditor()
	const {device} = useDevice()
	let parentNode = node(parent).get()

	let style: CSSProperties = {
		gridTemplateColumns: `repeat(${device === 'mobile' ? 36 : props.grids}, 1fr)`
	}

	if (parentNode.data.props.style.minHeight && device !== 'mobile') {
		style.minHeight = parentNode.data.props.style.minHeight
	}

	useEffect(() => {
		if (selected) {
			selectNode(parent)
		}
	}, [selected])

	return <div ref={ref => connect(ref)}
		style={style}
		className="dm-edit-section-column">
		<div className="dm-edit-section-column__grid"></div>
		{ props.children }
	</div>
}
