import React, { useState } from 'react'

import { PropertyLabel } from './PropertyLabel'
import { PropertiesField } from './PropertiesFields'
import { useNode } from '@craftjs/core'
import { ColorPicker } from './ColorPicker'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from './PropertiesAccordion'

export const Shadow = () => {
	const { actions: {setProp}, props } = useNode((node) => ({
		props: node.data.props	
	}))
	const [open, setOpen] = useState<boolean>(true)

	const getShadowValues = () => {
		let shadow: string = props.style.boxShadow

		if (!shadow) return {
			x: 0,
			y: 0,
			blur: 0,
			spread: 0,
			color: 'rgba(0,0,0,0.5)'
		}

		let items = shadow.split(' ')

		return {
			x: parseInt(items[0]),
			y: parseInt(items[1]),
			blur: parseInt(items[2]),
			spread: parseInt(items[3]),
			color: items[4]
		}
	}

	const [values, setValues] = useState<{x: number, y: number, blur: number, spread: number, color: string}>(getShadowValues())

	const onValueChange = (key: string, value: string) => {
		let new_values: any = {...values}
		
		if (key === 'color') {
			new_values[key] = value
		} else {
			new_values[key] = parseInt(value)
		}

		setValues(new_values)
		setProp((props: any) => props.style.boxShadow = `${new_values.x}px ${new_values.y}px ${new_values.blur}px ${new_values.spread}px ${new_values.color}`)
	}

	return <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
		<PropertiesAccordionSummary label="Shadow"/>
		<PropertiesAccordionDetails>
			<section className="dm-edit-property-fields">
				<PropertyLabel>X Offset</PropertyLabel>
				<PropertiesField value={values.x}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => onValueChange('x', event.target.value)}>
				</PropertiesField>
				<PropertyLabel>Y Offset</PropertyLabel>
				<PropertiesField value={values.y}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => onValueChange('y', event.target.value)}>
				</PropertiesField>
			</section>
			<section className="dm-edit-property-fields">
				<PropertyLabel>Blur</PropertyLabel>
				<PropertiesField value={values.blur}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => onValueChange('blur', event.target.value)}>
				</PropertiesField>
				<PropertyLabel>Spread</PropertyLabel>
				<PropertiesField value={values.spread}
					size="small"
					type="number"
					inputProps={{min: 0}}
					onChange={event => onValueChange('spread', event.target.value)}>
				</PropertiesField>
			</section>
			<section className="dm-edit-property-fields">
				<ColorPicker color={values.color}
					onColorChange={(color: string) => onValueChange('color', color)} />
			</section>
		</PropertiesAccordionDetails>
	</PropertiesAccordion>
}
