import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'

import { Node, useEditor, useNode } from '@craftjs/core'
import { MapProps, WebMapProps } from '../../../../types/props'
import { WebMapProperties } from './WebMapProperties'
import { getBorder, getStyle } from '../../../../services/helperService'
import GoogleMapReact from 'google-map-react'
import { styled } from '@mui/system'
import { useDevice } from '../../../../contexts/ViewProvider'

export const WebMap = (props: WebMapProps) => {
  const {connectors: {connect}, parent} = useNode(node => ({parent: node.data.parent}))
  const { query: {node} } = useEditor()

  const {device} = useDevice()
  let parentNode: Node = node(parent).get()
  let style: CSSProperties = {
    ...getStyle(props.style, props.mobileStyle, device, parentNode)
  }
  let address = props.address.replace(/\s/g, '+')

  // useEffect(() => {
  //   let geocoder: google.maps.Geocoder = new google.maps.Geocoder()    
  //   geocoder.geocode({ address: address }, (results) => {
  //     if (results[0]) {
  //       console.log(results[0])
  //     }
  //   })
  // }, [])
  
  return <div ref={ref => connect(ref)}
    style={{...props.style, ...props.style}}>
   <GoogleMapReact defaultZoom={14}
    defaultCenter={{lat: 25.7633056, lng: -80.1910755}}
    zoom={props.zoom}>
    <Marker lat={25.7633056} lng={-80.1910755}/>
   </GoogleMapReact>
  </div>
}

const Marker = ({ lat, lng }: {lat: number, lng: number}) => {
  
  const Wrapper =  styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    &:hover {
      z-index: 1;
    }
  `
  return <Wrapper/>
}

WebMap.craft = {
  displayName: 'Map',
  props: {
    maptype: 'roadmap',
    width: '300px', 
    height: '300px',
    initial: true,
    zoom: 14, 
    style: {
      backgroundColor: 'rgba(229, 229, 229, 1)'
    }
  },
  related: {
    properties: WebMapProperties
  },
  onClick: () => {}
}