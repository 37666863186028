import React, { useState } from 'react'

import { useNode } from '@craftjs/core'
import { WebButtonProps } from '../../../../types/props'
import { Background } from '../../../Rightbar/Properties/Background'
import { Border } from '../../../Rightbar/Properties/Border'
import { Corners } from '../../../Rightbar/Properties/Corners'
import { Layout } from '../../../Rightbar/Properties/Layout'
import { PropertiesAccordion, PropertiesAccordionDetails, PropertiesAccordionSummary } from '../../../Rightbar/Properties/PropertiesAccordion'
import { FullPropertiesField } from '../../../Rightbar/Properties/PropertiesFields'
import { PropertyLabel } from '../../../Rightbar/Properties/PropertyLabel'
import { Shadow } from '../../../Rightbar/Properties/Shadow'

export const WebButtonProperties = () => {
  const [open, setOpen] = useState<boolean>(true)
	const { actions: {setProp}, webButtonProps } = useNode<{webButtonProps: WebButtonProps}>((node) => ({
		webButtonProps: node.data.props as WebButtonProps
	}))

  return <>
    {/* <Layout hide={['height', 'width', 'rotate', 'opacity']}/> */}
    <PropertiesAccordion expanded={open} onChange={() => setOpen(!open)}>
			<PropertiesAccordionSummary label="Address"/>
			<PropertiesAccordionDetails>
				<section className="dm-edit-property-fields">
					<PropertyLabel>Label</PropertyLabel>
					<FullPropertiesField id="search_google"
	          value={webButtonProps.label}
	          size="small"
	          type="text"
	          onChange={(event) => {
							setProp((props: WebButtonProps) => props.label = event.target.value)
						}}
	        />	
				</section>			
        <section className="dm-edit-property-fields">
					<PropertyLabel>URL</PropertyLabel>
					<FullPropertiesField id="search_google"
	          value={webButtonProps.url || ''}
	          size="small"
	          type="text"
	          onChange={(event) => {
							setProp((props: WebButtonProps) => props.url = event.target.value)
						}}
	        />	
				</section>		
			</PropertiesAccordionDetails>
		</PropertiesAccordion>
    <Background />
		<Corners />
		<Border />
		<Shadow />
  </>
}