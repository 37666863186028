import React, { useEffect, useState } from 'react'

import { PixabayImageInterface, useImages } from '../../../contexts/ImagesContext'
import { ImageButton } from '../ImageButton/ImageButton'
import { Button, Dialog, Popper } from '@mui/material'
import './Instagram.scss'

export const Instagram = () => {
  const {pixabay, searchPixabay} = useImages()
  const [integration, setIntegration] = useState(false)
  const [search, setSearch] = useState<string>('')
  let params = `popup=yes,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600`;


  useEffect(() => {
    setSearch(pixabay.filter)
  }, [])

  const onAuth = () => {
    let windowRef = window.open('https://www.instagram.com/oauth/authorize?client_id=536386154503296&redirect_uri=https://local-admin.directmail.io:3000/&scope=user_profile,user_media&response_type=code', 'instagram', params);  
    windowRef.addEventListener('message', (event) => {console.log(event)})
  }

  return <section className="dm-edit-toolbar__section">
    <Button variant="contained"
      onClick={onAuth}>Connect</Button>
    {/* <Dialog open={integration}>
      <iframe src="" />
    </Dialog> */}
  </section>
}
