import React from 'react'

import { WebsiteRenderNode } from './WebsiteRenderNode/WebsiteRenderNode'
import { PostcardRenderNode } from './PostcardRenderNode/PostcardRederNode'

import './RenderNode.scss'

export const RenderNode = React.memo(({ render }: {render: any}) => {
  let type = new URLSearchParams(window.location.search).get('type')

  if (type === 'mailpiece') {
    return <PostcardRenderNode render={render}/>
  }

  return <WebsiteRenderNode render={render}/>
})