import React, { CSSProperties, PropsWithChildren } from 'react'

import { useNode } from '@craftjs/core'
import { BlockProps } from '../../../types/props'
import { ContainerProperties } from './ContainerProperties'
import { getBorder } from '../../../services/helperService'

export const Container = (props: PropsWithChildren<BlockProps>) => {  
  const {connectors: {connect}, id} = useNode(node => ({id: node.id}))

  let style: CSSProperties = { 
    display: 'flex', 
    width: props.width, 
    height: props.height, 
    ...props.style, 
    boxSizing: 'border-box', 
    ...getBorder(props.border)
  }

  if (props.absolute) {
    style.left = props.x
    style.top = props.y
    style.position = 'absolute'
    style.overflow = 'hidden'
  }

  return <div id={id}
    ref={ref => connect(ref)}
    style={style}>
     { props.children }
  </div>
}

Container.craft = {
  name: 'Container',
  displayName: 'Container',
  props: {
    absolute: false,
    initial: true,
    x: 0,
    y: 0,
    style: {
      backgroundColor: 'rgba(229, 229, 229, 1)',
      opacity: 1
    }
  },
  related: {
    properties: ContainerProperties
  }
}
